import { Component } from '@angular/core';
import { DynamicComponent } from '../../classes/DynamicComponent';

@Component({
  selector: 'app-dynamic-input',
  templateUrl: './dynamic-input.component.html'
})
export class DynamicInputComponent extends DynamicComponent {

}
