<ul class="flex list-li-space flex-wrap">
    <li *ngFor="let item of field.images.result; let i = index">
        <div class="img-check" [class.main-image]="i === 0">
           <span class="img" [style.background-image]="'url('+item.image+')'">
               <span class="text" (click)="makeMainImage(i)">{{ 'ADD_CAR.MAIN_IMAGE' | translate }}</span>
               <button class="btn-unstyled btn-delete" (click)="removeImage(i)">
                   <i class="icon-delete"></i>
               </button>
           </span>
           <span class="main-text">{{ 'ADD_CAR.MAIN_IMAGE' | translate }}</span>
        </div>
    </li>
    <li *ngIf="images.result.length < maxLength">
        <div class="img-upload">
            <label class="text-center">
                <input type="file" multiple #input (click)="input.value = null" (drop)="input.value = null" (change)="onFileChange($event)">
                <i class="icon-add-photo"></i>
                <span class="text">{{ 'ADD_CAR.ADD' | translate }}</span>
            </label>
        </div>
    </li>
</ul>
<p class="error-message" *ngIf="unsupportedImage">{{'ADD_CAR.UNSUPPORTED_IMAGE_TYPE' | translate}}</p>
<p class="error-message" *ngIf="bigImage">{{'ADD_CAR.BIG_IMAGE' | translate: {size: sizeMB } }}</p>
<p class="error-message" *ngIf="images.toAdd.length === maxLength">{{'ADD_CAR.MAXIMUM_IMAGES_COUNT' | translate:{count: maxLength} }}</p>
