import { Injectable } from '@angular/core';
import { CarFromAnotherWebsite } from '@shared/interfaces/car/car-from-another-website.interface';
import { ADD_CAR_STATUSES } from '@shared/constants/car/add-car-scraper.constant';
import { SNACKBAR_CONFIG } from '@shared/snackbar/snackbar.constants';

@Injectable({
  providedIn: 'root'
})
export class AddCarFromAnotherWebsiteInfoBoxService {

  private tasks: CarFromAnotherWebsite[] = [];
  private completed = new Set<CarFromAnotherWebsite['task_id']>(); // completed tasks
  private errored = new Set<CarFromAnotherWebsite['task_id']>(); // errored tasks
  private messages = {
    success: {
      value: false,
      interval: null
    },
    error: {
      value: false,
      interval: null
    }
  };

  constructor() {}

  get allTasks() {
    return this.tasks;
  }

  get inProgressTasks() {
    return this.tasks.filter(item => item.status === ADD_CAR_STATUSES.PENDING);
  }

  get failedTasks() {
    return this.tasks.filter(item => item.status === ADD_CAR_STATUSES.FAILURE);
  }

  inTasks(id: CarFromAnotherWebsite['task_id']) {
    return this.tasks.findIndex(item => item.task_id === id) > -1;
  }

  deleteTask(id: CarFromAnotherWebsite['task_id']) {
    const index = this.tasks.findIndex(item => item.task_id === id);
    if (index > -1) {
      this.tasks.splice(index, 1);
    }
  }

  deleteTasks() {
    this.tasks = [];
  }

  updateTask(id: CarFromAnotherWebsite['task_id'], values: Partial<CarFromAnotherWebsite>) {
    const index = this.tasks.findIndex(item => item.task_id === id);
    this.tasks[index] = {
      ...this.tasks[index],
      ...values
    };
  }

  get completedTasks() {
    return this.completed;
  }

  addTask(res: CarFromAnotherWebsite) {
    res.status = ADD_CAR_STATUSES[res.status];
    this.tasks.push(res);
  }

  addCompleted(id: CarFromAnotherWebsite['task_id']) {
    this.completedTasks.add(id);
    this.showSuccessMessage = true;
  }

  addErrored(id: CarFromAnotherWebsite['task_id']) {
    this.errored.add(id);
    this.showErrorMessage = this.allTasksFailed;
  }

  get allTasksFailed() {
    return this.tasks.length && this.tasks.length === this.failedTasks.length;
  }

  get allTasksCompleted() {
    return this.tasks.length && this.tasks.length === this.completedTasks.size;
  }

  get showSuccessMessage() {
    return this.messages.success.value;
  }

  set showSuccessMessage(value) {
    this.messages.success.value = value;
    this.setMessageInterval('success');
  }

  get showErrorMessage() {
    return this.messages.error.value;
  }

  set showErrorMessage(value) {
    this.messages.error.value = value;
    this.setMessageInterval('error');
  }

  setMessageInterval(type) {
    clearInterval(this.messages[type].interval);
    if (this.messages[type].value) {
      this.messages[type].interval = setTimeout(() => {
        this.messages[type].value = false;
      }, SNACKBAR_CONFIG.timeout);
    }
  }
}
