<!-- About banner start -->
<section class="about-banner">
	<div class="container">

		<div class="about-text">
			<h1 class="fw-600 mb-5 mb-xl-4 mb-md-3">{{'ABOUT.HEADER' | translate}}</h1>
			<p>
				{{'ABOUT.HEADER_DESC_1' | translate}}
			</p>
		</div>
	
		<div class="our-advantages">
			<h4 class="fw-600">{{'ABOUT.PREV_HEAD' | translate}}</h4>
			<ul class="info-list white top-aligned">
				<li *ngFor="let item of advantages">
					<div>					
						<h5 class="fw-400">{{ item.title | translate }}</h5>
						<p>{{ item.description | translate }}</p>
					</div>
				</li>
			</ul>
		</div>
		
	</div>
</section>
<!-- About banner end -->

<!-- Why us start -->
<div class="why-us">
	<div class="container">
		
		<div class="why-us-text bg-tertiary">
			<h2 class="h4 fw-600">{{'ABOUT.WHY_HEADER' | translate}}</h2>
			<p>{{'ABOUT.WHY_HEADER_DESC' | translate}}</p>
			<ul class="info-list blue sm">
				<li *ngFor="let title of why">{{ title | translate }}</li>
			</ul>
			<p>{{'ABOUT.WHY_HEADER_DESC_2' | translate}}</p>
		</div>

		<div class="why-us-images">
			<div class="img-small"></div>
			<div class="img-big"></div>
		</div>

	</div>
</div>
<!-- Why us end -->

<!-- About steps start -->
<section class="about-steps">
	<div class="container">
		
		<div class="text-center">		
			<h2 class="h4 mb-4 fw-600">{{'ABOUT.HEADER_2' | translate}}</h2>
			<p class="subtitle">{{'ABOUT.HEADER_DESC_2' | translate}}</p>
		</div>

		<div class="steps-content">

			<div class="step-list">
				<ul class="numbered-list blue">
					<li *ngFor="let step of steps">
						<div class="list-title">
							<span>{{ step.number }}</span>
							<p>{{ step.title | translate }}</p>
						</div>
						<p class="text">{{ step.description | translate }}</p>
					</li>
				</ul>
			</div>
			
			<div class="steps-img">
				<div class="device-img white">
	        <ul class="dots">
	          <li></li>
	          <li></li>
	          <li></li>
	        </ul>
	        <div class="img">
	          <img src="assets/images/add-car-sample.png" [alt]="'ADD_CAR.ADD_YOUR_VEHICLE' | translate">
	        </div>
	    	</div>
			</div>
			
		</div>

	</div>
</section>
<!-- About steps end -->