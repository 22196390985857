<section class="additional-info" *ngIf="additionalInformation.length">
    <div class="container">
        <button class="btn outline primary flex align-center"
                [ngClass]="{'expanded': expandedView}"
                (click)="expandedView = !expandedView">
            {{ 'ADD_CAR.FEATURES' | translate }}
            <i class="icon-arrow-down ml-3"></i>
        </button>
        <div class="additions" *ngIf="expandedView">
            <div class="block" *ngFor="let info of additionalInformation">
                <h5 class="sm color-secondary mb-3">{{info.label | translate}}</h5>
                <ul *ngFor="let field of info.fields">
                    <li><b *ngIf="field.label">{{ field.label | translate }}: </b>{{ field.value | translate }}</li>
                </ul>
            </div>
        </div>
    </div>
</section>