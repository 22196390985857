import { Component, Input } from '@angular/core';
import { IHeaderLinksConfig } from '../interfaces/links-config.interface';

@Component({
  selector: 'app-nav-link',
  templateUrl: './nav-link.component.html'
})
export class NavLinkComponent {

  @Input() item: IHeaderLinksConfig;

}
