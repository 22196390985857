import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { SnackbarService } from '@shared/snackbar/snackbar.service';
import { SNACKBAR_TYPES } from '@shared/snackbar/snackbar.constants';
import { Router } from '@angular/router';
import { FULL_ROUTES } from '../../routes';
import { CarAdvancedSearchService } from '@shared/providers/search/car-advanced-search.service';
import { CarVehicleTypeService } from '@shared/providers/search/car-vehicle-type.service';
import { CarFastSearchService } from '@shared/providers/search/car-fast-search.service';
import { SearchForm } from '@shared/helpers/search/search-form';
import { FIELD_PROVIDERS } from '@shared/providers/field-providers';
import { Subscription } from 'rxjs';
import { CarService } from '@shared/providers/car/car.service';
import { ResponseModel } from '@shared/interfaces/response-model.interface';
import { Car } from '@shared/interfaces/car/car.interface';
import { Brand } from '@shared/interfaces/car/brand.interface';

@Component({
  selector: 'app-search-section',
  templateUrl: './search-section.component.html',
  providers: FIELD_PROVIDERS
})
export class SearchSectionComponent implements OnInit, AfterViewInit, OnDestroy {

  private $subscriptions: Subscription = new Subscription();
  expandedView: boolean;
  carCount = '0';
  private readonly fullRoutes = FULL_ROUTES;
  private lastValue;

  constructor(
      private readonly router: Router,
      private readonly snackbarService: SnackbarService,
      private readonly carService: CarService,
      public advancedSearch: CarAdvancedSearchService,
      public fastSearch: CarFastSearchService,
      public vehicleType: CarVehicleTypeService,
  ) {}

  ngOnInit(): void {
      this.fastSearch.initFunctions();
      this.getCarsSearchCount();
  }

  ngAfterViewInit() {
    this.onFormValueChange();
  }

  private onFormValueChange() {
    const forms = [
      this.vehicleType.form,
      this.fastSearch.form,
      this.advancedSearch.form
    ];

    for (const form of forms) {
      this.$subscriptions.add(
          form.valueChanges.subscribe(res => {
            if (form.valid) {
              this.getCarsSearchCount();
            }
          })
      );
    }
  }

  getCarsSearchCount() {
    const searchResult = this.getSearchResult(false);
    const value = searchResult.value;
    // duplicated unnecessary request
    if (JSON.stringify(value) === JSON.stringify(this.lastValue)) {
      return;
    }
    if (searchResult.valid) {
      this.$subscriptions.add(
          this.carService.getCars<ResponseModel<Car>>(value).subscribe(res => {
            this.carCount = `${res.count > 99 ? '99+' : res.count}`;
          })
      );
      this.lastValue = value;
    }
  }

  private getSearchResult(setDefConfigs = true) {
    const vehicleTypeForm = this.vehicleType.form;
    const fastSearchForm = this.fastSearch.form;
    const advancedSearchForm = this.advancedSearch.form;
    return {
      invalid: fastSearchForm.invalid || vehicleTypeForm.invalid || advancedSearchForm.invalid,
      valid: fastSearchForm.valid && vehicleTypeForm.valid && advancedSearchForm.valid,
      value: SearchForm.getQueryParams({
        ...vehicleTypeForm.value,
        ...fastSearchForm.value,
        ...advancedSearchForm.value
      }, setDefConfigs)
    };
  }

  onBrandSelect(id: Brand['id']) {
    this.fastSearch.selectCarBrand(id);
  }

  search() {
    const searchResult = this.getSearchResult();
    if (searchResult.invalid) {
      this.snackbarService.open('TOASTS.NOT_VALID_SEARCH', SNACKBAR_TYPES.WARNING);
      return;
    }
    this.router.navigate([this.fullRoutes.SEARCH_CAR], { queryParams: searchResult.value });
  }

  ngOnDestroy() {
    this.$subscriptions.unsubscribe();
  }
}
