import { FULL_ROUTES, Social_APP_Links } from 'src/app/routes';
import { IFooterLinksConfig } from '../interfaces/links-config.interface';
import { IFooterSocialLinksConfig } from '../interfaces/social-links-config.interface';

export const footerLinksConfig: IFooterLinksConfig[] = [
  {
    path: FULL_ROUTES.HOME,
    text: 'FOOTER.HOMEPAGE',
  },
  {
    path: FULL_ROUTES.ABOUT_US,
    text: 'FOOTER.ABOUT_US',
  },
  {
    path: FULL_ROUTES.CONTACT_US,
    text: 'FOOTER.CONTACT_US',
  },
  {
    path: FULL_ROUTES.TERMS_AND_CONDITIONS,
    text: 'FOOTER.TERM',
  },
  {
    path: FULL_ROUTES.PRIVACY_POLICY,
    text: 'FOOTER.PRIVACY',
  }
];

export const footerSocialLinksConfig: IFooterSocialLinksConfig[] = [
  {
    href: Social_APP_Links.FACEBOOK,
    icon: 'icon-facebook',
  },
  {
    href: Social_APP_Links.INSTAGRAM,
    icon: 'icon-instagram',
  },
  {
    href: Social_APP_Links.YOUTUBE,
    icon: 'icon-youtube',
  }
];
