import { FormGroup } from '@angular/forms';
import { DynamicField } from '../../../modules/dynamic-fields/classes/DynamicField';
import { SearchForm } from '@shared/helpers/search/search-form';
import { CarService } from '@shared/providers/car/car.service';
import { ActivatedRoute } from '@angular/router';
import { getItem, setItem } from '@helpers/local-storage';
import { CarSearchConstant } from '@shared/constants/car/car-search.constant';
import { DynamicFormCreateService } from '../../../modules/dynamic-fields/providers/dynamic-form-create.service';
import { CarMainForm } from '@shared/helpers/car-main-form';

export abstract class SearchActions extends CarMainForm {

    protected constructor(
        protected dynamicForm: DynamicFormCreateService,
        protected carService: CarService,
        protected activatedRoute: ActivatedRoute
    ) {
        super();
        this.fieldConfigs();
        this.changeData();
        this.formConfigs();
    }

    public static INDEXES: any;

    // fields
    searchActionItems: DynamicField[] = [];

    fieldConfigs(): void {
        this.searchActionItems = [
            new DynamicField({
                type: 'select',
                label: 'SEARCH.SORT_BY',
                placeholder: 'FILTER.POSTED_TIME_DESC',
                wrapperClass: 'dynamic-input width-2 search-action-input',
                fieldClass: 'select-box h-sm dark mb-0',
                formControlName: 'sort_by',
                notClearable: true,
                source: [
                    { name: 'FILTER.POSTED_TIME_DESC', id: 'posted_time_desc' },
                    { name: 'FILTER.PRICE_ASC', id: 'price_asc' },
                    { name: 'FILTER.PRICE_DESC', id: 'price_desc' },
                    { name: 'FILTER.PRODUCTION_DATE_ASC', id: 'production_date_asc' },
                    { name: 'FILTER.PRODUCTION_DATE_DESC', id: 'production_date_desc' },
                    { name: 'FILTER.KILOMETER_DESC', id: 'kilometer_desc' },
                ]
            }),
            new DynamicField({
                type: 'select',
                label: 'FILTER.SHOW_ON_PAGE',
                placeholder: 'FILTER.SHOW_ON_PAGE',
                wrapperClass: 'dynamic-input width-2 search-action-input',
                fieldClass: 'select-box h-sm dark mb-0',
                formControlName: 'limit',
                notClearable: true,
                value: getItem(CarSearchConstant.LIMIT.KEY, CarSearchConstant.LIMIT.VALUE.toString()),
                source: [
                    { name: 'FILTER.ITEM', args: { count: 12 }, id: '12' },
                    { name: 'FILTER.ITEM', args: { count: 24 }, id: '24' },
                    { name: 'FILTER.ITEM', args: { count: 48 }, id: '48' },
                    { name: 'FILTER.ITEM', args: { count: 72 }, id: '72' },
                    { name: 'FILTER.ITEM', args: { count: 120 }, id: '120' },
                ],
                onChange: this.onPageItemsCountChange
            })
        ];
    }

    changeData(): void {}

    formConfigs(): void {
        // init fastSearchItems INDEXES
        SearchActions.INDEXES = SearchForm.getIndexes(this.searchActionItems);
        // init values
        for (const item of this.searchActionItems[SearchActions.INDEXES.SORT_BY].source) {
            if (this.activatedRoute.snapshot.queryParams.hasOwnProperty(item.id)) {
                this.searchActionItems[SearchActions.INDEXES.SORT_BY].value = item.id;
                break;
            }
        }
        // init form
        this.form = this.dynamicForm.createForm(this.searchActionItems);
    }

    onPageItemsCountChange = () => {
        setItem(CarSearchConstant.LIMIT.KEY, this.form.get('limit').value);
    }
}
