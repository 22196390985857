import { FULL_ROUTES } from '../../../routes';

import { IHeaderLinksConfig } from '../interfaces/links-config.interface';

export const headerLinksConfig: IHeaderLinksConfig[] = [
  /*  {
        path: FULL_ROUTES.HOME,
        routerLinkActiveOptions: true,
        text: 'HEADER.HOME',
    },*/
    {
        text: 'HEADER.ABOUT',
        path: FULL_ROUTES.ABOUT_US,
        routerLinkActiveOptions: true,
        children: [
            {
                path: FULL_ROUTES.CONTACT_US,
                routerLinkActiveOptions: true,
                text: 'HEADER.CONTACTS',
            },
        ]
    }
]

// TODO OPEN
export const headerOptionalConfig: IHeaderLinksConfig[] = [
   /* {
        path: FULL_ROUTES.OFFICIALS_SING_UP,
        routerLinkActiveOptions: true,
        text: 'HEADER.OFFICIALS_SING_UP',
        highlight: true
    }*/
]
