import { Component, OnInit } from '@angular/core';
import { DynamicComponent } from '../../classes/DynamicComponent';
import { CarSearchConstant } from '@shared/constants/car/car-search.constant';

@Component({
  selector: 'app-dynamic-input-with-select',
  templateUrl: './dynamic-input-with-select.component.html'
})
export class DynamicInputWithSelectComponent extends DynamicComponent implements OnInit {

  ngOnInit() {
    if (!this.group.get(this.getFormControlName(1)).value) {
      this.group.get(this.getFormControlName(1)).setValue(CarSearchConstant.CURRENCY.VALUE)
    }
  }

  keyPress = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
      const control = this.group.get(this.getFormControlName(1));
      const source = this.field.source;
      let index = source.findIndex(item => item.id === control.value);
      const sourceLength = source.length;
      const objDiv = document.querySelector(`.${this.getUniqueClass(1)} .scroll-host`);
      let toIndex;
      if (e.code === 'ArrowUp') {
        if (index === -1) {
          index = sourceLength;
          objDiv.scrollTop = objDiv.scrollHeight;
        }
        toIndex = index - 1;
        if (toIndex === -1) {
          toIndex = sourceLength - 1;
          objDiv.scrollTop = objDiv.scrollHeight;
        }
      } else {
        toIndex = index + 1;
        if (toIndex === sourceLength) {
          toIndex = 0;
          objDiv.scrollTop = -500;
        }
      }
      if (toIndex > -1 && toIndex < sourceLength) {
        control.setValue(source[toIndex].id);
      }
    }
    return true;
  }

}
