import { Injectable } from '@angular/core';
import {Alert, AlertService} from 'ngx-alerts';
import { SNACKBAR_TYPES } from '@shared/snackbar/snackbar.constants';
import { TranslateService } from '@ngx-translate/core';



@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  private alerts: Alert[];

  constructor(
    private alertService: AlertService,
    private translateService: TranslateService,
  ) {
    this.alertService.messages.subscribe(alert => {
      this.alerts = alert;
    });
  }

  handleErrorsAndOpen(msg: string, errors: Array<{error: string, field: string}> | null = null) {
    // this.closeAll();
    try {
      for (const item of errors) {
        // this.open(`${item.field}: ${item.error}`, SNACKBAR_TYPES.ERROR);
        this.open(item.error, SNACKBAR_TYPES.ERROR);
      }
    } catch (e) {
      this.open(msg, SNACKBAR_TYPES.ERROR);
    }
    // this.open(msg, SNACKBAR_TYPES.ERROR);
  }

  open(message: string, type: string = SNACKBAR_TYPES.SUCCESS, args = null) {
    if (message) {
      this.translateService.get(message, args).subscribe(tr => {
        this.alertService[type](tr);
      });
    }
  }

  closeAll() {
    for (const alert of this.alerts) {
      this.alertService.close(alert);
    }
  }

}
