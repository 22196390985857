import { CarFullSearchService } from '@shared/providers/search/car-full-search.service';
import { CarAdvancedSearchService } from '@shared/providers/search/car-advanced-search.service';
import { CarFastSearchService } from '@shared/providers/search/car-fast-search.service';
import { CarVehicleTypeService } from '@shared/providers/search/car-vehicle-type.service';
import { CarAddEditService } from '@shared/providers/search/car-add-edit.service';

export const FIELD_PROVIDERS = [
    CarAddEditService,
    CarFullSearchService,
    CarAdvancedSearchService,
    CarFastSearchService,
    CarVehicleTypeService,
]