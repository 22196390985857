import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
// Constants
import { FULL_ROUTES } from './routes';
// utils
import { languageMatcher } from '@shared/utils/route.utils';
// Resolvers
import { CarResolveDetailService } from '@shared/providers/car/car-resolve-detail.service';
import { SearchResolveService } from '@shared/providers/car/search-resolve.service';
import { AccountInfoResolveService } from '@shared/providers/account/account-info-resolve.service';
// Components
import { HomeComponent } from './home/home.component';
import { SearchCarComponent } from './components/search-car/search-car.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CarSingleComponent } from './car-single/car-single.component';
import { PrivacyPolicyComponent } from './terms-and-privacy/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-privacy/terms-and-conditions/terms-and-conditions.component';


const routes: Routes = [
  {
    matcher: languageMatcher,
    component: HomeComponent
  },
  {
    path: FULL_ROUTES.HOME,
    component: HomeComponent,
    data: {
      title: 'PAGE.HOME.TITLE'
    }
  },
  {
    path: FULL_ROUTES.SEARCH_CAR,
    component: SearchCarComponent,
    data: {
      headerClass: 'dark',
      title: 'PAGE.SEARCH.TITLE'
    },
    resolve: {cars: SearchResolveService},
  },
  {
    path: FULL_ROUTES.AUTH,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: FULL_ROUTES.ACCOUNT,
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
  },
  {
    path: FULL_ROUTES.CAR_MANAGE,
    loadChildren: () => import('./modules/add-edit-car/add-edit-car.module').then(m => m.AddEditCarModule)
  },
  {
    path: FULL_ROUTES.OFFICIALS,
    loadChildren: () => import('./modules/officials/officials.module').then(m => m.OfficialsModule)
  },
  {
    path: FULL_ROUTES.ABOUT_US,
    component: AboutUsComponent,
    data: {
      title: 'PAGE.ABOUT.TITLE'
    }
  },
  {
    path: FULL_ROUTES.CONTACT_US,
    component: ContactUsComponent,
    data: {
      title: 'PAGE.CONTACT_US.TITLE'
    },
    resolve: {accountInfo: AccountInfoResolveService}
  },
  {
    path: FULL_ROUTES.CAR_SINGLE_FULL_PATH,
    component: CarSingleComponent,
    data: {
      headerClass: 'dark'
    },
    resolve: {car: CarResolveDetailService},
  },
  {
    path: FULL_ROUTES.CAR_SINGLE_WITH_ID,
    component: CarSingleComponent,
    data: {
      headerClass: 'dark'
    },
    resolve: {car: CarResolveDetailService},
  },
  {
    path: FULL_ROUTES.PRIVACY_POLICY,
    component: PrivacyPolicyComponent,
    data: {
      headerClass: 'dark',
      title: 'PAGE.PRIVACY_POLICY.TITLE'
    }
  },
  {
    path: FULL_ROUTES.TERMS_AND_CONDITIONS,
    component: TermsAndConditionsComponent,
    data: {
      headerClass: 'dark',
      title: 'PAGE.TERMS_AND_CONDITIONS.TITLE'
    }
  },
  {
    path: '**',
    redirectTo: FULL_ROUTES.HOME
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
  providers: [CarResolveDetailService, SearchResolveService]
})
export class AppRoutingModule { }
