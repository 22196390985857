import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Form } from '@shared/helpers/forms/form';
import { emailValidator } from '@shared/validators/email.validator';
import { notOnltSpaces, onlyAlphabeticChars } from '@shared/validators/string.validator';

export abstract class ContactUs extends Form {

    protected constructor() {
        super();
        this.formConfigs();
    }

    formConfigs(): void {
        this.validationMessages = {
            required: 'VALIDATION.REQUIRED'
        };
        this.form = new FormGroup({
            name: new FormControl('', [
                Validators.required,
                onlyAlphabeticChars,
                notOnltSpaces
            ]),
            email: new FormControl('', [Validators.required, emailValidator]),
            message: new FormControl('', [
                Validators.required,
                notOnltSpaces
            ]),
        });
    }
}
