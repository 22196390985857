import { Component, Input, OnInit } from '@angular/core';
import { CarFromAnotherWebsite } from '@shared/interfaces/car/car-from-another-website.interface';

@Component({
  selector: 'app-dynamic-tasks',
  templateUrl: './dynamic-tasks.component.html'
})
export class DynamicTasksComponent implements OnInit {

  @Input() tasks: CarFromAnotherWebsite[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
