import { SNACKBAR_TYPES } from '@shared/snackbar/snackbar.constants';
import { environment } from '@environments/environment';
import { carSlug } from '@shared/utils/utils';
import { EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Car } from '@shared/interfaces/car/car.interface';
import { AuthService } from '@shared/providers/auth/auth.service';
import { Router } from '@angular/router';
import { FULL_ROUTES } from '../../../routes';
import { Subscription } from 'rxjs';
import { SnackbarService } from '@shared/snackbar/snackbar.service';
import { AccountService } from '@shared/providers/account/account.service';
import { FavouriteAction } from '@shared/interfaces/account/favourite.interface';
import { RouterLoaderService } from '@shared/providers/router/router-loader.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

declare let FB;

export abstract class CarDetailAction implements OnDestroy {

    private $subscriptions: Subscription = new Subscription();
    public readonly fullRoutes = FULL_ROUTES;
    isFavouritePending = false;
    @Output() onFavouriteChange = new EventEmitter<FavouriteAction>();
    @Input() car: Car;

    protected constructor(
        protected authService: AuthService,
        protected router: Router,
        protected accountService: AccountService,
        protected snackbarService: SnackbarService,
        protected routerLoaderService: RouterLoaderService,
        protected ngxSmartModalService: NgxSmartModalService
    ) {}


    get isTop() {
        return this.car && this.car.advert && this.car.advert.is_top;
    }

    toggleFavourite() {
        if (!this.authService.isLoggedIn) {
            this.router.navigate([`/${this.fullRoutes.SIGN_IN}`]);
            return;
        }
        if (!this.isFavouritePending) {
            this.routerLoaderService.loading = true;
            this.isFavouritePending = true;
            if (this.car.favorite && this.car.favorite.favorite) {
                this.$subscriptions.add(
                    this.accountService.removeFromFavourites(this.car.favorite.favorite_id).subscribe(res => {
                            this.changeFavourite(null);
                            this.onFavouriteChange.emit({
                                inFavourite: false,
                                id: this.car.id
                            });
                            this.snackbarService.open('TOASTS.FAVOURITE_DELETED');
                        },
                        err => this.snackbarService.open('SHARED.ERROR_MESSAGE', SNACKBAR_TYPES.ERROR)
                    ).add(() => {
                        this.isFavouritePending = false;
                        this.routerLoaderService.loading = false;
                    })
                );
            } else {
                this.$subscriptions.add(
                    this.accountService.addToFavourites({ advert: this.car.advert.id }).subscribe(res => {
                            this.changeFavourite(res['car'].favorite);
                            this.onFavouriteChange.emit({
                                inFavourite: true,
                                id: this.car.id
                            });
                            this.snackbarService.open('TOASTS.FAVOURITE_ADDED');
                        }, err => this.snackbarService.open('SHARED.ERROR_MESSAGE', SNACKBAR_TYPES.ERROR)
                    ).add(() => {
                        this.isFavouritePending = false;
                        this.routerLoaderService.loading = false;
                    })
                );
            }
        }
    }

    changeFavourite(favorite) {
        this.car.favorite = favorite;
    }

    fbShareBtnClick() {
        FB.ui({
            method: 'share',
            href: `${environment.apiHost}/detail/${this.car.id}/${carSlug(this.car)}`,
        }, (response) => {
            // console.log('res:', response);
        });
    }

    openDeleteCarModal() {
        const modal = this.ngxSmartModalService.getModal('appCarDeleteModal');
        modal.setData(this.car.id);
        modal.open();
    }

    openAddTopModal() {
        const modal = this.ngxSmartModalService.getModal('appCarAddTopModal');
        modal.setData(this.car.advert.id);
        modal.open();
    }

    ngOnDestroy(): void {
        this.$subscriptions.unsubscribe();
    }

}
