import { Component, OnInit } from '@angular/core';
import { CarAdvancedSearchService } from '@shared/providers/search/car-advanced-search.service';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html'
})
export class AdvancedSearchComponent implements OnInit {

  constructor(
      public advancedSearch: CarAdvancedSearchService
  ) {}

  ngOnInit(): void {
    this.advancedSearch.initFunctions();
  }

}
