import { ValidationErrors, FormControl } from '@angular/forms';
import {PhoneNumberUtil} from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function phoneNumberValidator(control: FormControl, regionCode?: string): ValidationErrors | null {
    return isValidPhoneNumber(control.value, regionCode) ? null : { invalidPhoneNumber: 'VALIDATION.PHONE_NUMBER' };
}

export function isValidPhoneNumber(value: string, regionCode = 'am'): boolean {
  let validNumber = false;
  try {
    if (value) {
      let amconf = true;
      if (regionCode === 'am') {
        amconf = /^[+0-9]*$/.test(value);
      }
      if (amconf) {
        const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
          value, regionCode
        );
        validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
      }
    }
  } catch (e) {

  }
  return validNumber;
}
