import { Injectable } from '@angular/core';
import { FastSearch } from '@shared/helpers/search/fast-search-form';
import { CarService } from '@shared/providers/car/car.service';
import { DynamicFormCreateService } from '../../../modules/dynamic-fields/providers/dynamic-form-create.service';

@Injectable({
  providedIn: 'root'
})
export class CarFastSearchService extends FastSearch {

  constructor(
      protected carService: CarService,
      protected dynamicForm: DynamicFormCreateService
  ) {
    super(dynamicForm, carService);
  }
}
