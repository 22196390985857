<div [ngClass]="[field.fieldClass]" [formGroup]="group">
    <p *ngIf="field.label"
       class="form-label"
       [ngClass]="[field.theme]"
       [class.invalid]="isTouchedAndInvalid(field.formControlName)">
        {{field.label | translate }}
    </p>
    <p *ngIf="field.loading"
       class="form-label" [ngClass]="[field.theme]">
       {{'SHARED.LOADING_TEXT' | translate}}
    </p>
    <ul *ngIf="!field.loading" class="flex align-center flex-wrap colors-list">
        <li *ngFor="let color of field.source">
            <label class="circle-radio btn-unstyled"
                   (click)="chooseColor(color.id)"
                   [title]="color.name"
                   [class.active]="color.id === fieldValue"
                   [class.no-border]="!color.hash"
                   [ngClass]="(isLightColor(color.hash))?'light-color':'dark-color'"
                   [style.background-color]="color.hash">
<!--                <input type="radio" [value]="color.id" [formControlName]="field.formControlName">-->
                <img  *ngIf="!color.hash" src="../../../../../assets/images/other.svg" [alt]="color.name">
                <span class="icon-check"></span>
            </label>
        </li>
    </ul>
    <app-print-field-errors *ngIf="field.printErrors && isTouchedAndInvalid(field.formControlName)"
                            [field]="field"
                            [errors]="group.get(field.formControlName).errors">
    </app-print-field-errors>
</div>