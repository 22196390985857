export interface CarImageResult {
    advert?: any;
    id?: number;
    image: any;
    thumbnail?: string;
    position?: number;
}

export class DynamicImageProperty {
    result: CarImageResult[] = [];
    files: CarImageResult[] = [];
    toDelete: CarImageResult['id'][] = [];


    set value(val) {
        this.result = [...val];
        this.files = [...val];
    }

    add(image, file) {
        this.result.push({
            image,
            position: this.result.length - 1
        });
        this.files.push({
            image: file
        });
    }

    remove(index = 0) {
        this.files.splice(index, 1);
        const imageId = this.result[index].id;
        this.result.splice(index, 1);
        if (imageId) {
            this.toDelete.push(imageId);
        }
    }

    get toAdd() {
        return this.files.map((item, index) => ({
            ...item,
            position: index + 1
        })).filter(item => !item.id);
    }

    get alreadyAdded() {
        return this.files.map((item, index) => ({
            id: item.id,
            position: index + 1
        })).filter(item => item.id);
    }

    get changeDetected() {
        return this.toAdd.length || this.toDelete.length || this.alreadyAdded.length;
    }
}
