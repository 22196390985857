import { Component, OnDestroy, ViewChild } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent
} from '@angular/router';
import { NgProgressComponent } from 'ngx-progressbar';
import { RouterLoaderService } from '@shared/providers/router/router-loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page-preloader',
  templateUrl: './page-preloader.component.html'
})
export class PagePreloaderComponent implements OnDestroy {

  private $subscriptions: Subscription = new Subscription();

  public static isGoToTopEnabled = true;
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;

  constructor(
      private readonly router: Router,
      private readonly routerLoaderService: RouterLoaderService,
  ) {
    this.$subscriptions.add(
        router.events.subscribe((event: RouterEvent) => {
          this.navigationInterceptor(event);
        })
    );
    this.$subscriptions.add(
        // Shows and hides the loading spinner in custom cases using Subject in service
        this.routerLoaderService.$loader.subscribe(state => {
          this.progressBar.start();
        })
    );
    this.$subscriptions.add(
        this.routerLoaderService.$stopLoader.subscribe(() => {
          this.progressBar.complete();
        })
    );
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.progressBar.start();
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
    ) {
      this.progressBar.complete();
      if (PagePreloaderComponent.isGoToTopEnabled) {
       /*window.scrollTo({
          top: 0,
          left: 0,
          // behavior: 'smooth'
        });*/
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.unsubscribe();
  }

}
