import { FormControl } from '@angular/forms';

export function isValidNumber(value: any) {
  return /^\d+$/.test(value);
}


export function onlyNumbersWithLength(length: number): (control: FormControl) => { [p: string]: string } {
  return (control: FormControl): { [key: string]: string } => {
    if (!control.value) {
      return null;
    }
    if (!isValidNumber(`${control.value}`)) {
      return {notOnlyNumbers: 'VALIDATION.ONLY_NUMBERS'};
    }
    if (`${control.value}`.length !== length) {
      return {pattern: 'VALIDATION.MIN_LENGTH_MESSAGE'};
    }

    return null;
  };
}


export function onlyNumbersWithMinMax(min: number, max: number): (control: FormControl) => { [p: string]: string } {
  return (control: FormControl): { [key: string]: string } => {
    if (control.value == null || !control.value.toString().length) {
      return;
    }
    if (!isValidNumber(control.value)) {
      return {notOnlyNumbers: 'VALIDATION.ONLY_NUMBERS'};
    }
    const value = parseFloat(control.value.toString());
    if (value >= min && value <= max) {
      return null;
    }
    return {numberMinMax: 'VALIDATION.INVALID_VALUE'};
  };
}
