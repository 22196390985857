import { ValidationErrors, ValidatorFn, Validators, FormControl } from '@angular/forms';

import { emailValidator } from './email.validator';
import { phoneNumberValidator } from './phone-number.validator';

export function emailOrPhoneNumberValidator(
    regionCode?: string,
    emailMinLength: number = -Infinity,
    emailMaxLength: number = Infinity,
    phoneMinLength: number = -Infinity,
    phoneMaxLength: number = Infinity,
): ValidatorFn {
    return function (control: FormControl): ValidationErrors | null {
        const emailLengthValidationResult: ValidationErrors = Validators.minLength(emailMinLength)(control) || Validators.maxLength(emailMaxLength)(control);
        const emailValidationResult: ValidationErrors | null = emailValidator(control);
        if (!emailLengthValidationResult && !emailValidationResult) return null;

        const phoneNumberLengthValidationResult: ValidationErrors = Validators.minLength(phoneMinLength)(control) || Validators.maxLength(phoneMaxLength)(control);
        const phoneNumberValidationResult: ValidationErrors | null = phoneNumberValidator(control, regionCode);
        if (!phoneNumberValidationResult && !phoneNumberLengthValidationResult) return null;

        if (emailValidationResult && phoneNumberValidationResult) return { ...emailValidationResult, ...phoneNumberValidationResult };

        if (!emailValidationResult && emailLengthValidationResult) return emailLengthValidationResult;
        return phoneNumberLengthValidationResult;
    }
}
