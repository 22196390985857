<!-- Hero block start -->
<section class="hero">
  <h1 class="hero-title color-white text-center"
      [innerHTML]="'PAGE.HOME.SUB_TEXT' | translate">
  </h1>
</section>
<!-- Hero block end -->


<!-- Search section start -->
<section class="search" id="searchSection">
  <app-search-section></app-search-section>
</section>
<!-- Search section end -->

<!--Top vehicles start -->
<section class="primary-additions overflow-hidden">
  <div class="container">
    <h2 class="color-secondary h4 mb-8 title">{{ 'TOP.TOP_ADS' | translate }}</h2>
    <app-top-vehicles [isTop]="true"></app-top-vehicles>
  </div>
</section>
<!--Top vehicles end -->

<!-- Demo section start -->
<section class="demo bg-tertiary relative">
  <div class="demo-top flex align-center h-50">
    <div class="container text-xxs-center text-md-left">
      <h2 class="color-black h1 section-title" [innerHTML]="'PAGE.HOME.HOW_TO_SEARCH_FOR_A_CAR' | translate"></h2>
      <a [routerLink]="'/' + fullRoutes.SEARCH_CAR" [queryParams]="searchQueryParam" class="btn filled primary shadow mt-6">
        {{ 'SEARCH.LABEL' | translate }}
      </a>
    </div>
    <div class="demo-video right">
      <iframe src="https://www.youtube.com/embed/_Ed5bFOsrv8?color=white"
              style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="fullscreen"
              allow="autoplay" allowfullscreen></iframe>
    </div>
  </div>
  <div class="demo-bottom flex align-center h-50">
    <div class="container text-xxs-center text-md-right">
      <h2 class="color-black h1 section-title to-left">{{ 'PAGE.HOME.HOW_TO_SIGN_UP' | translate }}
      </h2>
      <a *ngIf="!authService.isLoggedIn" [routerLink]="fullRoutes.SIGN_UP" class="btn filled primary shadow mt-6">
        {{ 'AUTH.SIGN_UP' | translate }}
      </a>
    </div>
    <div class="demo-video left">
      <iframe src="https://www.youtube.com/embed/ur0MYIFAJX0?color=white"
              style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="fullscreen"
              allow="autoplay" allowfullscreen></iframe>
    </div>
  </div>
</section>
<!-- Demo section end -->


<!--Last added vehicles start -->
<section class="primary-additions overflow-hidden">
  <div class="container">
    <h2 class="color-secondary h4 mb-8 title">{{ 'PAGE.HOME.LAST_ADDED' | translate }}</h2>
    <app-top-vehicles></app-top-vehicles>
  </div>
</section>
<!--Last added vehicles end -->

<!-- Apps download section start-->
<section class="apps-download flex align-center relative overflow-hidden">
  <div class="container">
    <p class="color-white fw-700 title">{{ 'PAGE.HOME.MULTIPLATFORM_SYSTEM' | translate }}</p>
    <p class="color-white fs-lg mt-7 sub-title" [innerHTML]="'PAGE.HOME.CHOOSE_YOUR_PLATFORM' | translate"></p>
    <ul class="app-stores flex align-center mt-9">
      <li>
        <a [href]="socialAppLinks.APP_STORE" class="inline-block hover-opacity">
          <img src="/assets/images/app-store.svg" alt="App store" class="block">
        </a>
      </li>
      <li>
        <a [href]="socialAppLinks.GOOGLE_PLAY" class="inline-block hover-opacity">
          <img src="/assets/images/google-play.svg" alt="Google play" class="block">
        </a>
      </li>
    </ul>
  </div>
</section>
<!-- Apps download section end -->

<!-- Sign up section start -->
<app-home-sign-in *ngIf="!authService.isLoggedIn"></app-home-sign-in>
<!-- Sign up section end -->
