import { Injectable } from '@angular/core';
import { VehicleTypeSearch } from '@shared/helpers/search/vehicle-type-search-form';
import { CarService } from '@shared/providers/car/car.service';
import { DynamicFormCreateService } from '../../../modules/dynamic-fields/providers/dynamic-form-create.service';

@Injectable({
  providedIn: 'root'
})
export class CarVehicleTypeService extends VehicleTypeSearch {

  constructor(
      public readonly carService: CarService,
      public readonly dynamicForm: DynamicFormCreateService,
  ) {
    super(dynamicForm, carService);
  }

}
