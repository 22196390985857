import { Component, Input } from '@angular/core';
import { RuleList } from '../../interfaces/rule.interface';

@Component({
  selector: 'app-rules-list',
  templateUrl: './rules-list.component.html'
})
export class RulesListComponent {

  @Input() pageTitle: string;
  @Input() rules: RuleList[] = [];
}
