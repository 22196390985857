import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterLoaderService {
  $loader: Subject<boolean | {loader: boolean}>;
  $stopLoader: Subject<void>;

  constructor() {
    this.$loader = new Subject<boolean | {loader: boolean}>();
    this.$stopLoader = new Subject<void>();
  }

  set loading(value) {
    if (value) {
      this.$loader.next(true);
    } else {
      this.$stopLoader.next();
    }
  }

}
