import { AdditionalParams, FieldConfig, Source } from '../interfaces/field.interface';
import { ValidatorFn } from '@angular/forms';
import { DynamicImageProperty } from './DynamicImageProperty';

export class DynamicField implements FieldConfig {

    public type: string;
    public updateType?: 'change' | 'blur' | 'submit';
    public inputType = 'text';
    public wrapperClass = '';
    public fieldClass = '';
    public theme = 'dark';
    public formControlName?: string | string[];
    public label?: string;
    public placeholder?: string;
    public source?: Source[];
    public value?: any;
    public loading?: boolean;
    public disabled?: boolean;
    public readonly?: boolean;
    public pattern?: RegExp | string;
    public specialCharacters: string[] = [];
    public mask?: string;
    public notClearable?: boolean;
    public validation?: ValidatorFn[];
    public printErrors?: boolean;
    public validationMessages?: {[key: string]: string};
    public additionalParams?: AdditionalParams;
    private fieldImages?: DynamicImageProperty;

    constructor(parameters: FieldConfig) {
        // tslint:disable-next-line:forin
        this.addExtraParameters(parameters);
        if (parameters.type === 'imageUpload') {
            this.images = new DynamicImageProperty();
        }
    }

    onChange() {}

    get toControlNameArray() {
        return this.formControlName.toString().split(',');
    }

    get toControlNameString() {
        return this.formControlName.toString();
    }

    addExtraParameters(parameters) {
        // tslint:disable-next-line:forin
        for (const key in parameters) {
            this[key] = parameters[key];
        }
    }

    set isLoading(value) {
        this.loading = value;
        this.disabled = value;
    }

    get fullWrapperClass() {
        return !this.printErrors ? this.wrapperClass : `${this.wrapperClass} dynamic-input-no-align`;
    }

    get images() {
        return this.fieldImages;
    }

    set images(value) {
         this.fieldImages = value;
    }
}
