<div *ngIf="images?.length; else noImage" class="car-images">

    <!-- Main image slider -->
    <ngx-slick-carousel class="carousel carousel-big" [config]="slideImagesBigConfig">
        <div *ngFor="let item of images; let i = index" ngxSlickItem class="slide"
             (click)="carouselIndex = i;ngxSmartModalService.getModal('galleryModal').open()">
            <img [src]="item.image" class="img mx-auto">
        </div>
    </ngx-slick-carousel>

    <!-- Thumbnail images slider  -->
    <ngx-slick-carousel class="carousel carousel-small" [config]="slideImagesSmallConfig">
        <div ngxSlickItem class="slide" *ngFor="let item of images">
            <div class="img" [style.background-image]="'url('+item.image+')'"></div>
        </div>
    </ngx-slick-carousel>
</div>

<ng-template #noImage>
    <div class="car-img-placeholder">
        <img src="/assets/images/car-placeholder.svg"
             class="block">
    </div>
</ng-template>


<ngx-smart-modal
    #galleryModal
    identifier="galleryModal"
    customClass="modal gallery-modal nsm-centered"
    (onOpen)="galleryModalOpened = true"
    (onClose)="galleryModalOpened = false"
    (onOpenFinished)="openGallery()"
>
    <div class="car-images" *ngIf="galleryModalOpened">

        <!-- Main image slider -->
        <ngx-slick-carousel #mainCarousel class="carousel carousel-big carousel-big-modal" [config]="slideImagesBigModalConfig">
            <div *ngFor="let item of images" ngxSlickItem class="slide">
                <img [src]="item.image" class="img mx-auto">
            </div>
        </ngx-slick-carousel>

        <!-- Thumbnail images slider  -->
        <ngx-slick-carousel class="carousel carousel-small carousel-small-modal" [config]="slideImagesSmallModalConfig">
            <div ngxSlickItem class="slide" *ngFor="let item of images">
                <div class="img" [style.background-image]="'url('+item.image+')'"></div>
            </div>
        </ngx-slick-carousel>

    </div>

</ngx-smart-modal>