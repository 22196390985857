import { Injectable } from '@angular/core';
import { User } from '@shared/interfaces/user.interface';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@shared/providers/auth/auth.service';
import { AccountService } from '@shared/providers/account/account.service';
import { catchError } from 'rxjs/operators';
import { ResolveService } from '@shared/providers/car/resolve-service';
import { SnackbarService } from '@shared/snackbar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class AccountInfoResolveService extends ResolveService implements Resolve<User> {

  constructor(
      public readonly router: Router,
      public readonly authService: AuthService,
      private readonly accountService: AccountService,
      public readonly snackbarService: SnackbarService,
  ) {
    super(router, authService, snackbarService);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    const id = this.authService.userId;
    if (!id) {
      return this.optionalData();
    }
    return this.accountService.getAccountInfo<User>(id).pipe(
        catchError(err =>  this.optionalData())
    );
  }
}
