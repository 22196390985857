<pagination-template #p="paginationApi" (pageChange)="pageChange.emit($event)">

    <ul class="pagination">
        <li class="arrow arrow-prev"
            [class.disabled]="p.isFirstPage()"
            (click)="!p.isFirstPage() && p.previous()">
            <i class="icon-left"></i>
        </li>

        <ng-container *ngFor="let page of p.pages">
            <li *ngIf="p.getCurrent() !== page.value"
                (click)="p.setCurrent(page.value)"
                class="page">
                {{ page.label }}
            </li>
            <li *ngIf="p.getCurrent() === page.value" class="page active">
                {{ page.label }}
            </li>
        </ng-container>

        <li class="arrow arrow-next"
            [class.disabled]="p.isLastPage()"
            (click)="!p.isLastPage() && p.next()">
            <i class="icon-right"></i>
        </li>
    </ul>

</pagination-template>