import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CarService } from '@shared/providers/car/car.service';
import { AuthService } from '@shared/providers/auth/auth.service';
import { Car } from '@shared/interfaces/car/car.interface';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { carSlug } from '@shared/utils/utils';
import { SnackbarService } from '@shared/snackbar/snackbar.service';
import { SeoService } from '@shared/providers/seo/seo.service';
import { ResolveService } from '@shared/providers/car/resolve-service';

@Injectable({
  providedIn: 'root'
})
export class CarResolveDetailService extends ResolveService {

  constructor(
      private readonly carService: CarService,
      public readonly router: Router,
      public readonly authService: AuthService,
      public readonly snackbarService: SnackbarService,
      private readonly seoService: SeoService
  ) {
    super(router , authService , snackbarService);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    return this.carService.getCar<Car>(route.params.id).pipe(
        map(result => {
          if (result) {
            if (route.params.slug && route.params.slug !== carSlug(result)) {
              this.router.navigateByUrl(state.url.replace(route.params.slug, carSlug(result)));
              return;
            }
            this.seoService.setPageTitle(carSlug(result));
            return of(result);
          } else {
            return EMPTY;
          }
        }),
        catchError(err => this.errorHandler(err))
    );
  }
}
