import { Component, OnInit } from '@angular/core';
import { DynamicComponent } from '../../classes/DynamicComponent';
import { CarImageValidation } from '@shared/constants/validation/validation.constant';
import { isFileTypeUnsupported } from '@shared/validators/unsupported-file-type-validator';
import { DynamicImageProperty } from '../../classes/DynamicImageProperty';

@Component({
    selector: 'app-dynamic-image-upload',
    templateUrl: './dynamic-image-upload.component.html'
})
export class DynamicImageUploadComponent extends DynamicComponent implements OnInit {
    maxLength = CarImageValidation.MAX_LENGTH;
    sizeMB = CarImageValidation.SIZE_MB;
    maxSize = CarImageValidation.MAX_SIZE;
    unsupportedImage: boolean;
    bigImage: boolean;
    images: DynamicImageProperty;

    ngOnInit() {
        this.images = this.field.images;
        this.images.value = [...this.group.get(this.field.formControlName).value];
    }

    onFileChange(event: any): void {
        const files = event.target.files;
        if (files && files.length) {
            for (const file of files) {
              if (isFileTypeUnsupported(file)) {
                this.unsupportedImage = true;
                continue;
              }
              if (file.size > this.maxSize) {
                this.bigImage = true;
                continue;
              }
              const reader = new FileReader();
              reader.onload = (e) => {
                  if (this.images.result.length < this.maxLength) {
                      this.images.add(e.target.result, file);
                  }
              };
              reader.readAsDataURL(file);
            }
        }
    }

    removeImage(index) {
        this.images.remove(index);
    }

    makeMainImage(index) {
        if (index === 0) {
            return;
        }
        this.swap(this.images.result, index);
        this.swap(this.images.files, index);
    }

    private swap(arr, index: number): void {
        const x = arr[index];
        arr[index] = arr[0];
        arr[0] = x;
    }

}
