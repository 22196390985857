import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// directives
import { DynamicFieldDirective } from './directives/dynamic-field.directive';
// components
import { DynamicFieldsComponent } from './dynamic-fields.component';
import { DynamicSelectComponent } from './components/dynamic-select/dynamic-select.component';
import { DynamicInputComponent } from './components/dynamic-input/dynamic-input.component';
import { DynamicColorComponent } from './components/dynamic-color/dynamic-color.component';
import { DynamicSwitcherComponent } from './components/dynamic-switcher/dynamic-switcher.component';
import { PrintFieldErrorsComponent } from './parts/print-field-errors/print-field-errors.component';
import { DynamicInputWithSelectComponent } from './components/dynamic-input-with-select/dynamic-input-with-select.component';
import { DynamicImageUploadComponent } from './components/dynamic-image-upload/dynamic-image-upload.component';
import { DynamicTextareaComponent } from './components/dynamic-textarea/dynamic-textarea.component';
import { NgxMaskModule } from 'ngx-mask';
import { DynamicAddressAutocompleteComponent } from './components/dynamic-address-autocomplete/dynamic-address-autocomplete.component';

@NgModule({
    declarations: [
        DynamicFieldDirective,
        DynamicSelectComponent,
        DynamicFieldsComponent,
        DynamicInputComponent,
        DynamicFieldDirective,
        DynamicColorComponent,
        DynamicSwitcherComponent,
        PrintFieldErrorsComponent,
        DynamicInputWithSelectComponent,
        DynamicImageUploadComponent,
        DynamicTextareaComponent,
        DynamicAddressAutocompleteComponent,
    ],
    exports: [
        DynamicFieldsComponent
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule
    ]
})
export class DynamicFieldsModule { }
