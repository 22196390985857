import {AbstractControl} from '@angular/forms';
import { getHostnameFromUrl } from '@shared/utils/utils';

const supportedWebsites = [
  'iaai.com',
  'copart.com',
  'auto.am',
  'list.am'
];

export function supportedSitesToAddCarFromValidator(control: AbstractControl): { [key: string]: string } {
  if (!control.value) {
    return null;
  }
  const url = getHostnameFromUrl(control.value);
  const exists = supportedWebsites.findIndex(item => item === url);
  return exists > -1 ? null : { unsupportedWebsite: 'VALIDATION.UNSUPPORTED_WEBSITE_URL' };
}
