export const allowedImageTypes = {
  'image/cis-cod': true,
  'image/ief': true,
  'image/jpeg': true,
  'image/jpg': true,
  'image/pipeg': true,
  'image/x-cmu-raster': true,
  'image/x-cmx': true,
  'image/x-icon': true,
  'image/x-portable-anymap': true,
  'image/x-portable-bitmap': true,
  'image/x-portable-graymap': true,
  'image/x-portable-pixmap': true,
  'image/x-rgb': true,
  'image/x-xbitmap': true,
  'image/x-xpixmap': true,
  'image/x-xwindowdump': true,
  'image/vnd.microsoft.icon': true,
  'image/png': true
};

export function isFileTypeUnsupported(image: File): boolean {

  if (!image || !image.type) {
    return true;
  }

  return !allowedImageTypes[image.type];
}
