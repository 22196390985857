<div class="cols-lg-2 add-car-modal">
  <div class="col content-top">
    <h2 class="h1 color-black mb-2">{{ 'ADD_CAR.FROM_OTHER_SITE.MODAL_TITLE' | translate }}</h2>
    <p>{{ 'ADD_CAR.FROM_OTHER_SITE.MODAL_DESCR' | translate }}</p>
    <div class="mt-10 image">
      <img src="./../assets/images/external-add.svg" alt="Illustration">
    </div>
  </div>
  <div class="col card flex align-center justify-center">
    <form [formGroup]="form" class="form-content" (submit)="addCar()">
      <app-dynamic-fields
              class="w-100"
              [group]="form"
              [fields]="items">
      </app-dynamic-fields>
      <div class="text-right button">
        <button class="btn filled primary mt-5" [class.disabled]="loading">
          {{ 'ADD_CAR.FROM_OTHER_SITE.BUTTON_ADD' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
