import {AlertConfig} from 'ngx-alerts';

export const SNACKBAR_CONFIG: AlertConfig = {
  maxMessages: 5,
  timeout: 3 * 1000,
  position: 'right'
};

export enum SNACKBAR_TYPES {
  SUCCESS = 'success',
  ERROR = 'danger',
  WARNING = 'warning',
  INFO = 'info',
}
