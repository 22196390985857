<div [ngClass]="[field.fieldClass]" [formGroup]="group">
    <div *ngIf="field.label">
        <p class="form-label"
           [ngClass]="[field.theme]"
           [class.invalid]="isTouchedAndInvalid(field.formControlName)">
            {{field.label | translate }}
        </p>
    </div>
    <div>
        <div class="text-field"
             [ngClass]="[field.theme]"
             [class.invalid]="isTouchedAndInvalid(field.formControlName)"
             [class.disabled]="!!field.disabled"
        >
            <input [type]="field.inputType"
                   [mask]="field.mask"
                   [pattern]="field.pattern"
                   [specialCharacters]="field.specialCharacters"
                   [formControlName]="field.formControlName"
                   [name]="field.formControlName"
                   [placeholder]="(field.placeholder | translate) || ''">
            <app-print-field-errors *ngIf="field.printErrors && isTouchedAndInvalid(field.formControlName)"
                                    [field]="field"
                                    [errors]="group.get(field.formControlName).errors">
            </app-print-field-errors>
        </div>
    </div>
</div>