import { Component } from '@angular/core';
import { CarDetailAction } from '@shared/helpers/car/car-detail-action';
import { SnackbarService } from '@shared/snackbar/snackbar.service';
import { Router } from '@angular/router';
import { AuthService } from '@shared/providers/auth/auth.service';
import { AccountService } from '@shared/providers/account/account.service';
import { RouterLoaderService } from '@shared/providers/router/router-loader.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-detail-right-section',
  templateUrl: './detail-right-section.component.html'
})
export class DetailRightSectionComponent extends CarDetailAction {

  constructor(
      public readonly authService: AuthService,
      public readonly accountService: AccountService,
      public readonly snackbarService: SnackbarService,
      public readonly router: Router,
      public readonly routerLoaderService: RouterLoaderService,
      protected ngxSmartModalService: NgxSmartModalService
  ) {
    super(
        authService,
        router,
        accountService,
        snackbarService,
        routerLoaderService,
        ngxSmartModalService
    );
  }

}
