import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { CarFromAnotherWebsite } from '@shared/helpers/forms/car-from-another-website';
import { DynamicFormCreateService } from '../../../modules/dynamic-fields/providers/dynamic-form-create.service';
import { CarLocalizeService } from '@shared/providers/car/car-localize.service';
import { SNACKBAR_TYPES } from '@shared/snackbar/snackbar.constants';
import { SnackbarService } from '@shared/snackbar/snackbar.service';
import { Subscription } from 'rxjs';
import { AddCarFromAnotherWebsiteService } from '@shared/providers/advert/add-car-from-another-website.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-add-car-modal',
  templateUrl: './add-car-modal.component.html'
})
export class AddCarModalComponent extends CarFromAnotherWebsite implements OnChanges, OnDestroy {

  @Input() modalOpened: boolean;
  loading: boolean;
  private $subscriptions: Subscription = new Subscription();

  constructor(
      public dynamicForm: DynamicFormCreateService,
      public carLocalize: CarLocalizeService,
      private readonly snackbarService: SnackbarService,
      private readonly addCarFromAnotherWebsite: AddCarFromAnotherWebsiteService,
      private ngxSmartModalService: NgxSmartModalService,
  ) {
    super(dynamicForm, carLocalize);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.modalOpened.currentValue) {
      this.resetForm();
    }
  }

  addCar() {
    if (this.loading) {
      return;
    }

    if (this.form.invalid) {
      this.validateAll();
      this.snackbarService.open('TOASTS.NOT_VALID_SEARCH', SNACKBAR_TYPES.WARNING);
      return;
    }
    this.loading = true;
    this.$subscriptions.add(
        this.addCarFromAnotherWebsite.createRequest(this.form.value).subscribe(
            (res) => {
              this.resetForm();
              this.ngxSmartModalService.getModal('addCarModal').close();
            },
            () => this.snackbarService.open('SHARED.ERROR_MESSAGE', SNACKBAR_TYPES.ERROR)
        ).add(() => this.loading = false)
    );
  }

  ngOnDestroy() {
    this.$subscriptions.unsubscribe();
  }
}
