<!-- Form needs to be added -->
<div class="container">
    <div class="search-form card pt-10">
        <div class="search-space-y">
            <app-vehicle-type></app-vehicle-type>
            <!-- Popular marks list start -->
            <app-popular-brands
                    *ngIf="fastSearch.populars?.length"
                    [popularBrands]="fastSearch.populars"
                    (onBrandSelect)="onBrandSelect($event)">
            </app-popular-brands>
            <!-- Popular marks list start -->
        </div>
        <div class="color-white search-fields mt-7 pb-10" [class.expanded]="expandedView">
            <div class="search-space-y">
                <form [formGroup]="fastSearch.form">
                    <app-dynamic-fields
                            class="w-100"
                            [group]="fastSearch.form"
                            [fields]="fastSearch.items">
                    </app-dynamic-fields>
                </form>
                <div class="cols expandable-search">
                    <div class="search-actions">
                        <button class="btn link pr-0 color-primary expand flex align-center mr-3" [ngClass]="{'expanded': expandedView}" (click)="expandedView = !expandedView">{{ 'SEARCH.ADVANCED' | translate }} <i class="icon-arrow-down ml-3"></i></button>
                        <button class="btn filled primary btn-search flex align-center" (click)="search()">{{ 'SEARCH.LABEL' | translate }} <span class="block ellipsis ml-1"> ({{carCount}})</span></button>
                    </div>
                    <app-advanced-search></app-advanced-search>
                </div>
            </div>
        </div>
    </div>
</div>
