import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {BaseAPIService} from '../base-api.service';
import {AUTH} from '../../constants/api-endpoints';
import { UserLogin, UserLoginWithLink, UserRegister } from '@shared/interfaces/user.interface';
import { Router } from '@angular/router';
import { FULL_ROUTES } from '../../../routes';
import { tap } from 'rxjs/operators';
import { LocalStorageConstants } from '@shared/constants/app-constants';
import { RESPONSE_CODE } from '@shared/constants/api-response-codes';
import { SNACKBAR_TYPES } from '@shared/snackbar/snackbar.constants';
import { SnackbarService } from '@shared/snackbar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseAPIService {

  private readonly fullRoutes = FULL_ROUTES;
  private authToken: string;
  private refresh: string;
  public  id: any;
  public official: any;

  constructor(
    public http: HttpClient,
    private readonly router: Router,
    private readonly snackbarService: SnackbarService
  ) {
    super(http);
  }

  register(credentials: UserRegister) {
    return this.post(AUTH.REGISTER, credentials);
  }

  login(credentials: UserLogin) {
    return this.post(AUTH.LOGIN, credentials);
  }

  loginWithLink<T>(credentials: UserLoginWithLink) {
    return this.post(AUTH.LOGIN_WITH_LINK, credentials);
  }

  refreshExpiredToken() {
    return this.post(AUTH.REFRESH_TOKEN, {
      refresh: this.refreshToken
    }).pipe(tap((res: any) => {
      this.setAuthToken(true, {...res});
    }));
  }

  forgotPassword(credentials: object) {
    return this.post(AUTH.FORGOT_PASSWORD, credentials);
  }

  verifyToken(tokenObj: {token: string}) {
    return this.post(AUTH.TOKEN_VERIFICATION, tokenObj);
  }

  resetPassword(credentials: object, resetKey: string) {
    return this.post(AUTH.RESET_PASSWORD_URL.replace('{resetKey}', resetKey), credentials);
  }

  setAuthToken(rememberMe: boolean, res) {
    this.authToken = res.access;
    this.refresh = res.refresh;
    if (rememberMe) {
      localStorage.setItem(LocalStorageConstants.ACCESS_TOKEN, res.access);
      localStorage.setItem(LocalStorageConstants.REFRESH_TOKEN, res.refresh);
    }
  }

  setUserId(rememberMe: boolean, id) {
    this.id = id;
    if (rememberMe) {
      localStorage.setItem(LocalStorageConstants.USER_ID, id);
    }
  }

  setOfficialId(rememberMe: boolean, id) {
    if (id) {
      this.official = id;
      if (rememberMe) {
        localStorage.setItem(LocalStorageConstants.OFFICIAL_ID, id);
      }
    }
  }

  saveTokenAndUserId(rememberMe: boolean, res) {
    this.setAuthToken(rememberMe, res);
    this.setUserId(rememberMe, res.id);
    this.setOfficialId(rememberMe, res.official_id);
  }

  get isLoggedIn(): boolean {
    // return !!localStorage.getItem('token') || this.cookieService.check('token') || !!this.token;
    return !!localStorage.getItem(LocalStorageConstants.ACCESS_TOKEN) || !!this.authToken;
  }

  get isOfficial(): boolean {
    // return !!localStorage.getItem('token') || this.cookieService.check('token') || !!this.token;
    return this.isLoggedIn && !!this.officialId;
  }

  get token(): string {
    // return localStorage.getItem('token') || this.cookieService.get('token') || this.token;
    return localStorage.getItem(LocalStorageConstants.ACCESS_TOKEN) || this.authToken;
  }

  get refreshToken(): string {
    return localStorage.getItem(LocalStorageConstants.REFRESH_TOKEN) || this.refresh;
  }

  get userId(): string {
    return localStorage.getItem(LocalStorageConstants.USER_ID) || this.id;
  }

  get officialId(): string {
    return localStorage.getItem(LocalStorageConstants.OFFICIAL_ID) || this.official;
  }

  logOut(navigate = false) {
    // this.cookieService.delete(LocalStorageConstants.ACCESS_TOKEN);
    localStorage.removeItem(LocalStorageConstants.ACCESS_TOKEN);
    localStorage.removeItem(LocalStorageConstants.REFRESH_TOKEN);
    localStorage.removeItem(LocalStorageConstants.USER_ID);
    localStorage.removeItem(LocalStorageConstants.OFFICIAL_ID);
    this.authToken = null;
    this.refresh = null;
    this.id = null;
    this.official = null;
    if (navigate) {
      return this.router.navigate([`/${this.fullRoutes.SIGN_IN}`]);
    }
  }

  handlePermissionError(err: HttpErrorResponse, navigate = true) {
    let errorMessage = 'SHARED.ERROR_MESSAGE';
    if (err.status === RESPONSE_CODE.INVALID_PERMISSION) {
      errorMessage = 'TOASTS.INACTIVE_ACCOUNT_OR_INVALID_PERMISSION';
      this.logOut(navigate);
    }
    this.snackbarService.open(errorMessage, SNACKBAR_TYPES.ERROR);
  }

}
