import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarPaginationComponent } from './car-pagination.component';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
    declarations: [
        CarPaginationComponent
    ],
    imports: [
        CommonModule,
        NgxPaginationModule,
    ],
    exports: [
        NgxPaginationModule,
        CarPaginationComponent
    ]
})
export class CarPaginationModule { }
