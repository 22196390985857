<section class="sign-up bg-secondary">
    <div class="container">
        <h2 class="h1 color-white mb-8 title">{{ 'PAGE.HOME.SIGN_UP_SECTION' | translate}}</h2>
        <form  [formGroup]="form" (submit)="submit()">
            <div class="cols cols-md-3 cols-mb-4 cols-mb color-white">
                <div class="col text-field light" [class.invalid]="isTouchedAndInvalid('phoneNumOrEmail')">
                    <p class="form-label">{{ 'AUTH.EMAIL_OR_PHONE' | translate }}</p>
                    <input name="email_or_phone" formControlName="phoneNumOrEmail" type="text" placeholder="example@gmail.com">
                    <ng-container *ngIf="isTouchedAndInvalid('phoneNumOrEmail')">
                        <ng-container *ngFor="let item of form.controls.phoneNumOrEmail.errors | keyvalue">
                            <p class="error-message">{{item.value|translate}}</p>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="col text-field light" [class.invalid]="isTouchedAndInvalid('password')">
                    <p class="form-label">{{ 'AUTH.PASSWORD' | translate }}</p>
                    <input name="password" formControlName="password" type="password" placeholder="********">
                    <ng-container *ngIf="isTouchedAndInvalid('password')">
                        <ng-container *ngFor="let item of form.controls.password.errors | keyvalue">
                            <p class="error-message">{{item.value|translate}}</p>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="col text-field light" [class.invalid]="isInvalidRepeatPassword()">
                    <p class="form-label">{{ 'AUTH.CONFIRM_PASSWORD' | translate }}</p>
                    <input name="repeat_password" formControlName="repeat_password" type="password" placeholder="********">
                    <p *ngIf="isInvalidRepeatPassword()" class="error-message">{{form.errors?.invalidRepeatPassword|translate}}</p>
                </div>
            </div>
            <p class="color-white-50 mt-2">
                {{ 'TERM.TERM_1.PART_1' | translate }}
                <a [href]="'/' + fullRoutes.TERMS_AND_CONDITIONS" target="_blank" class="color-white hover-opacity">
                    {{ 'TERM.TERM_1.PART_2' | translate }}
                </a>
            </p>
            <div class="text-right mt-3">
                <button class="btn filled primary" [class.disabled]="loading">
                    {{ 'AUTH.SIGN_UP' | translate }}
                </button>
            </div>
        </form>
    </div>
</section>
