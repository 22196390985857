import { FormGroup, FormControl } from '@angular/forms';
import { emailOrPhoneNumberValidator } from '@shared/validators/email-or-phone-number.validator';
import { passwordValidator } from '@shared/validators/password.validator';
import { repeatPasswordValidator } from '@shared/validators/repeat-password.validator';
import { Form } from '@shared/helpers/forms/form';
import { UserRegister } from '@shared/interfaces/user.interface';
import { isValidEmail } from '@shared/validators/email.validator';
import { isValidPhoneNumber } from '@shared/validators/phone-number.validator';
import { Subscription } from 'rxjs';
import { AuthService } from '@shared/providers/auth/auth.service';
import { SnackbarService } from '@shared/snackbar/snackbar.service';
import { Router } from '@angular/router';
import { FULL_ROUTES } from '../../../routes';

export abstract class SignUp extends Form {

    public readonly fullRoutes = FULL_ROUTES;
    protected $subscriptions: Subscription = new Subscription();
    // disabling submit action if loading
    public loading = false;

    protected constructor(
        protected readonly authService: AuthService,
        protected readonly snackbarService: SnackbarService,
        protected readonly router: Router,
    ) {
        super();
        this.form = new FormGroup({
                phoneNumOrEmail: new FormControl('', [emailOrPhoneNumberValidator('am')]),
                password: new FormControl('', [passwordValidator]),
                repeat_password: new FormControl('', [passwordValidator]),
            },
            [repeatPasswordValidator]
        );
    }

    handleSignUp() {
        this.loading = true;
        const signUpFormData = this.form.value;
        const payload: UserRegister = {
            password: signUpFormData.password,
            repeat_password: signUpFormData.repeat_password
        };
        const phoneNumOrEmail = signUpFormData.phoneNumOrEmail;
        if (isValidEmail(phoneNumOrEmail)) {
            payload.email = phoneNumOrEmail;
        } else if (isValidPhoneNumber(phoneNumOrEmail, 'am')) {
            payload.phone = phoneNumOrEmail;
        }
        this.$subscriptions.add(
            this.authService.register(payload).subscribe(registerResponse => {
                if (registerResponse['id']) {
                    this.authService.saveTokenAndUserId(true, { ...registerResponse });
                    this.router.navigate([`/${this.fullRoutes.MY_ACCOUNT}`]);
                } else {
                    this.router.navigate([`/${this.fullRoutes.SIGN_IN}`]);
                }
            }, err => {
                this.loading = false;
                this.snackbarService.handleErrorsAndOpen(err.error.detail, err.error.errors);
            })
        );
    }

}
