import { Component, OnDestroy, OnInit } from '@angular/core';
import { FULL_ROUTES } from '../routes';
import { CarService } from '@shared/providers/car/car.service';
import { ActivatedRoute } from '@angular/router';
import { Car } from '@shared/interfaces/car/car.interface';
import { Subscription } from 'rxjs';
import { CarLocalizeService } from '@shared/providers/car/car-localize.service';
import { Price } from '@shared/interfaces/car/price.interface';

@Component({
  selector: 'app-car-single',
  templateUrl: './car-single.component.html'
})
export class CarSingleComponent implements OnInit, OnDestroy {

  private $subscriptions: Subscription = new Subscription();
  car: Car;
  loading = true;
  carDropdownOpened = false;
  carouselConfigs = {
    type: 'similar',
    query: {offset: 0, limit: 8}
  };
  pricesList: Array<{
    currency: string,
    price: string | number
  }> = [];

  constructor(
      private readonly carService: CarService,
      private readonly activatedRoute: ActivatedRoute,
      public readonly carLocalize: CarLocalizeService
  ) { }

  public readonly fullRoutes = FULL_ROUTES;

  ngOnInit() {
    this.$subscriptions.add(
        this.activatedRoute.data.subscribe((data: any) => {
          if (data.car) {
            data.car.subscribe(car => {
              this.car = car;
              this.getPricesList(car.price);
            }).unsubscribe();
          }
        })
    );
  }

  getPricesList(price: Array<Price>) {
    this.pricesList = [];
    const currencyKeys = Object.keys(this.carLocalize.currency);
    if (price && price.length) {
      const prices = price[0];
      for (const key in prices) {
       if (prices.hasOwnProperty(key) && !key.includes(this.car.currency)) {
         const currencyKey = currencyKeys.find(item => key.includes(item));
         this.pricesList.push({
           currency: this.carLocalize.currency[currencyKey],
           price: prices[key]
         });
       }
      }
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.unsubscribe();
  }

}
