import { ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { CarFromAnotherWebsite } from '@shared/interfaces/car/car-from-another-website.interface';
import { InProgressTaskComponent } from '../components/in-progress-task/in-progress-task.component';
import { ADD_CAR_STATUSES } from '@shared/constants/car/add-car-scraper.constant';
import { FailedTaskComponent } from '../components/failed-task/failed-task.component';
import { CompletedTaskComponent } from '../components/completed-task/completed-task.component';

const componentMapper = {
  [ADD_CAR_STATUSES.PENDING]: InProgressTaskComponent,
  [ADD_CAR_STATUSES.FAILURE]: FailedTaskComponent,
  [ADD_CAR_STATUSES.SUCCESS]: CompletedTaskComponent,
};

@Directive({
  selector: '[appDynamicTask]'
})
export class DynamicTaskDirective implements OnInit {

  @Input() task: CarFromAnotherWebsite;
  componentRef: any;

  constructor(
      private resolver: ComponentFactoryResolver,
      private container: ViewContainerRef,
  ) { }

  ngOnInit(): void {
    if (this.task.status in componentMapper) {
      const factory = this.resolver.resolveComponentFactory(
          componentMapper[this.task.status]
      );
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.task = this.task;
    }
  }

}
