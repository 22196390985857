import { Component } from '@angular/core';
import { FULL_ROUTES } from '../../routes';
import { RuleList } from '../interfaces/rule.interface';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html'
})
export class TermsAndConditionsComponent {

  private readonly fullRoutes = FULL_ROUTES;

  rules: RuleList[] = [
    {
      descriptions: [
          'TERM.TERM_2', 'TERM.TERM_3', 'TERM.TERM_4',
          'TERM.TERM_5', 'TERM.TERM_6', 'TERM.TERM_7',
          'TERM.TERM_8', 'TERM.TERM_9'
      ]
    },
    {
      title: 'TERM.TERM_10',
      descriptions: ['TERM.TERM_11'],
    },
    {
      title: 'TERM.TERM_12',
      descriptions: [{
        text: 'TERM.TERM_13',
        args: {href: `/${this.fullRoutes.CONTACT_US}`}
      }]
    }
  ];
}
