<div class="select-box"
     [class.disabled]="!!field.disabled"
     [ngClass]="[field.theme, field.fieldClass, getUniqueClass()]"
     [class.invalid]="isTouchedAndInvalid(field.formControlName)"
     [formGroup]="group">
    <p class="form-label" *ngIf="field.label">{{field.label | translate }}</p>
    <ng-select [items]="field.source"
               [placeholder]="field.placeholder | translate"
               [formControlName]="field.formControlName"
               [loading]="!!field.loading"
               [searchable]="true"
               [readonly]="!!field.readonly || !!field.disabled"
               [class.readonly]="field.readonly"
               [clearable]="!field.notClearable"
               (ngModelChange)="field.onChange()"
               [notFoundText]="'SHARED.NOT_FOUND_MSG' | translate"
               [loadingText]="'SHARED.LOADING_TEXT' | translate"
               [keyDownFn]="keyPress"
               bindLabel="name"
               bindValue="id"
               groupBy="source">
        <ng-template ng-optgroup-tmp let-item="item">
            {{ item.name | translate }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            {{ item.name | translate:item.args }}
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
            {{ item.name | translate:item.args }}
        </ng-template>
    </ng-select>
    <app-print-field-errors *ngIf="field.printErrors && isTouchedAndInvalid(field.formControlName)"
                            [field]="field"
                            [errors]="group.get(field.formControlName).errors">
    </app-print-field-errors>
</div>