export enum AUTH {
    REGISTER = '/auth-users/register/',
    LOGIN = '/auth-users/login/',
    LOGIN_WITH_LINK = '/auth-users/login-with-token/',
    REFRESH_TOKEN = '/auth-users/refresh-token/',
    FORGOT_PASSWORD = '/auth-users/forgot-password/',
    TOKEN_VERIFICATION = '/auth-users/token_verification/',
    RESET_PASSWORD_URL = '/auth-users/reset-password/{resetKey}/'
}

export enum CAR {
    LIST = '/car/',
    SIMILAR_LIST = '/related-car/',
    CAR_SERIES = '/car-series/',
    BRAND = '/brand/',
    MODEL = '/model/',
    GENERATION = '/car-generation/',
    COUNTRIES = '/country/',
    REGIONS = '/{country_id}/regions/',
    COLOR_URL = '/color/',
    CONTACT_US = '/core/contact-us/'
}

export enum ACCOUNT {
    USERS = '/users/',
    MY_CARS = '/user_car/',
    FAVOURITES = '/favorites/',
    FAVOURITES_ADD = '/favorites/add_favorite/',
    MANAGE_ACCOUNT_INFO = '/users/{id}/',
    CHANGE_PASSWORD_URL = '/auth-users/change-password/'
}

export enum ADVERT {
    ADD_CAR = '/car/car_create/',
    UPDATE_CAR = '/car/{id}/car_update/',
    CAR_DELETE = '/car/{id}/car_delete/',
    ADD_CAR_IMAGES = '/images/',
    DELETE_CAR_IMAGES = '/images/{id}/',
    ORDER_IMAGES = '/order_advert_images/{id}/',
}

export enum ADVERT_PAYMENT {
    PAY = '/payment/pay/'
}

export enum CAR_SCRAPER {
    ADD_CAR = '/car-scraper/',
    RESULT = '/car-scraper-info/'
}

export enum OFFICIALS {
    REGISTER = '/officials/register/',
    OFFICIAL = '/officials/',
    OFFICIAL_CARS = '/car/',
    UPDATE_OFFICIAL = '/officials/{id}/official_update/',
    UPDATE_IMAGE_OFFICIAL = '/officials/{id}/official_image_update/'
}
