<section class="auth-pages contact-us">
  <div class="container flex justify-center align-center">
    <div class="card">

      <div class="card-header">
        <h1 class="color-black title">
          {{"CONTACT_US.HEADER" | translate}}
        </h1>
        <p class="fs-sm mt-4 color-black info-text info-text-top">
          {{"CONTACT_US.ENTER_YOUR_COMMENT" | translate}}
        </p>
      </div>

      <form [formGroup]="form" (submit)="submit()">
        <ul class="mt-3 form-list">
          <li>
            <div class="text-field dark" [class.invalid]="isTouchedAndInvalid('name')">
              <p class="form-label">{{ 'USER.FIRST_NAME' | translate }}</p>
              <input name="name" formControlName="name" type="text">
              <ng-container *ngIf="isTouchedAndInvalid('name')">
                <ng-container *ngFor="let item of form.controls.name.errors | keyvalue">
                  <p class="error-message">{{ (validationMessages[item.key] || item.value) |translate }}</p>
                </ng-container>
              </ng-container>
            </div>
          </li>
          <li>
            <div class="text-field dark" [class.invalid]="isTouchedAndInvalid('email')">
              <p class="form-label">{{ 'USER.EMAIL_ADDRESS' | translate }}</p>
              <input name="email" formControlName="email" type="email">
              <ng-container *ngIf="isTouchedAndInvalid('email')">
                <ng-container *ngFor="let item of form.controls.email.errors | keyvalue">
                  <p class="error-message">{{ (validationMessages[item.key] || item.value) |translate }}</p>
                </ng-container>
              </ng-container>
            </div>
          </li>
          <li>
            <div class="text-field dark" [class.invalid]="isTouchedAndInvalid('message')">
              <p class="form-label">{{ 'CONTACT_US.COMMENT_SUGGESTION_FEEDBACK' | translate }}</p>
              <textarea name="message" formControlName="message"></textarea>
              <ng-container *ngIf="isTouchedAndInvalid('message')">
                <ng-container *ngFor="let item of form.controls.message.errors | keyvalue">
                  <p class="error-message">{{ (validationMessages[item.key] || item.value) |translate }}</p>
                </ng-container>
              </ng-container>
            </div>
          </li>
        </ul>

        <div class="text-center mt-9 mt-md-5 auth-actions">
          <button class="btn filled primary shadow" [class.disabled]="form.invalid || loading">
            {{'CONTACT_US.SUBMIT_NOW' | translate}}
          </button>
        </div>

        <p class="fs-sm color-black info-text info-text-bottom text-center mt-5 mt-xs-4">
          {{'CONTACT_US.FOOTER_TEXT_PART_1' | translate}}
          <a href="mailto:info@gear.am" class="color-primary hover-opacity">info@gear.am</a>
          {{'CONTACT_US.FOOTER_TEXT_PART_2' | translate}}
        </p>

      </form>
    </div>
  </div>
</section>
