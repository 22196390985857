import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DynamicTaskDirective } from './directives/dynamic-task.directive';
import { InProgressTaskComponent } from './components/in-progress-task/in-progress-task.component';
import { DynamicTasksComponent } from './dynamic-tasks.component';
import { FailedTaskComponent } from './components/failed-task/failed-task.component';
import { CompletedTaskComponent } from './components/completed-task/completed-task.component';


@NgModule({
  declarations: [
    DynamicTaskDirective,
    InProgressTaskComponent,
    DynamicTasksComponent,
    FailedTaskComponent,
    CompletedTaskComponent
  ],
  exports: [
    DynamicTasksComponent
  ],
    imports: [
        CommonModule,
        RouterModule
    ]
})
export class DynamicTaskModule { }
