import { Component } from '@angular/core';
import { FULL_ROUTES } from '../../routes';
import { AppConstants } from '@shared/constants/app-constants';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html'
})
export class PrivacyPolicyComponent {

    private readonly fullRoutes = FULL_ROUTES;

    rules = [
        {
            descriptions: [
                'PRIVACY.PRIVACY_2', 'PRIVACY.PRIVACY_3',
                'PRIVACY.PRIVACY_4', 'PRIVACY.PRIVACY_5'
            ]
        },
        {
            title: 'PRIVACY.PRIVACY_6',
            descriptions: [
                'PRIVACY.PRIVACY_7', 'PRIVACY.PRIVACY_8',
                {
                    text: 'PRIVACY.PRIVACY_9',
                    args: {href: AppConstants.GOOGLE_PLAY_PRIVACY_URL}
                }
            ],
        },
        {
            title: 'PRIVACY.PRIVACY_10',
            descriptions: ['PRIVACY.PRIVACY_11']
        },
        {
            title: 'PRIVACY.PRIVACY_12',
            descriptions: ['PRIVACY.PRIVACY_13', 'PRIVACY.PRIVACY_14']
        },
        {
            title: 'PRIVACY.PRIVACY_15',
            descriptions: ['PRIVACY.PRIVACY_16', 'PRIVACY.PRIVACY_17', 'PRIVACY.PRIVACY_18']
        },
        {
            title: 'PRIVACY.PRIVACY_19',
            descriptions: ['PRIVACY.PRIVACY_20']
        },
        {
            title: 'PRIVACY.PRIVACY_21',
            descriptions: ['PRIVACY.PRIVACY_22']
        },
        {
            title: 'PRIVACY.PRIVACY_23',
            descriptions: ['PRIVACY.PRIVACY_24']
        },
        {
            title: 'PRIVACY.PRIVACY_25',
            descriptions: ['PRIVACY.PRIVACY_26']
        },
        {
            title: 'PRIVACY.PRIVACY_27',
            descriptions: [{
                text: 'PRIVACY.PRIVACY_28',
                args: {href: `/${this.fullRoutes.CONTACT_US}`}
            }]
        }
    ];
}
