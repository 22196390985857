<form [formGroup]="vehicleType.form">
    <div class="cols cols-xl-8 cols-lg-5  cols-xxs-3 cols-mb-xs vehicle-types">
        <div *ngFor="let item of vehicleType.types" class="col type">
            <label>
                <input type="radio" formControlName="car_type" [value]="item.id">
                <img [src]="item.image" [alt]="item.name">
                <span class="name">{{item.name}}</span>
                <span class="layer"></span>
            </label>
        </div>
    </div>
</form>