<form class="advanced-search">
    <app-dynamic-fields
            class="w-100 search-space-y"
            [group]="advancedSearch.form"
            [fields]="advancedSearch.items">
    </app-dynamic-fields>

    <ul class="bordered-list mt-5">
        <li *ngFor="let group of advancedSearch.groupedItems">
            <div class="search-section search-space-y">
                <h3 class="color-secondary fs-lg mb-5">{{group.title | translate }}</h3>
                <app-dynamic-fields
                        class="w-100"
                        [group]="advancedSearch.form"
                        [fields]="group.fields">
                </app-dynamic-fields>
            </div>
        </li>
    </ul>
</form>