import { Component, Input, OnInit } from '@angular/core';
import { DynamicField } from '../../classes/DynamicField';
import { ValidationErrors } from '@angular/forms';
import { ValidationMessages } from '@shared/constants/validation/validation.constant';

@Component({
  selector: 'app-print-field-errors',
  templateUrl: './print-field-errors.component.html'
})
export class PrintFieldErrorsComponent implements OnInit {

  @Input() errors: ValidationErrors | null;
  @Input() field: DynamicField;

  constructor() { }

  ngOnInit(): void {
  }

  printError(item) {
    if (this.field.validationMessages && this.field.validationMessages.hasOwnProperty(item.key)) {
      return this.field.validationMessages[item.key];
    } else if (ValidationMessages[item.key]) {
      return ValidationMessages[item.key];
    } else if (typeof item.value === 'string') {
      return item.value;
    }
  }
}
