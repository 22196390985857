import { Component, Input } from '@angular/core';
import { CarLocalizeService } from '@shared/providers/car/car-localize.service';
import { CarDescriptionFields } from '@shared/helpers/car/car-description-fields';

@Component({
  selector: 'app-car-description',
  templateUrl: './car-description.component.html'
})
export class CarDescriptionComponent extends CarDescriptionFields {


  @Input() class = 'desktop';
  description: any;

  constructor(
      public readonly carLocalize: CarLocalizeService,
  ) {
    super(carLocalize);
  }

}
