import { RESPONSE_CODE } from '@shared/constants/api-response-codes';
import { EMPTY, of } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '@shared/providers/auth/auth.service';
import { SnackbarService } from '@shared/snackbar/snackbar.service';

export abstract class ResolveService {

    protected constructor(
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly snackbarService: SnackbarService
    ) {}

    protected errorHandler(error, redirectUrl = '') {
        if (error.status === RESPONSE_CODE.NOT_FOUND || error.status === RESPONSE_CODE.SERVER_ERROR) {
            if (typeof redirectUrl === 'string') {
                this.router.navigate([`/${redirectUrl}`]);
                this.snackbarService.handleErrorsAndOpen('SHARED.NOT_FOUND_MSG', error.error.errors);
            }
        }
        return EMPTY;
    }


    protected optionalData() {
        return of(null);
    }
}
