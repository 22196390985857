import { Component } from '@angular/core';
import { CarLocalizeService } from '@shared/providers/car/car-localize.service';
import { CarAdditionalFields } from '@shared/helpers/car/car-additional-fields';

@Component({
  selector: 'app-car-additional-information',
  templateUrl: './car-additional-information.component.html'
})
export class CarAdditionalInformationComponent extends CarAdditionalFields {

  public expandedView = false;
  constructor(
      public readonly carLocalize: CarLocalizeService
  ) {
    super(carLocalize);
  }
}
