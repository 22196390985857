// ng
import { Component } from '@angular/core';

// routes
import { FULL_ROUTES } from '../../routes';
import { footerLinksConfig, footerSocialLinksConfig } from './configs/links.config';

// interfaces
import { IFooterLinksConfig } from './interfaces/links-config.interface';
import { IFooterSocialLinksConfig } from './interfaces/social-links-config.interface';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {

  public readonly fullRoutes = FULL_ROUTES;
  public readonly linksConfig: IFooterLinksConfig[] = footerLinksConfig;
  public readonly socialLinksConfig: IFooterSocialLinksConfig[] = footerSocialLinksConfig;
  public currentYear = new Date().getFullYear();

}
