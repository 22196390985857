// ng
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

// routes
import { FULL_ROUTES } from '../../routes';
import { headerLinksConfig, headerOptionalConfig } from './configs/links.config';
import { LayoutRouterDataModel} from '../models/layout-router-data.model';
import { DOCUMENT } from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

// interfaces
import { IHeaderLinksConfig } from './interfaces/links-config.interface';
import { CarSearchConstant } from '@shared/constants/car/car-search.constant';
// providers
import { AuthService } from '@shared/providers/auth/auth.service';
import { AppLanguageService } from '@shared/providers/language/app-language.service';
import { SeoService } from '@shared/providers/seo/seo.service';
import { Subscription } from 'rxjs';
import { User } from '@shared/interfaces/user.interface';
import { AccountService } from '@shared/providers/account/account.service';
import { ifInHomepage } from '@shared/utils/route.utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {

  private $subscriptions: Subscription = new Subscription();
  public readonly fullRoutes = FULL_ROUTES;
  public readonly linksConfig: IHeaderLinksConfig[] = headerLinksConfig;
  public readonly optionalLinksConfig: IHeaderLinksConfig[] = headerOptionalConfig;
  public readonly defaultPageParams = {
    [CarSearchConstant.PAGE.KEY]: CarSearchConstant.PAGE.VALUE,
    ...CarSearchConstant.DEFAULT_SORT_PARAM
  };
  public currentLang: string = AppLanguageService.API_LANGUAGE;
  public languages = AppLanguageService.langList;
  public routerData: LayoutRouterDataModel = {headerClass: ''};
  public showMenu = false;
  public userDropdownOpened = false;
  public langDropdownOpened = false;
  addCarModalOpened = true;

  constructor(
      private readonly activatedRoute: ActivatedRoute,
      private readonly router: Router,
      public readonly authService: AuthService,
      public readonly appLanguageService: AppLanguageService,
      private readonly seoService: SeoService,
      public accountService: AccountService,
      public ngxSmartModalService: NgxSmartModalService,
      @Inject(DOCUMENT) document
  ) { }

  ngOnInit() {
    this.subscribeToRouterEvents();
    if (this.authService.isLoggedIn) {
      this.getAccountInfo();
    }
  }

  private getAccountInfo() {
    const id = this.authService.userId;
    this.$subscriptions.add(
        this.accountService.getAccountInfo<User>(id).subscribe(res => {
          this.accountService.setAvatar(res);
        })
    );
  }

  private subscribeToRouterEvents(): void {
    this.$subscriptions.add(
        this.seoService.getRouteData().subscribe((data: LayoutRouterDataModel) => {
          this.routerData = data;
          this.resetActions();
        })
    );
  }

  toggleUserDropdown() {
    this.userDropdownOpened = !this.userDropdownOpened;
    this.langDropdownOpened = false;
  }

  toggleLangDropdown() {
    this.langDropdownOpened = !this.langDropdownOpened;
    this.userDropdownOpened = false;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 45) {
      const element = document.getElementById('navBar');
      element.classList.add('sticky');
    } else {
      const element = document.getElementById('navBar');
      element.classList.remove('sticky');
    }
  }

  public onLangChange(lang: any): void {
    this.langDropdownOpened = false;
    this.appLanguageService.defineLanguage(lang.key);
    this.currentLang = lang.value;
    if (ifInHomepage()) {
      window.location.href = `/${lang.key}`;
    } else {
      window.location.reload();
    }
  }

  ifNavElementActive(item: IHeaderLinksConfig) {
    return item.routerLinkActiveOptions || (item.children && item.children.some(el => el.routerLinkActiveOptions));
  }

  logout() {
    this.authService.logOut(true);
  }

  resetActions() {
    this.userDropdownOpened = false;
    this.langDropdownOpened = false;
    this.showMenu = false;
  }

  ngOnDestroy() {
    this.$subscriptions.unsubscribe();
  }

}
