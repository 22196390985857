import { Component } from '@angular/core';

import { FULL_ROUTES } from '../routes';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html'
})
export class AboutUsComponent {

  public readonly fullRoutes = FULL_ROUTES;

  advantages = [
    {
      title: 'ABOUT.PREV_1_HEAD',
      description: 'ABOUT.PREV_1_DESC'
    },
    {
      title: 'ABOUT.PREV_2_HEAD',
      description: 'ABOUT.PREV_2_DESC'
    },
    {
      title: 'ABOUT.PREV_3_HEAD',
      description: 'ABOUT.PREV_3_DESC'
    },
    {
      title: 'ABOUT.PREV_4_HEAD',
      description: 'ABOUT.PREV_4_DESC'
    }
  ];

  why = ['ABOUT.WHY_1', 'ABOUT.WHY_2', 'ABOUT.WHY_3', 'ABOUT.WHY_4'];

  steps = [
    {
      number: 1,
      title: 'ABOUT.STEP_1_H2',
      description: 'ABOUT.STEP_1_DESC'
    },
    {
      number: 2,
      title: 'ABOUT.STEP_2_H2',
      description: 'ABOUT.STEP_2_DESC'
    },
    {
      number: 3,
      title: 'ABOUT.STEP_3_H2',
      description: 'ABOUT.STEP_3_DESC'
    }
  ];

  constructor() { }
}
