import { Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicField } from './DynamicField';

export abstract class DynamicComponent {
    @Input() group: FormGroup;
    @Input() field: DynamicField;

    public isTouchedAndInvalid(key) {
        return this.isInvalid(key) &&
            (this.group.controls[key].touched || this.group.controls[key].dirty);
    }

    public isInvalid(key) {
        return !!this.group.controls[key].errors;
    }

    getFormControlName(i) {
        return typeof this.field.formControlName === 'string' ? this.field.formControlName : this.field.formControlName[i];
    }

    public getUniqueClass(index = 0) {
        return `${this.field.type}-${this.getFormControlName(index)}`;
    }

    get fieldValue() {
        return this.group.value[this.field.toControlNameString];
    }
}
