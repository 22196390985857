import { CarSearchConstant } from '@shared/constants/car/car-search.constant';
import { getItem } from '@helpers/local-storage';
import { Params } from '@angular/router';

export class SearchForm {

    public static getIndexes(items) {
        const INDEXES = {};
        for (let i = 0; i < items.length; i++) {
            const formControlName = items[i].formControlName;
            if (typeof formControlName === 'string') {
                INDEXES[formControlName.toUpperCase()] = i;
                INDEXES[formControlName] = i;
            } else {
                for (const name of formControlName) {
                    INDEXES[name.toUpperCase()] = i;
                    INDEXES[name] = i;
                }
            }
        }
        return INDEXES;
    }

    public static getQueryParams(params: Params = {}, setDefConfigs = true) {
        Object.keys(params).forEach(paramsKey => {
            if (params[paramsKey] == null) {
                delete params[paramsKey];
            }
            if (typeof params[paramsKey] === 'boolean') {
                if (params[paramsKey]) {
                    params[paramsKey] = 1;
                } else if (paramsKey === 'is_crashed') {
                    params[paramsKey] = 0;
                } else {
                    delete params[paramsKey];
                }
            }
        });
        if (setDefConfigs) {
            params[CarSearchConstant.LIMIT.KEY] = getItem(CarSearchConstant.LIMIT.KEY, CarSearchConstant.LIMIT.VALUE);
            params[CarSearchConstant.OFFSET.KEY] = CarSearchConstant.OFFSET.VALUE;
            params[CarSearchConstant.SORT_BY.KEY] = CarSearchConstant.SORT_BY.VALUE;
        }
        return params;
    }

    public static filterSearchFormValue(value): Params {
        Object.keys(value).forEach(key => {
           if (value[key] === '' || value[key] === null || value[key] === undefined) {
               delete value[key];
           }
        });
        return value;
    }
}
