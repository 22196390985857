export enum AppConstants {
    LANGUAGE_KEY = 'lang',
    GOOGLE_PLAY_PRIVACY_URL = 'https://policies.google.com/privacy'
}

export enum LanguagesList {
    am = 'ՀԱՅ',
    ru = 'РУС',
    en = 'ENG'
}

export enum LocalStorageConstants {
    ACCESS_TOKEN = 'token',
    REFRESH_TOKEN = 'refresh_token',
    USER_ID = 'id',
    OFFICIAL_ID = 'official_id'
}

export const addToTopConstants = {
    price: 3000,
    currency: 'AMD',
    method: 'idram'
}