<!-- Car info start -->
<section class="car-info">
  <div class="container">

    <div class="car-header">
      <span class="year" *ngIf="car?.production_date">{{car.production_date}}</span>
      <div class="car-title">
        <h1 class="h2">{{car?.car_model?.brand?.name}} {{car?.car_model?.name}} {{car?.generation?.name}}</h1>
       <div class="dropdown"
            (mouseleave)="carDropdownOpened = false">
         <span class="h2 color-primary pointer"
               (mouseover)="carDropdownOpened = true">{{car?.advert?.price}} {{carLocalize.get(car?.advert?.currency, 'currency')}}</span>
          <ul *ngIf="carDropdownOpened && car?.price?.length" class="dropdown-menu dropdown-absolute margin-0 top-unset z-10">
            <li *ngFor="let item of pricesList">
              <a>{{item.price}} {{item.currency|translate}}</a>
            </li>
          </ul>
        </div>
      </div>
      <ul class="list-with-icons horizontal sm">
        <li *ngIf="car.advert?.region?.name || car.advert?.country?.name">
          <i class="icon-location"></i>
          <span>{{car.advert?.region?.name ? car.advert.region.name + ', ' : '' }} {{car.advert?.country?.name}}</span>
        </li>
        <li>
          <i class="icon-eye"></i>
          <span>{{car?.seen_count || 0}} {{ 'CAR_DETAIL.SEEN_COUNT' | translate }}</span>
        </li>
      </ul>
    </div>

    <div class="car-info-wrap">

      <div class="car-info-left">

        <app-car-image-slider [images]="car?.images"></app-car-image-slider>

        <app-car-description [car]="car"></app-car-description>

      </div>

      <app-detail-right-section  class="car-info-right" [car]="car"></app-detail-right-section>

      <app-car-description [car]="car" [class]="'mobile'"></app-car-description>

    </div>

  </div>
</section>
<!-- Car info end -->

<!-- Additional info start -->
<app-car-additional-information *ngIf="car.information" [information]="car.information"></app-car-additional-information>
<!-- Additional info end -->

<!-- Similar cars start -->
<section class="primary-additions overflow-hidden">
  <div class="container">
    <h2 class="color-secondary h4 mb-8 title">{{"OTHER.SIMILAR_CARS" | translate}}</h2>
    <app-top-vehicles [type]="carouselConfigs.type" [query]="carouselConfigs.query"></app-top-vehicles>
  </div>
</section>
<!-- Similar cars end -->

