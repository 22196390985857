import { ValidationErrors, FormControl } from '@angular/forms';

export function passwordValidator(control: FormControl): ValidationErrors | null {
    // numbers,symbols, uppercase validator,min 6 symbols
    // const passwordPattern: RegExp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})');
    const passwordPattern = /^[a-zA-Z0-9!#$%@&'*+-./=?^_`{|}~]{6,}$/g;
    const val = control.value && control.value.trim();
    if (!val || val.length < 6) {
        return { invalidPassword: 'VALIDATION.PASSWORD_LENGTH' };
    }
    return  val.length <= 30 && passwordPattern.test(val) ? null : { invalidPassword: 'VALIDATION.PASSWORD' };
}
