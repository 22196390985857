import { FormGroup } from '@angular/forms';
import { DynamicField } from '../../../modules/dynamic-fields/classes/DynamicField';

export abstract class Form {

    validationMessages?: {[key: string]: string};
    // fields
    items: DynamicField[] = [];
    // form
    form: FormGroup;

    protected constructor() {}

    public isTouchedAndInvalid(key) {
        return this.isInvalid(key) && this.isTouched(key)
    }

    public isInvalid(key) {
        return !!this.form.controls[key].errors;
    }

    public isTouched(key) {
        return (this.form.controls[key].touched || this.form.controls[key].dirty);
    }

    public isInvalidRepeatPassword(key = 'repeat_password') {
        return this.isTouched(key) && !!(this.form.errors && this.form.errors.invalidRepeatPassword);
    }

    public validateAll(): void {
        const controls = Object.keys(this.form.controls).filter(key => this.form.controls[key].invalid);
        for (const key of controls) {
            this.form.controls[key].markAsDirty();
            this.form.controls[key].markAsTouched();
        }
    }

    public resetForm(value = {}) {
        this.form.reset(value);
    }
}
