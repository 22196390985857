import { FormGroup } from '@angular/forms';
import { DynamicField } from '../../../modules/dynamic-fields/classes/DynamicField';
import { SearchForm } from '@shared/helpers/search/search-form';
import { Brand } from '@shared/interfaces/car/brand.interface';
import { Model } from '@shared/interfaces/car/model.interface';
import { CarGeneration } from '@shared/interfaces/car/car-generation.interface';
import { CarService } from '@shared/providers/car/car.service';
import { DynamicFormCreateService } from '../../../modules/dynamic-fields/providers/dynamic-form-create.service';
import { minMaxValidator } from '@shared/validators/min-max.validator';
import { CarFieldsValidation } from '@shared/constants/validation/validation.constant';
import { onlyNumbersWithMinMax } from '@shared/validators/number.validator';
import { numberMask } from '@shared/utils/mask';
import { CarMainForm } from '@shared/helpers/car-main-form';

export abstract class FastSearch extends CarMainForm {

    protected constructor(
        protected dynamicForm: DynamicFormCreateService,
        protected carService: CarService
    ) {
        super();
        this.formConfigs();
    }

    public static INDEXES: any;

    // fields
    items: DynamicField[] = [];

    // populars
    populars: Brand[] = [];

    initFunctions(form: FormGroup = null): void {
        if (form) {
            this.form = form;
        }
        if (this.form.contains('brand')) {
            this.initCarBrands();
        }
    }

    formConfigs(): void {
        this.items = [
            new DynamicField({
                type: 'select',
                label: 'ADD_CAR.MAKE',
                placeholder: 'CAR.ANY',
                wrapperClass: 'dynamic-input width-3',
                formControlName: 'brand',
                loading: true,
                onChange: this.onBrandSelect
            }),
            new DynamicField({
                type: 'select',
                label: 'ADD_CAR.MODEL',
                placeholder: 'CAR.ANY',
                wrapperClass: 'dynamic-input width-3',
                formControlName: 'model',
                onChange: this.onModelSelect
            }),
            new DynamicField({
                type: 'select',
                label: 'ADD_CAR.STYLE',
                placeholder: 'CAR.ANY',
                wrapperClass: 'dynamic-input width-3',
                formControlName: 'generation'
            }),
            ...this.similarGroup([{
                    label: 'ADD_CAR.YEAR',
                    placeholder: 'CAR.FROM',
                    formControlName: 'production_date_range_min',
                }, {
                    placeholder: 'CAR.TO',
                    formControlName: 'production_date_range_max',
                }], {
                    type: 'input',
                    updateType: 'blur',
                    wrapperClass: 'dynamic-input width-6',
                    mask: numberMask(CarFieldsValidation.MAX_PROD_DATE, true),
                    validation: [
                        onlyNumbersWithMinMax(
                            CarFieldsValidation.MIN_PROD_DATE,
                            CarFieldsValidation.MAX_PROD_DATE
                        ),
                        minMaxValidator('production_date_range_min', 'production_date_range_max')
                    ]
            }),
            ...this.similarGroup([{
                label: 'ADD_CAR.PRICE',
                placeholder: 'CAR.FROM',
                formControlName: 'price_range_min',
            }, {
                placeholder: 'CAR.TO',
                formControlName: 'price_range_max',
            }], {
                type: 'input',
                updateType: 'blur',
                wrapperClass: 'dynamic-input width-6',
                mask: numberMask(CarFieldsValidation.MAX_PRICE),
                validation: [
                    onlyNumbersWithMinMax(
                        CarFieldsValidation.MIN_PRICE,
                        CarFieldsValidation.MAX_PRICE
                    ),
                    minMaxValidator('price_range_min', 'price_range_max')
                ],
            }),
            ...this.similarGroup([{
                label: 'ADD_CAR.RUNS',
                placeholder: 'CAR.FROM',
                formControlName: 'kilometer_range_min',
            }, {
                placeholder: 'CAR.TO',
                formControlName: 'kilometer_range_max',
            }], {
                type: 'input',
                updateType: 'blur',
                wrapperClass: 'dynamic-input width-6',
                mask: numberMask(CarFieldsValidation.RANGE_MAX),
                validation: [
                    onlyNumbersWithMinMax(
                        CarFieldsValidation.RANGE_MIN,
                        CarFieldsValidation.RANGE_MAX
                    ),
                    minMaxValidator('kilometer_range_min', 'kilometer_range_max')
                ],
            })
        ];

        // init items INDEXES
        FastSearch.INDEXES = SearchForm.getIndexes(this.items);

        // init form
        this.form = this.dynamicForm.createForm(
            this.items
        );
    }

    initCarBrands() {
        this.carService.getBrands<Brand>().subscribe(res => {
            this.populars = res.results.filter(brand => brand.popular);
            this.items[FastSearch.INDEXES.BRAND].isLoading = false;
            if (this.populars.length) {
                const all = (res.results as any).diff(this.populars);
                this.items[FastSearch.INDEXES.BRAND].source = [
                    {
                        name: 'SEARCH.POPULAR',
                        source: this.populars
                    },
                    {
                        name: 'SEARCH.ALL',
                        source: all
                    }
                ];
            } else {
                this.items[FastSearch.INDEXES.BRAND].source = res.results;
            }
        });
    }

    initCarModels = (id: Brand['id']) => {
        this.items[FastSearch.INDEXES.MODEL].isLoading = true;
        this.carService.getModel<Model>(id).subscribe(res => {
            this.items[FastSearch.INDEXES.MODEL].source = res.results;
            this.items[FastSearch.INDEXES.MODEL].isLoading = false;
            this.items[FastSearch.INDEXES.MODEL].disabled = !res.results.length;
        });
    }

    initCarGenerations = (id: Model['id']) => {
        this.items[FastSearch.INDEXES.GENERATION].isLoading = true;
        this.carService.getGenerations<CarGeneration>(id).subscribe(res => {
            this.items[FastSearch.INDEXES.GENERATION].source = res.results;
            this.items[FastSearch.INDEXES.GENERATION].isLoading = false;
            this.items[FastSearch.INDEXES.GENERATION].disabled = !res.results.length;
        });
    }

    selectCarBrand(id: Brand['id']) {
        const brand = this.form.get('brand');
        if (brand.value !== id) {
            brand.setValue(id);
        }
    }

    onBrandSelect = () => {
        const brand = this.form.get('brand');
        const model = this.form.get('model');
        if (model) {
            if (model.value || !brand.value) {
                this.form.patchValue({
                    generation: null,
                    model: null
                });
                this.items[FastSearch.INDEXES.MODEL].source = [];
                this.items[FastSearch.INDEXES.GENERATION].source = [];
            }
            if (brand.value) {
                this.initCarModels(brand.value);
            }
        }
    }

    onModelSelect = () => {
        const model = this.form.get('model');
        const generation = this.form.get('generation');
        if (!model.value || generation.value) {
            generation.setValue(null);
            this.items[FastSearch.INDEXES.GENERATION].source = [];
        }
        if (model.value) {
            this.initCarGenerations(model.value);
        }
    }

}
