import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

// Environment
import { environment } from '@environments/environment';

// Helpers
import { getItem } from '@helpers/local-storage';
import { AppConstants } from '@shared/constants/app-constants';


export abstract class BaseAPIService {

  protected apiHost = environment.apiHost;

  protected constructor(
      protected http: HttpClient
  ) { }

  protected getList<T>(url: string, params: object): Observable<T> {
    return this.http.get<T>(`${this.apiHost}${url}`, {
      params: {...params}
    });
  }

  protected getById<T>(url: string, id: string | number): Observable<T> {
    return this.http.get<T>(`${this.apiHost}${url}${id}/`);
  }

  protected post<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(`${this.apiHost}${url}`, data);
  }

  protected put<T>(url: string, data: T): Observable<T> {
    return this.http.put<T>(`${this.apiHost}${url}`, data);
  }

  protected patch<T>(url: string, data: T): Observable<T> {
    return this.http.patch<T>(`${this.apiHost}${url}`, data);
  }

  protected delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(`${this.apiHost}${url}`);
  }

  protected appendHeader(token: string): HttpHeaders {
    const lang = getItem(AppConstants.LANGUAGE_KEY, environment.defaultLang);
    return  new HttpHeaders({
      'Accept-Language': lang,
      ...token && { Authorization: `Token ${token}` }
    });
  }
}

