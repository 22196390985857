import { Validators } from '@angular/forms';
import { DynamicField } from '../../../modules/dynamic-fields/classes/DynamicField';
import { DynamicFormCreateService } from '../../../modules/dynamic-fields/providers/dynamic-form-create.service';
import { phoneNumberValidator } from '@shared/validators/phone-number.validator';
import { emailValidator } from '@shared/validators/email.validator';
import { CarAdvancedSearchService } from '@shared/providers/search/car-advanced-search.service';
import { CarFastSearchService } from '@shared/providers/search/car-fast-search.service';
import { CarVehicleTypeService } from '@shared/providers/search/car-vehicle-type.service';
import { CarLocalizeService } from '@shared/providers/car/car-localize.service';
import { CarMainForm } from '@shared/helpers/car-main-form';
import { FieldGroup } from '../../../modules/dynamic-fields/interfaces/field-group.interface';
import { CarFieldsValidation } from '@shared/constants/validation/validation.constant';
import { Car } from '@shared/interfaces/car/car.interface';
import { DynamicImageProperty } from '../../../modules/dynamic-fields/classes/DynamicImageProperty';
import { CarFieldConstant } from '@shared/constants/car/car-field.constant';
import { onlyNumbersWithMinMax } from '@shared/validators/number.validator';
import { numberMask } from '@shared/utils/mask';
import { Official } from '@shared/interfaces/officials/official.interface';
import { FastSearch } from '@shared/helpers/search/fast-search-form';

export abstract class CarAddEdit extends CarMainForm {

    // fields
    public requiredItems: FieldGroup[] = [];
    public additionalItems: FieldGroup[] = [];
    private imageFields: {[key: string]: DynamicField};

    private officialControl = new DynamicField({
        type: 'input',
        inputType: 'hidden',
        formControlName: 'officials'
    });


    protected constructor(
        protected dynamicForm: DynamicFormCreateService,
        protected advancedSearch: CarAdvancedSearchService,
        protected fastSearch: CarFastSearchService,
        protected vehicleType: CarVehicleTypeService,
        protected carLocalize: CarLocalizeService
    ) {
        super();
        this.formConfigs();
    }

    formConfigs(): void {
        this.imageFields = {
            [CarFieldConstant.CAR_IMAGES_FIELD]: new DynamicField({
                type: 'imageUpload',
                wrapperClass: 'dynamic-input width-1',
                formControlName: CarFieldConstant.CAR_IMAGES_FIELD,
            }),
        };
        this.requiredItems = [
            {
                title: 'ADD_CAR.DESCRIPTION',
                fields: [
                    ...this.includeFields(
                        this.fastSearch.items,
                        {
                            brand: {
                                validation: [Validators.required],
                                printErrors: true,
                            },
                            model: {
                                validation: [Validators.required],
                                printErrors: true,
                            },
                            generation: {
                                printErrors: true,
                            },
                        },
                    ),
                    new DynamicField({
                        type: 'input',
                        label: 'ADD_CAR.YEAR',
                        placeholder: 'ADD_CAR.YEAR',
                        wrapperClass: 'dynamic-input width-3',
                        formControlName: 'production_date',
                        printErrors: true,
                        mask: numberMask(CarFieldsValidation.MAX_PROD_DATE, true),
                        validation: [
                            Validators.required,
                            onlyNumbersWithMinMax(
                                CarFieldsValidation.MIN_PROD_DATE,
                                CarFieldsValidation.MAX_PROD_DATE
                            )
                        ]
                    }),
                    new DynamicField({
                        type: 'inputWithSelect',
                        label: 'ADD_CAR.PRICE',
                        placeholder: 'ADD_CAR.PRICE',
                        source: this.carLocalize.toArray('currency'),
                        wrapperClass: 'dynamic-input width-3',
                        formControlName: ['price', 'currency'],
                        printErrors: true,
                        notClearable: true,
                        mask: numberMask(CarFieldsValidation.MAX_PRICE),
                        validation: [
                            Validators.min(CarFieldsValidation.MIN_PRICE),
                            Validators.max(CarFieldsValidation.MAX_PRICE),
                            Validators.required,
                        ]
                    }),
                    new DynamicField({
                        type: 'input',
                        label: 'ADD_CAR.RUNS',
                        placeholder: 'ADD_CAR.RUNS',
                        wrapperClass: 'dynamic-input width-3',
                        formControlName: 'kilometer',
                        printErrors: true,
                        mask: numberMask(CarFieldsValidation.RANGE_MAX),
                        validation: [
                            onlyNumbersWithMinMax(
                                CarFieldsValidation.RANGE_MIN,
                                CarFieldsValidation.RANGE_MAX
                            ),
                            Validators.required,
                        ],
                    }),
                    ...this.includeFields(
                        this.advancedSearch.items,
                        {
                            color: {
                                validation: [Validators.required],
                                printErrors: true
                            },
                            drive_type: {
                                validation: [Validators.required],
                                printErrors: true
                            },
                            transmission: {
                                validation: [Validators.required],
                                printErrors: true
                            },
                        }
                    )
                ],
                class: 'radius-bottom-none'
            },
            {
                title: 'ADD_CAR.UPLOAD_VEHICLE_IMAGES',
                class: 'radius-none border-top-none',
                fields: [
                    this.imageFields.images
                ],
            },
            {
                title: 'ADD_CAR.CONTACT_DETAILS',
                class: 'radius-none border-x-none',
                fields: [
                    new DynamicField({
                        type: 'input',
                        inputType: 'number',
                        label: 'ADD_CAR.MOBILE_NUMBER',
                        placeholder: 'ADD_CAR.MOBILE_NUMBER',
                        wrapperClass: 'dynamic-input width-3',
                        formControlName: 'tel_num',
                        printErrors: true,
                        validation: [
                            Validators.required,
                            phoneNumberValidator
                        ],
                    }),
                    new DynamicField({
                        type: 'input',
                        label: 'ADD_CAR.CONTACT_PERSON',
                        placeholder: 'ADD_CAR.CONTACT_PERSON',
                        wrapperClass: 'dynamic-input width-3',
                        formControlName: 'contact_person',
                        printErrors: true,
                        validation: [
                            Validators.required,
                        ],
                    }),
                    new DynamicField({
                        type: 'input',
                        inputType: 'email',
                        label: 'ADD_CAR.EMAIL_ADDRESS',
                        placeholder: 'ADD_CAR.EMAIL_ADDRESS',
                        wrapperClass: 'dynamic-input width-3',
                        formControlName: 'email',
                        printErrors: true,
                        validation: [
                            Validators.required,
                            emailValidator
                        ],
                    }),
                ],
            }
        ];
        this.additionalItems = [
            {
                title: 'ADD_CAR.ADDITIONAL_INFO',
                fields: [
                    ...this.includeFields(
                        this.advancedSearch.items,
                        'country', 'region', 'usage', 'is_crashed',
                        'fuel_type', 'drive_wheel_type', 'owners_count',
                        new DynamicField({
                            type: 'input',
                            updateType: 'blur',
                            label: 'ADD_CAR.POWER',
                            placeholder: 'ADD_CAR.POWER',
                            wrapperClass: 'dynamic-input width-3',
                            formControlName: 'power',
                            mask: numberMask(CarFieldsValidation.MAX_POWER),
                            validation: [
                                onlyNumbersWithMinMax(
                                    CarFieldsValidation.MIN_POWER,
                                    CarFieldsValidation.MAX_POWER
                                )
                            ]
                        }),
                        new DynamicField({
                            type: 'select',
                            label: 'ADD_CAR.CUBIC_CAPACITY',
                            placeholder: 'ADD_CAR.CUBIC_CAPACITY',
                            wrapperClass: 'dynamic-input width-3',
                            formControlName: 'cubic_capacity',
                            source: this.carLocalize.cubic_capacity
                        }),
                        'custom_clearance'
                    ),
                ]
            },
            ...this.advancedSearch.groupedItems,
            {
                title: '',
                fields: [
                    new DynamicField({
                        type: 'textarea',
                        label: 'ADD_CAR.DESCRIPTION_TEXT',
                        placeholder: 'ADD_CAR.DESCRIPTION_TEXT',
                        wrapperClass: 'dynamic-input width-1',
                        formControlName: 'additional_information',
                        fieldClass: 'h-150px',
                        printErrors: true,
                        validation: [
                            Validators.minLength(CarFieldsValidation.TEXTAREA_MIN_LENGTH),
                            Validators.maxLength(CarFieldsValidation.TEXTAREA_MAX_LENGTH)
                        ],
                    }),
                ]
            }
        ];
        // init form
        this.form = this.dynamicForm.createForm(
            this.joinArrays(
                this.requiredItems.map(item => item.fields),
                this.additionalItems.map(item => item.fields)
            )
        );
        this.advancedSearch.initFunctions(this.form);
        this.fastSearch.initFunctions(this.form);
        this.vehicleType.initFunctions(this.form);
    }

    getImages(key = CarFieldConstant.CAR_IMAGES_FIELD): DynamicImageProperty {
        return this.imageFields[key] && this.imageFields[key].images;
    }

    officialConfig(official: Official, editMode) {
        this.fastSearch.items[FastSearch.INDEXES.BRAND].readonly = true;
        if (!editMode) {
            this.dynamicForm.createControl(this.officialControl, this.form);
            this.form.patchValue({
                officials: official.id,
                brand: official.brand.id
            });
            this.fastSearch.initCarModels(official.brand.id);
        }
    }

    initValues(car: Car) {
        this.initSelects(car);
        this.initRequiredFields(car);
        this.initAdditionalFields(car);
    }

    initSelects(car: Car) {
        const carModel = car.car_model;
        const carBrand = carModel.brand;
        const country = car.advert.country;
        const selects = [
            {
                object: carBrand,
                function: this.fastSearch.initCarModels
            },
            {
                object: carModel,
                function: this.fastSearch.initCarGenerations
            },
            {
                object: country,
                function: this.advancedSearch.initRegions
            }
        ];
        for (const select of selects) {
            const id = select.object && select.object.id;
            if (id) {
                select.function(id);
            }
        }
    }

    initRequiredFields(car: Car) {
        const carModel = car.car_model;
        const carBrand = carModel.brand;
        const carGeneration = car.generation;
        const carAdvert = car.advert;
        // this.imgControl.images = [...this.carRequiredFieldsData.car.images];
        // this.imgControl.files = [...this.carRequiredFieldsData.car.images]
        const patchObject = {
            brand: carBrand.id,
            model: carModel.id,
            generation: carGeneration && carGeneration.id || null,
            production_date: car.production_date,
            currency: carAdvert.currency,
            price: carAdvert.price,
            kilometer: car.kilometer,
            color: car.color.id,
            drive_type: car.drive_type,
            transmission: car.transmission,
            tel_num: (carAdvert.tel_num && carAdvert.tel_num !== 'none') ? carAdvert.tel_num : null,
            contact_person: carAdvert.contact_person,
            email: carAdvert.email,
            images: car.images
        };
        this.form.patchValue(patchObject);
    }

    initAdditionalFields(car: Car) {
        const carAdvert = car.advert;
        const carInformation = car.information;
        const patchObject = {
            car_type: car.car_type && car.car_type.id || null,
            fuel_type: car.fuel_type,
            cubic_capacity: car.cubic_capacity,
            power: car.power,
            drive_wheel_type: car.drive_wheel_type,
            owners_count: car.owners_count,
            purchased_year: car.purchased_year,
            is_crashed: car.is_crashed,
            usage: car.usage,
            country: carAdvert.country && carAdvert.country.id || null,
            region: carAdvert.region && carAdvert.region.id || null,
            custom_clearance: car.custom_clearance,
            guarantee: car.guarantee,
            exchange: carAdvert.exchange,
            additional_information: carAdvert.additional_information,
            ...carInformation,
        };
        this.form.patchValue(patchObject);
    }
}
