// ng
import { ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
// components
import { DynamicInputComponent } from '../components/dynamic-input/dynamic-input.component';
import { DynamicSelectComponent } from '../components/dynamic-select/dynamic-select.component';
import { DynamicColorComponent } from '../components/dynamic-color/dynamic-color.component';
import { DynamicSwitcherComponent } from '../components/dynamic-switcher/dynamic-switcher.component';
import { DynamicInputWithSelectComponent } from '../components/dynamic-input-with-select/dynamic-input-with-select.component';

import { DynamicImageUploadComponent } from '../components/dynamic-image-upload/dynamic-image-upload.component';
import { DynamicTextareaComponent } from '../components/dynamic-textarea/dynamic-textarea.component';
import { DynamicAddressAutocompleteComponent } from '../components/dynamic-address-autocomplete/dynamic-address-autocomplete.component';
// interfaces
import { DynamicField } from '../classes/DynamicField';
// services
import { DynamicFormCreateService } from '../providers/dynamic-form-create.service';


const componentMapper = {
  input: DynamicInputComponent,
  select: DynamicSelectComponent,
  color: DynamicColorComponent,
  switcher: DynamicSwitcherComponent,
  inputWithSelect: DynamicInputWithSelectComponent,
  imageUpload: DynamicImageUploadComponent,
  textarea: DynamicTextareaComponent,
  addressAutocomplete: DynamicAddressAutocompleteComponent
};

@Directive({
  selector: '[appDynamicField]'
})
export class DynamicFieldDirective implements OnInit {

  @Input() group: FormGroup;
  @Input() field: DynamicField;
  componentRef: any;

  constructor(
      private resolver: ComponentFactoryResolver,
      private container: ViewContainerRef,
      private dynamicFormCreateService: DynamicFormCreateService,
  ) { }

  ngOnInit(): void {
    if (this.field.type in componentMapper) {
      const factory = this.resolver.resolveComponentFactory(
          componentMapper[this.field.type]
      );
      this.dynamicFormCreateService.createControl(this.field, this.group);
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.field = this.field;
      this.componentRef.instance.group = this.group;
    }
  }
}
