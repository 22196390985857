import { Component } from '@angular/core';

import { FULL_ROUTES, Social_APP_Links } from '../routes';
import { AuthService } from '@shared/providers/auth/auth.service';
import { SearchForm } from '@shared/helpers/search/search-form';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent {

  public readonly fullRoutes = FULL_ROUTES;
  public readonly socialAppLinks = Social_APP_Links;
  public readonly searchQueryParam = SearchForm.getQueryParams();

  constructor(
      public readonly authService: AuthService,
  ) {}

}
