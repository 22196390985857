import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CarLocalizeConstant } from '@shared/constants/car/car-localize.constant';

@Injectable({
  providedIn: 'root'
})
export class CarLocalizeService {

  // currency translation keys
  public currency = {
    usd: 'CAR.CURRENCY.USD',
    eur: 'CAR.CURRENCY.EUR',
    rub: 'CAR.CURRENCY.RUB',
    amd: 'CAR.CURRENCY.AMD'
  };

  public transmission = {
    automatic: 'CAR.TRANSMISSION.AUTOMATIC',
    robot: 'CAR.TRANSMISSION.ROBOT',
    variator: 'CAR.TRANSMISSION.VARIATOR',
    manual: 'CAR.TRANSMISSION.MANUAL',
  };

  public drive_type = {
    left_hand: 'CAR.DRIVE_TYPE.LEFT_HAND',
    right_hand: 'CAR.DRIVE_TYPE.RIGHT_HAND'
  };


  public usage = {
    under_one_year: 'CAR.USAGE.UNDER_ONE_YEAR',
    one_year: 'CAR.USAGE.ONE_YEAR',
    two_years: 'CAR.USAGE.TWO_YEARS',
    from_three_to_five: 'CAR.USAGE.FROM_THREE_TO_FIVE',
    from_six_to_ten: 'CAR.USAGE.FROM_SIX_TO_TEN',
    ten_and_more: 'CAR.USAGE.TEN_AND_MORE',
    is_new: 'CAR.USAGE.NEW'
  };

  public is_crashed = [
    { name: 'CAR.CRASHED.NO', id: false },
    { name: 'CAR.CRASHED.YES', id: true }
  ];

  public fuel_type = {
    petrol: 'CAR.FUEL_TYPE.PETROL',
    diesel: 'CAR.FUEL_TYPE.DIESEL',
    hybrid: 'CAR.FUEL_TYPE.HYBRID',
    petrol_gas: 'CAR.FUEL_TYPE.PETROL_GAS',
    electric: 'CAR.FUEL_TYPE.ELECTRIC',
  };

  public drive_wheel_type = {
    front_wheel_drive: 'CAR.DRIVE_WHEEL_TYPE.FRONT_WHEEL_DRIVE',
    rear_drive: 'CAR.DRIVE_WHEEL_TYPE.REAR_DRIVE',
    four_wheel_drive: 'CAR.DRIVE_WHEEL_TYPE.FOUR_WHEEL_DRIVE',
  };

  public cubic_capacity: Array<any> = [];

  public posted_time = {
    today: 'CAR.POSTED_TIME.TODAY',
    two_days: 'CAR.POSTED_TIME.TWO_DAYS',
    three_days: 'CAR.POSTED_TIME.THREE_DAYS',
    one_week: 'CAR.POSTED_TIME.ONE_WEEK',
    two_weeks: 'CAR.POSTED_TIME.TWO_WEEK',
    month: 'CAR.POSTED_TIME.MONTH',
  };

  public owners_count = {
    one: '1',
    two: '2',
    three_plus: '3+',
  };

  public interior_type = [
    { name: 'CAR.INTERIOR_TYPE.TEXTILE', id: 'textile' },
    { name: 'CAR.INTERIOR_TYPE.VELOURS', id: 'velours' },
    { name: 'CAR.INTERIOR_TYPE.LEATHER', id: 'leather' },
    { name: 'CAR.INTERIOR_TYPE.COMBINED', id: 'combined' }
  ];

  public interior_flavor = [
    { name: 'CAR.INTERIOR_FLAVOR.WHITE', id: 'white' },
    { name: 'CAR.INTERIOR_FLAVOR.BLACK', id: 'black' },
    { name: 'CAR.INTERIOR_FLAVOR.GREY', id: 'grey' },
    { name: 'CAR.INTERIOR_FLAVOR.BEIGE', id: 'beige' },
    { name: 'CAR.INTERIOR_FLAVOR.RED', id: 'red' },
    { name: 'CAR.INTERIOR_FLAVOR.BROWN', id: 'brown' }
  ];

  public seat_count = [
    { name: 'CAR.SEATS_COUNT.TWO', id: 'two' },
    { name: 'CAR.SEATS_COUNT.FOUR_FIVE', id: 'four_five' },
    { name: 'CAR.SEATS_COUNT.SIX_EIGHT', id: 'six_eight' },
    { name: 'CAR.SEATS_COUNT.NINE_PLUS', id: 'nine_plus' }
  ];

  public driver_seat_regulator = [
    { name: 'CAR.SEAT_REGULATOR.BY_HEIGHT', id: 'by_height' },
    { name: 'CAR.SEAT_REGULATOR.ELECTRO_REGULATOR', id: 'electro_regulator' },
    { name: 'CAR.SEAT_REGULATOR.WITH_MEMORY', id: 'with_memory' },
  ];

  public helm_regulator = [
    { name: 'CAR.HELM_REGULATOR.IN_ONE_PLANE', id: 'in_one_plane' },
    { name: 'CAR.HELM_REGULATOR.IN_TWO_PLANE', id: 'in_two_plane' },
    { name: 'CAR.HELM_REGULATOR.ELECTRO_REGULATOR', id: 'electro_regulator' },
  ];

  public passenger_seat_regulator = [
    { name: 'CAR.SEAT_REGULATOR.BY_HEIGHT', id: 'by_height' },
    { name: 'CAR.SEAT_REGULATOR.ELECTRO_REGULATOR', id: 'electro_regulator' },
    { name: 'CAR.SEAT_REGULATOR.WITH_MEMORY', id: 'with_memory' },
  ];

  public airbag = [
    { name: 'CAR.AIRBAG.DRIVER_SIDE', id: 'driver_side' },
    { name: 'CAR.AIRBAG.DRIVER_AND_PASSENGER', id: 'driver_and_passenger' },
    // {name: 'CAR.AIRBAG.FRONT_AND_LATERIAL', id: 'front_and_laterial'},
  ];

  public climate_control = [
    { name: 'CAR.CLIMAT_CONTROL.CONDITIONER', id: 'conditioner' },
    { name: 'CAR.CLIMAT_CONTROL.ONE_ZONE', id: 'one_zone' },
    { name: 'CAR.CLIMAT_CONTROL.TWO_ZONE', id: 'two_zone' },
    { name: 'CAR.CLIMAT_CONTROL.THREE_ZONE', id: 'three_zone' }
  ];


  public window_regulator = [
    { name: 'CAR.WINDOW_REGULATOR.FRONT', id: 'front' },
    { name: 'CAR.WINDOW_REGULATOR.ALL', id: 'all' }
  ];

  public custom_clearance = [
    { name: 'CAR.CUSTOM_CLEARANCE.YES', id: true },
    { name: 'CAR.CUSTOM_CLEARANCE.NO', id: false }
  ];

  public guarantee = [
    { name: 'CAR.GUARANTEE.YES', id: true },
    { name: 'CAR.GUARANTEE.NO', id: false }
  ];

  public gas_cylinder_equipment = [
    { name: 'CAR.GAS_CYLINDER_EQUIPMENT.YES', id: true },
    { name: 'CAR.GAS_CYLINDER_EQUIPMENT.NO', id: false }
  ];

  constructor(
      private translate: TranslateService
  ) {
    for (let i = 3; i <= 100; i += 1) {
      this.cubic_capacity.push({
        name: (i / 10).toString(),
        id: i / 10
      });
    }
  }

  get(value: any, key: string, def = '') {
    return this[key] && this[key][value] ? this.translate.instant(this[key][value]) : def || CarLocalizeConstant[key.toUpperCase()] || CarLocalizeConstant.DEFAULT;
  }

  fromArray(value: any, key: string, def = '') {
    return this[key] && this[key].some(item => item.id === value) ? this.translate.instant(this[key].find(item => item.id === value).name) : def || CarLocalizeConstant[key.toUpperCase()] || CarLocalizeConstant.DEFAULT;
  }

  getThumbnail(images: undefined | Array<{ thumbnail: string }>) {
    return images && images[0] && images[0].thumbnail ? images[0].thumbnail : CarLocalizeConstant.THUMBNAIL;
  }

  getList(arrayKey) {
    return [...this[arrayKey].map(item => ({ ...item, name: this.translate.instant(item.name) }))];
  }

  toArray(key) {
    if (!this[key]) {
      return [];
    }
    return Object.keys(this[key]).map(id => ({
      id,
      name: this[key][id]
    }));
  }
}
