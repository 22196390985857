import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Brand } from '@shared/interfaces/car/brand.interface';

@Component({
  selector: 'app-popular-brands',
  templateUrl: './popular-brands.component.html'
})
export class PopularBrandsComponent {

  @Input() popularBrands: Brand[] = [];
  @Output() onBrandSelect = new EventEmitter<Brand['id']>();

  constructor() { }

  selectBrand(id: Brand['id']) {
    this.onBrandSelect.emit(id);
  }

}
