import { Component, Input, ViewChild } from '@angular/core';
import { CarImage } from '@shared/interfaces/car/car-image.interface';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SliderConfigs } from '../configs/slider-configs';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

@Component({
  selector: 'app-car-image-slider',
  templateUrl: './car-image-slider.component.html'
})
export class CarImageSliderComponent extends SliderConfigs {

  @ViewChild('mainCarousel') slick: SlickCarouselComponent;
  @Input() images: CarImage[] = [];
  galleryModalOpened = false;
  carTitle: string;
  carouselIndex = 0;

  constructor(
      public ngxSmartModalService: NgxSmartModalService,
  ) {
    super();
  }

  openGallery() {
    this.slick.slickGoTo(this.carouselIndex);
  }
}
