export enum AuthRoutes {
    AUTH = 'auth',
    SIGN_IN = 'sign-in',
    LOGIN_WITH_LINK = 'in/:id/:token',
    SIGN_UP = 'sign-up',
    FORGOT_PASSWORD = 'forgot-password',
    RESET_PASSWORD = 'reset-password/:token'
}

export enum AccountRoutes {
    MY = 'my',
    ACCOUNT = 'account',
    CARS = 'cars',
    FAVORITES = 'favorites',
}

export enum AddEditCarRoutes {
    MANAGE = 'manage',
    ADD_CAR = 'vehicle',
    EDIT_CAR = 'vehicle/:id',
}

export enum OfficialsRoutes {
    OFFICIALS = 'officials',
    ACCOUNT = 'account',
    PUBLIC = ':id/:slug',
    SIGN_UP = 'sign-up'
}

export enum GeneralRoutes {
    HOME = '',
    ABOUT_US = 'about-us',
    CONTACT_US = 'contact-us',
    CAR_SINGLE = 'car',
    CAR_SINGLE_ID = '/:id',
    CAR_SINGLE_SLUG = '/:slug',
    TERMS_AND_CONDITIONS = 'terms-and-conditions',
    PRIVACY_POLICY = 'privacy-policy',
    SEARCH_CAR = 'search-car',
}

export enum Social_APP_Links {
    APP_STORE = 'https://play.google.com/store/apps/details?id=am.gear.app',
    GOOGLE_PLAY = 'https://apps.apple.com/us/app/gear-am/id1460577458?ls=1',
    FACEBOOK = 'https://www.facebook.com/1gear.am/',
    INSTAGRAM = 'https://www.instagram.com/1gear.am/',
    YOUTUBE = 'https://www.youtube.com/channel/UCh6LyhzpwyrzABT7m6-C8gg'
}

export const FULL_ROUTES = {
    HOME: GeneralRoutes.HOME,
    ABOUT_US: `${GeneralRoutes.ABOUT_US}`,
    CONTACT_US: `${GeneralRoutes.CONTACT_US}`,
    CAR_SINGLE: `${GeneralRoutes.CAR_SINGLE}`,
    CAR_SINGLE_WITH_ID: `${GeneralRoutes.CAR_SINGLE}${GeneralRoutes.CAR_SINGLE_ID}`,
    CAR_SINGLE_FULL_PATH: `${GeneralRoutes.CAR_SINGLE}${GeneralRoutes.CAR_SINGLE_ID}${GeneralRoutes.CAR_SINGLE_SLUG}`,
    TERMS_AND_CONDITIONS: `${GeneralRoutes.TERMS_AND_CONDITIONS}`,
    PRIVACY_POLICY: `${GeneralRoutes.PRIVACY_POLICY}`,
    SEARCH_CAR: `${GeneralRoutes.SEARCH_CAR}`,
    ACCOUNT: `${AccountRoutes.MY}`,
    MY_ACCOUNT: `${AccountRoutes.MY}/${AccountRoutes.ACCOUNT}`,
    MY_CARS: `${AccountRoutes.MY}/${AccountRoutes.CARS}`,
    FAVORITES: `${AccountRoutes.MY}/${AccountRoutes.FAVORITES}`,
    CAR_MANAGE: `${AddEditCarRoutes.MANAGE}`,
    ADD_CAR: `${AddEditCarRoutes.MANAGE}/${AddEditCarRoutes.ADD_CAR}`,
    EDIT_CAR: `${AddEditCarRoutes.MANAGE}/${AddEditCarRoutes.EDIT_CAR}`,
    AUTH: `${AuthRoutes.AUTH}`,
    SIGN_IN: `/${AuthRoutes.AUTH}/${AuthRoutes.SIGN_IN}`,
    SIGN_UP: `/${AuthRoutes.AUTH}/${AuthRoutes.SIGN_UP}`,
    FORGOT_PASSWORD: `/${AuthRoutes.AUTH}/${AuthRoutes.FORGOT_PASSWORD}`,
    OFFICIALS: `${OfficialsRoutes.OFFICIALS}`,
    OFFICIALS_SING_UP: `/${OfficialsRoutes.OFFICIALS}/${OfficialsRoutes.SIGN_UP}`,
    OFFICIALS_MY_ACCOUNT: `/${OfficialsRoutes.OFFICIALS}/${OfficialsRoutes.ACCOUNT}`,
};
