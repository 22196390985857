import { Injectable } from '@angular/core';
import {
  CarFromAnotherWebsite,
  CarFromAnotherWebsiteRequest
} from '@shared/interfaces/car/car-from-another-website.interface';
import { BaseAPIService } from '@shared/providers/base-api.service';
import { HttpClient } from '@angular/common/http';
import { map, mergeMap, take, takeWhile, tap } from 'rxjs/operators';
import { interval, Observable, Subject } from 'rxjs';
import { ResponseModel } from '@shared/interfaces/response-model.interface';
import { getDateDifferenceInMinutes, getHostnameFromUrl, carSlug } from '@shared/utils/utils';
import { Car } from '@shared/interfaces/car/car.interface';
import { CarService } from '@shared/providers/car/car.service';
import { CAR_SCRAPER } from '@shared/constants/api-endpoints';
import { ADD_CAR_STATUSES, SCRAPER } from '@shared/constants/car/add-car-scraper.constant';
import { AddCarFromAnotherWebsiteInfoBoxService } from '@shared/providers/advert/add-car-from-another-website-info-box.service';
import { FULL_ROUTES } from '../../../routes';

@Injectable({
  providedIn: 'root'
})
export class AddCarFromAnotherWebsiteService extends BaseAPIService {

  private readonly fullRoutes = FULL_ROUTES;
  alreadyStartedChecking: boolean;
  $completed: Subject<void>;

  constructor(
      protected httpClient: HttpClient,
      private readonly carService: CarService,
      private infoBoxService: AddCarFromAnotherWebsiteInfoBoxService
  ) {
    super(httpClient);
    this.$completed = new Subject<void>();
  }

  createRequest(data: CarFromAnotherWebsiteRequest) {
    return this.post<CarFromAnotherWebsite>(CAR_SCRAPER.ADD_CAR, data).pipe(
        tap((res: CarFromAnotherWebsite) => {
          res.title = getHostnameFromUrl(data.url);
          this.infoBoxService.addTask(res);
          this.statusChecker();
        })
    );
  }

  statusChecker(): void {
    if (!this.infoBoxService.inProgressTasks.length) {
      this.stopStatusChecking();
      return;
    }
    if (this.alreadyStartedChecking) {
      return;
    }

    this.alreadyStartedChecking = true;

    interval(SCRAPER.INTERVAL)
        .pipe(
            mergeMap(() => {
              return this.getStatuses();
            }),
            map((result: Array<CarFromAnotherWebsite>) => {
              if (!result.length) {
                // no status updates yet
                return;
              }
              this.infoBoxService.inProgressTasks.forEach(car => {
                if (!car.requestStartDate) {
                  car.requestStartDate = new Date();
                }
                const index = result.findIndex(item => item.task_id === car.task_id);
                if (index > -1 && this.infoBoxService.inTasks(result[index].task_id)) {
                  const item = result[index];
                  item.slug = car.slug;
                  item.title = car.title;
                  switch (item.status) {
                    case ADD_CAR_STATUSES.SUCCESS:
                      this.changeItemStatus(item, ADD_CAR_STATUSES.SUCCESS);
                      break;
                    case ADD_CAR_STATUSES.FAILURE:
                      this.changeItemStatus(item, ADD_CAR_STATUSES.FAILURE);
                      break;
                    default:
                      this.changeItemStatus(item, ADD_CAR_STATUSES.PENDING);
                      break;
                  }
                } else {
                  const now = new Date();
                  const minutes = getDateDifferenceInMinutes(car.requestStartDate, now);
                  if (minutes >= SCRAPER.TIMEOUT) {
                    this.changeItemStatus(car, ADD_CAR_STATUSES.FAILURE);
                  }
                }
              });

              if (!this.infoBoxService.inProgressTasks.length) {
                this.stopStatusChecking();
              }
            })
        )
        .pipe(
            takeWhile(() => this.infoBoxService.inProgressTasks.length > 0)
        )
        .subscribe();
  }

  private getStatuses(): Observable<Array<CarFromAnotherWebsite>> {
    return this.post(
        CAR_SCRAPER.RESULT,
        {
          ids: this.infoBoxService.inProgressTasks.map(
              item => item.task_id
          )
        }
    );
  }

  private stopStatusChecking(): void {
    /*this.infoBoxService.updateData(
        0,
        AddCarFromOtherWebsiteService.completedTasks.size,
        null
    );*/
    this.alreadyStartedChecking = false;
  }

  private changeItemStatus(item: CarFromAnotherWebsite, status: ADD_CAR_STATUSES): void {
    item.status = ADD_CAR_STATUSES[status];
    this.infoBoxService.updateTask(item.task_id, item);
    if (status === ADD_CAR_STATUSES.SUCCESS) {
      this.addCompletedItem(item.task_id);
      this.carService.getCars({slug: item.slug})
          .pipe(take(1))
          .subscribe((response: ResponseModel<Car>) => {
            const car = response.results[0];
            if (car) {
              item.routerLink = ['/', this.fullRoutes.CAR_SINGLE, `${car.id}`, carSlug(car)];
              item.title = car.car_model.brand.name + ' ' + car.car_model.name;
              this.infoBoxService.updateTask(item.task_id, item);
            }
          });
    } else {
      this.infoBoxService.addErrored(item.task_id);
    }
  }

  addCompletedItem(taskId) {
    this.infoBoxService.addCompleted(taskId);
    this.$completed.next();
  }

}
