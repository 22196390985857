export function setItem(key: string, value: any): void {
  localStorage.setItem(key, value);
}

export function setJSONStringified(key: string, value: object): void {
  const str = JSON.stringify(value);
  localStorage.setItem(key, str);
}

export function getItem(key: string, defaultValue = null): string {
  return localStorage.getItem(key) || defaultValue;
}

export function getJSONParsed<T>(key: string): T {
  const item = getItem(key);
  try {
    return JSON.parse(item);
  } catch {
    return null;
  }
}
