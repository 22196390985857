import { DynamicField } from '../../modules/dynamic-fields/classes/DynamicField';
import { FormGroup } from '@angular/forms';

export abstract class CarMainForm {

    // form
    form: FormGroup;

    similarGroup(parameters, config) {
        const objects = [];
        for (const parameter of parameters) {
            const item = new DynamicField(config);
            item.addExtraParameters(parameter);
            objects.push(item);
        }
        return objects;
    }

    excludeFields(array: DynamicField[], ...items) {
        return array.filter(item => !items.includes(item.formControlName));
    }

    includeFields(array: DynamicField[], ...items: ({[key: string]: Partial<DynamicField>} | string | DynamicField)[]) {
        const arr = [];
        for (const item of items) {
            let el;
            if (typeof item === 'string') {
               el = array.find(control =>
                   control.toControlNameArray.includes(item)
               );
               if (el) {
                    arr.push(el);
                }
            } else if (item instanceof DynamicField) {
                arr.push(item)
            } else {
                for (const key in item) {
                    el = array.find(control =>
                        control.toControlNameArray.includes(key)
                    );
                    if (el) {
                        el.addExtraParameters(item[key]);
                        arr.push(el);
                    }
                }
            }
        }
        return arr;
    }

    joinArrays(...array) {
        return array.reduce((flat, curr) => {
            if (Array.isArray(curr)) {
                return flat.concat(this.joinArrays(...curr));
            }
            return flat.concat(curr);
        }, []);
    }

    removeControls(items, controls) {
        for (const item of items) {
           for (const control of controls) {
               const index = item.fields.findIndex(el => el.formControlName === control);
               if (index > -1) {
                   item.fields.splice(index, 1);
                   this.form.removeControl(control);
               }
           }
        }
        return items;
    }

}
