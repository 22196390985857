import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SignUp } from '@shared/helpers/forms/sign-up-form';
import { AuthService } from '@shared/providers/auth/auth.service';
import { SnackbarService } from '@shared/snackbar/snackbar.service';


@Component({
    selector: 'app-home-sign-in',
    templateUrl: './sign-in.component.html'
})
export class SignInComponent extends SignUp implements OnDestroy {

    constructor(
        protected readonly authService: AuthService,
        protected readonly snackbarService: SnackbarService,
        protected readonly router: Router,
    ) {
        super(authService, snackbarService, router);
    }

    public submit(): void {
        if (this.loading) {
            return;
        }
        if (this.form.invalid) {
            this.validateAll();
            return;
        }

        this.handleSignUp();
    }

    ngOnDestroy(): void {
       this.$subscriptions.unsubscribe();
    }
}
