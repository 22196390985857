import { FormControl } from '@angular/forms';

export function onlyAlphabeticChars(control: FormControl): { [key: string]: string } {
    const pattern = /^[a-zA-Zա-ֆԱ-ՖА-Яа-я' ]*$/;
    return control.value && !pattern.test(control.value) ? { notOnlyChars: 'VALIDATION.ONLY_ALPHABETICAL_CHARS'} : null;
}

export function notOnltSpaces(control: FormControl): { [key: string]: string } {
    if (!control.value) {
        return null;
    }
    return control.value.trim() ? null : {notOnlySpaces: 'VALIDATION.NOT_ONLY_SPACES'};
}
