import { AfterViewInit, Component, EventEmitter, Input, OnDestroy,  Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { SearchForm } from '@shared/helpers/search/search-form';
import { PagePreloaderComponent } from '../../../layout/page-preloader/page-preloader.component';
import { CarFullSearchService } from '@shared/providers/search/car-full-search.service';
import { FIELD_PROVIDERS } from '@shared/providers/field-providers';

@Component({
  selector: 'app-car-filter',
  templateUrl: './car-filter.component.html',
  providers: FIELD_PROVIDERS
})
export class CarFilterComponent implements AfterViewInit, OnDestroy {

  private $subscriptions: Subscription = new Subscription();
  @Input() showFilters = false;
  @Output() onFilterValueChange = new EventEmitter<Params>();
  @Output() onShowFilters = new EventEmitter<boolean>();
  public formPending = false;
  public expandedItem: any = {
    0: true // first item will be expanded
  };


  constructor(
      public carFullSearch: CarFullSearchService,
      protected activatedRoute: ActivatedRoute
  ) {}

  ngAfterViewInit(): void {
    this.onFormValueChange();
  }

  private onFormValueChange() {
    this.$subscriptions.add(
        this.carFullSearch.form.valueChanges.subscribe(res => {
          if (this.carFullSearch.form.valid && !this.formPending) {
            PagePreloaderComponent.isGoToTopEnabled = false;
            this.emitValueChange(res);
          }
        })
    );
  }

  resetForm() {
    this.formPending = true;
    this.carFullSearch.form.reset();
    this.emitValueChange(this.carFullSearch.form.value);
    this.formPending = false;
  }

  expandItem(index: number) {
    this.expandedItem[index] = !this.expandedItem[index];
  }

  emitShowFilterChange() {
    this.onShowFilters.emit(true);
  }

  emitValueChange(values) {
    this.onFilterValueChange.emit(SearchForm.filterSearchFormValue({
      ...this.activatedRoute.snapshot.queryParams,
      ...values
    }));
  }

  ngOnDestroy(): void {
      this.$subscriptions.unsubscribe();
  }

}
