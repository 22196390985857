import { FormGroup } from '@angular/forms';

export function bindRequiredField(main, ...children): (group: FormGroup) => { [p: string]: string } {
    return (group: FormGroup): { [key: string]: string } => {
        if (!group.value[main] || !group.dirty) {
            return null;
        }
        for (const controlName of children) {
            if (!group.value[controlName]) {
                return { bindRequired: 'VALIDATION.REQUIRED' };
            }
        }
        return null;
    };
}
