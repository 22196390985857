import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base-api.service';
import { ACCOUNT, CAR } from '../../constants/api-endpoints';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseModel } from '@shared/interfaces/response-model.interface';
import { Model } from '@shared/interfaces/car/model.interface';
import { CarGeneration } from '@shared/interfaces/car/car-generation.interface';
import { Country } from '@shared/interfaces/car/region.interface';
import { Car } from '@shared/interfaces/car/car.interface';
import { CarAddEditBody } from '@shared/interfaces/car/car-add-edit-body.interface';


@Injectable({
  providedIn: 'root'
})
export class CarService extends BaseAPIService {

    constructor(
        protected httpClient: HttpClient
    ) {
        super(httpClient);
    }

    getCars<T>(query: any): Observable<T> {
        return this.getList(CAR.LIST, query);
    }

    getSimilarCars<T>(query: any): Observable<T> {
        return this.getList<T>(`${CAR.SIMILAR_LIST}`, query);
    }

    getCar<T>(id: Car['id']): Observable<T> {
        return this.getById(`${CAR.LIST}`, id);
    }

    getCarSeries<T>(): Observable<ResponseModel<T>> {
        return this.getList(`${CAR.CAR_SERIES}`, { limit: -1 })
            .pipe(map((response: ResponseModel<T>) => {
                    (response as any).results = (response as any).results.sort((a, b) => a.name && a.name.localeCompare(b.name));
                    return response;
                })
            );
    }

    getBrands<T>(): Observable<ResponseModel<T>> {
        return this.getList(`${CAR.BRAND}`, { limit: -1 })
            .pipe(map((response: ResponseModel<T>) => {
                (response as any).results = (response as any).results.sort((a, b) => a.name && a.name.localeCompare(b.name));
                return response;
            }));
    }

    getModel<T>(id: Model['id']): Observable<ResponseModel<T>> {
        return this.getList(`${CAR.MODEL}`, { brand: id, limit: -1 })
            .pipe(map((response: ResponseModel<T>) => {
                (response as any).results = (response as any).results.sort((a, b) => {
                    if (Number(a.name) && Number(b.name)) {
                        return a.name - b.name;
                    }
                    return a.name && a.name.localeCompare(b.name);
                });
                return response;
            }));
    }

    getGenerations<T>(id: CarGeneration['id']): Observable<ResponseModel<T>> {
        return this.getList(`${CAR.GENERATION}`, { model: id, limit: -1 });
    }

    getCountries<T>(): Observable<ResponseModel<T>> {
        return this.getList(`${CAR.COUNTRIES}`, { limit: -1 })
            .pipe(map((response: ResponseModel<T>) => {
                    (response as any).results = (response as any).results.sort((a, b) => a.name && a.name.localeCompare(b.name));
                    return response;
                })
            );
    }

    getRegions<T>(id: Country['id']): Observable<ResponseModel<T>> {
        return this.getList(`${CAR.REGIONS.replace('{country_id}', id)}`, { limit: -1 })
            .pipe(
                map((response: ResponseModel<T>) => {
                    (response as any).results = (response as any).results.sort((a, b) => a.name && a.name.localeCompare(b.name));
                    return response;
                })
            );
    }

    getColors<T>(): Observable<ResponseModel<T>> {
        return this.getList(`${CAR.COLOR_URL}`, { limit: -1 })
            .pipe(map((response: ResponseModel<T>) => {
                    (response as any).results = (response as any).results.sort((a, b) => a.hash && a.hash.localeCompare(b.hash));
                    return response;
                })
            );
    }

    contactUs<T>(body): Observable<ResponseModel<T>> {
        return this.post(CAR.CONTACT_US, body);
    }
}
