<div class="card">
    <div class="flex align-center justify-between">
        <button *ngIf="car?.advert?.user != authService.userId"
                (click)="toggleFavourite()"
                [ngClass]="car.favorite?.favorite?'filled':'outline'"
             class="btn primary like-btn flex align-center fw-600 mr-4 mr-md-2 mr-sm-0">
            <i class="mr-3 mr-xl-2" [ngClass]="car.favorite?.favorite?'icon-favorite-filled':'icon-favorite-outline'"></i>
           <span> {{ 'CAR_DETAIL.LIKE' | translate }}</span>
        </button>
        <button (click)="fbShareBtnClick()"
                class="btn link color-primary flex align-center fw-600">
            <i class="icon-share mr-3 mr-xl-2"></i>
            {{ 'CAR_DETAIL.SHARE' | translate }}
        </button>
    </div>

    <ul class="list-with-icons vertical md">
        <li *ngIf="car?.advert?.contact_person">
            <i class="icon-person"></i>
            <span>{{ car?.advert?.contact_person }}</span>
        </li>
        <li *ngIf="car?.advert?.email">
            <i class="icon-email"></i>
            <a [href]="'mailto:'+car.advert.email" class="link color-text word-break">{{car.advert.email}}</a>
        </li>
        <li *ngIf="car?.advert?.tel_num">
            <i class="icon-phone"></i>
            <div>
                <span class="block">{{ 'CAR_DETAIL.CALL' | translate }}</span>
                <a [href]="'tel:'+car.advert.tel_num" class="link color-secondary">{{car.advert.tel_num}}</a>
            </div>
        </li>
    </ul>

    <p class="text-right">{{ 'CAR_DETAIL.DATE' | translate }}: {{car?.advert?.created_at | date:'dd.MM.yyyy' }}</p>
</div>

<div class="additional-description" *ngIf="car?.advert?.additional_information">
    <h5 class="sm color-secondary mb-3">{{ 'CAR_DETAIL.GENERAL' | translate }}</h5>
    <p class="fs-sm">{{ car.advert.additional_information }}</p>
</div>