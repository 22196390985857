import { environment } from './environments/environment';

(window as any).fbAsyncInit = (): void => {
  FB.init({
    appId: environment.FB.APP_ID,
    autoLogAppEvents: true,
    xfbml: true,
    version: environment.FB.APP_VERSION
  });
};

((d: Document, s: string, id: string): void => {
  const fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) { return; }
  const js = d.createElement(s) as HTMLScriptElement;
  js.id = id;
  js.src = '//connect.facebook.net/en_US/sdk.js';
  fjs.parentNode.insertBefore(js, fjs);
})(document, 'script', 'facebook-jssdk');
