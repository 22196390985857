import { Injectable } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicField } from '../classes/DynamicField';
import { DYNAMIC_FIELDS_DEF_VALUES } from '../constants/dynamic-fields-default-values';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormCreateService {

  constructor(private fb: FormBuilder) {}

  createControl(field: DynamicField, form: FormGroup) {
    if (field.type === 'button') {
      return;
    }
    const names = field.toControlNameArray;
    for (const name of names) {
      if (!form.contains(name)) {
        const control = this.fb.control(
            this.getValue(field),
            {
              ...field.validation && {
                validators: field.validation
              },
              ...field.updateType && {
                updateOn: field.updateType
              }
            }
        );
        form.addControl(name, control);
      }
    }
  }

  createForm(items: DynamicField[] = [], options: AbstractControlOptions = null) {
    const form = this.fb.group({}, options);
    for (const item of items) {
      this.createControl(item, form);
    }
    return form;
  }

  getValue(field: DynamicField) {
    return (typeof field.value !== 'undefined') ? field.value : DYNAMIC_FIELDS_DEF_VALUES[field.type];
  }
}
