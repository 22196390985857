<footer>
  <div class="bg-white content-top">
    <div class="container flex align-center justify-between">
      <div class="content-left w-75">
        <p>{{ 'FOOTER.JOIN_US' | translate}}</p>
        <ul class="social-links flex align-center mt-4">
          <li *ngFor="let social of socialLinksConfig">
            <a [href]="social.href" target="_blank">
              <i [class]="social.icon"></i>
            </a>
          </li>
        </ul>
        <ul class="nav mt-3">
          <li *ngFor="let val of linksConfig">
            <a [routerLink]="['/', val.path]">{{ val.text | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="content-right text-xs-right">
        <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F1gear.am%2F&tabs&width=300&height=154&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                width="270" height="154" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
      </div>
    </div>
  </div>
  <div class="bg-secondary content-bottom pt-3 pb-3">
    <div class="container text-center">
      <p class="color-white mb-1 flex align-center justify-center">
        <i class="icon-pin fs-lg mr-1"></i>
        {{ 'FOOTER.ADDRESS' | translate}}
      </p>
      <p class="color-white-50 fs-sm">
        &copy; {{ currentYear }} gear.am {{ 'FOOTER.ALL_RIGHTS_RESERVED' | translate}}
      </p>
    </div>
  </div>
</footer>
