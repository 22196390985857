import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@shared/providers/auth/auth.service';
import { Car } from '@shared/interfaces/car/car.interface';
import { CarLocalizeService } from '@shared/providers/car/car-localize.service';
import { SnackbarService } from '@shared/snackbar/snackbar.service';
import { Router } from '@angular/router';
import { carSlug } from '@shared/utils/utils';
import { CarDetailAction } from '@shared/helpers/car/car-detail-action';
import { AccountService } from '@shared/providers/account/account.service';
import { RouterLoaderService } from '@shared/providers/router/router-loader.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-car-item',
  templateUrl: './car-item.component.html'
})
export class CarItemComponent extends CarDetailAction implements OnInit, OnDestroy {

  public slug: string;
  @Input() car: Car;
  @Input() editable: boolean;

  constructor(
      public readonly authService: AuthService,
      public readonly carLocalize: CarLocalizeService,
      public readonly accountService: AccountService,
      public readonly snackbarService: SnackbarService,
      public readonly router: Router,
      protected routerLoaderService: RouterLoaderService,
      protected ngxSmartModalService: NgxSmartModalService
  ) {
    super(
        authService,
        router,
        accountService,
        snackbarService,
        routerLoaderService,
        ngxSmartModalService
    );
  }

  ngOnInit(): void {
      this.slug = carSlug(this.car);
  }

}
