import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@shared/providers/auth/auth.service';
import { Subscription } from 'rxjs';
import { CarService } from '@shared/providers/car/car.service';
import { ResponseModel } from '@shared/interfaces/response-model.interface';
import { Car } from '@shared/interfaces/car/car.interface';
import { Carousel } from '@shared/ui/carousel/carousel';
import { SnackbarService } from '@shared/snackbar/snackbar.service';

const requestTypes = {
  last: 'getCars',
  similar: 'getSimilarCars'
};

@Component({
  selector: 'app-top-vehicles',
  templateUrl: './top-vehicles.component.html'
})
export class TopVehiclesComponent extends Carousel implements OnInit, OnDestroy {

  private $subscriptions: Subscription = new Subscription();
  public carouselItems: Car[] = [];
  public loading = true;

  constructor(
      public  readonly authService: AuthService,
      private readonly carService: CarService,
      private readonly snackbarService: SnackbarService,
  ) {
    super();
  }

  ngOnInit(): void {
    const type = requestTypes[this.type];
    if (type) {
      this.$subscriptions.add(
          this.carService[type]<ResponseModel<Car>>({
            ...this.query,
            ...this.isTop && {top: 'True'}
          }).subscribe(res => {
            this.carouselItems = res.results;
          }, error => {
            this.snackbarService.handleErrorsAndOpen('SHARED.NOT_FOUND_MSG', error.error.errors);
          }).add(() =>  this.loading = false)
      );
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.unsubscribe();
  }

}
