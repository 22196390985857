export enum ValidationMessages {
    required = 'VALIDATION.REQUIRED'
}

export enum CarImageValidation {
    SIZE_MB = 10,
    MAX_SIZE = SIZE_MB * 1024 * 1024, // 10 mb
    MAX_LENGTH = 30
}

export enum CarFieldsValidation {
    MIN_PROD_DATE = 1950,
    MAX_PROD_DATE = (new Date()).getFullYear(),
    MIN_PRICE = 1,
    MAX_PRICE = 600000000, // 9 digits
    RANGE_MIN = 1,
    RANGE_MAX = 3000000, // 7 digits
    TEXTAREA_MIN_LENGTH = 2,
    TEXTAREA_MAX_LENGTH = 2000,
    MIN_POWER = 1,
    MAX_POWER = 1600
}
