import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SearchActions } from '@shared/helpers/search/search-actions-form';
import { CarService } from '@shared/providers/car/car.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { CarSearchConstant } from '@shared/constants/car/car-search.constant';
import { DynamicFormCreateService } from '../../../modules/dynamic-fields/providers/dynamic-form-create.service';

@Component({
  selector: 'app-search-car-header',
  templateUrl: './search-car-header.component.html'
})
export class SearchCarHeaderComponent extends SearchActions implements AfterViewInit, OnDestroy {

  private $subscriptions: Subscription = new Subscription();

  @Input() viewMode: boolean;
  @Input() showFilters: boolean;

  @Output() onViewModeChange = new EventEmitter<boolean>();
  @Output() onShowFiltersChange = new EventEmitter<boolean>();
  @Output() onFilterValueChange = new EventEmitter<Params>();

  constructor(
      public readonly carService: CarService,
      public readonly dynamicForm: DynamicFormCreateService,
      public readonly activatedRoute: ActivatedRoute,
  ) {
    super(dynamicForm, carService, activatedRoute);
  }

  ngAfterViewInit(): void {
    this.onFormValueChange();
  }

  private onFormValueChange() {
    this.$subscriptions.add(
        this.form.valueChanges.subscribe(res => {
          const queryParams = {
            ...this.activatedRoute.snapshot.queryParams
          };
          // init values
          for (const item of this.searchActionItems[SearchActions.INDEXES.SORT_BY].source) {
            if (queryParams.hasOwnProperty(item.id)) {
              delete queryParams[item.id];
              break;
            }
          }
          queryParams[CarSearchConstant.LIMIT.KEY] = this.form.value.limit;
          queryParams[this.form.value.sort_by] = CarSearchConstant.SORT_BY.VALUE;
          this.onFilterValueChange.emit(queryParams);
        })
    );
  }

  bodyCover() {
    const body = document.getElementById('body');
    body.classList.add('body-covered');
  }

  emitViewChange() {
    this.onViewModeChange.emit(true);
  }

  emitShowFiltersChange() {
    this.onShowFiltersChange.emit(true);
    this.bodyCover();
  }

  ngOnDestroy() {
    this.$subscriptions.unsubscribe();
  }

}
