import {  FormControl, AbstractControl } from '@angular/forms';

export function minMaxValidator(minKey: string, maxKey: string): (control: FormControl) => { [p: string]: string } {
    return (control: FormControl): { [key: string]: string } => {
        const group = control && control.parent;
        if (!group) return null;
        const min: AbstractControl = group.get(minKey);
        const max: AbstractControl = group.get(maxKey);
        if (!max || !max || !max.value || !min.value || parseFloat(max.value.toString()) >= parseFloat(min.value.toString())) return null;
        return { minMax: 'VALIDATION.MIN_MAX_MESSAGE' };
    };
}