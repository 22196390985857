import { Component } from '@angular/core';
import { AddCarFromAnotherWebsiteInfoBoxService } from '@shared/providers/advert/add-car-from-another-website-info-box.service';

@Component({
    selector: 'app-add-car-progress',
    templateUrl: './add-car-progress.component.html',
})
export class AddCarProgressComponent {

    constructor(
        public infoBoxService: AddCarFromAnotherWebsiteInfoBoxService
    ) {}

    get progressTranslation() {
        const translation = 'ADD_CAR.FROM_OTHER_SITE.STATUS_INDICATOR.';
        return `${translation}${this.infoBoxService.inProgressTasks.length > 1 ? 'IN_PROGRESS_MULTIPLE' : 'IN_PROGRESS_SINGLE'}`;
    }

}
