import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicField } from './classes/DynamicField';

@Component({
  selector: 'app-dynamic-fields',
  templateUrl: './dynamic-fields.component.html'
})
export class DynamicFieldsComponent {

  @Input() group: FormGroup;
  @Input() fields: DynamicField[];

  constructor() { }
}
