<div class="rules">
    <h4 class="fw-600">{{pageTitle | translate}}</h4>
    <ul class="info-list blue top-aligned">
        <li *ngFor="let item of rules" [class.no-style]="!item.title">
            <div>
                <h5 *ngIf="item.title" class="fw-400">{{ item.title | translate }}</h5>
                <div class="text" *ngFor="let des of item.descriptions"
                   [innerHTML]="des.text ? (des.text | translate : des.args) : des | translate">
                </div>
            </div>
        </li>
    </ul>
</div>