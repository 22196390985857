<div class="card filters-header flex justify-between mb-3">
    <ul class="view-options flex align-end mr-8">
        <li>
            <button class="btn-unstyled btn-view active" (click)="emitViewChange()" [ngClass]="{'active': !viewMode}">
                <i class="icon-grid"></i>
            </button>
        </li>
        <li>
            <button class="btn-unstyled btn-view" (click)="emitViewChange()" [ngClass]="{'active': viewMode}">
                <i class="icon-list"></i>
            </button>
        </li>
    </ul>
    <div class="flex align-center justify-between filter-options w-100">
        <app-dynamic-fields
                class="w-100"
                [group]="form"
                [fields]="searchActionItems">
        </app-dynamic-fields>
        <button class="btn-unstyled btn-filter color-primary" (click)="emitShowFiltersChange()">
            <i class="icon-filter mr-2"></i>
            {{ 'FILTER.FILTER' | translate }}
        </button>
    </div>
</div>