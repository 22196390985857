export enum SCRAPER {
    TIMEOUT = 10, // minutes
    INTERVAL = 7 * 1000 // milliseconds
}

export enum ADD_CAR_STATUSES {
    PENDING = 'PENDING',
    RECEIVED = 'PENDING',
    STARTED = 'PENDING',
    RETRY = 'PENDING',
    REVOKED = 'PENDING',
    FAILURE = 'FAILURE',
    IGNORED = 'FAILURE',
    SUCCESS = 'SUCCESS'
}