import { Component } from '@angular/core';
import { DynamicComponent } from '../../classes/DynamicComponent';

@Component({
  selector: 'app-dynamic-select',
  templateUrl: './dynamic-select.component.html'
})
export class DynamicSelectComponent extends DynamicComponent {

  keyPress = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
      const control = this.group.get(this.field.formControlName);
      const source = this.flatSource;
      let index = source.findIndex(item => item.id === control.value);
      const sourceLength = source.length;
      const objDiv = document.querySelector(`.${this.getUniqueClass()} .scroll-host`);
      let toIndex;
      if (e.code === 'ArrowUp') {
        if (index === -1) {
          index = sourceLength;
          objDiv.scrollTop = objDiv.scrollHeight;
        }
        toIndex = index - 1;
        if (toIndex === -1) {
          toIndex = sourceLength - 1;
          objDiv.scrollTop = objDiv.scrollHeight;
        }
      } else {
        toIndex = index + 1;
        if (toIndex === sourceLength) {
          toIndex = 0;
          objDiv.scrollTop = -500;
        }
      }
      if (toIndex > -1 && toIndex < sourceLength) {
        control.setValue(source[toIndex].id);
        if (this.isGrouped) {
          const optionHeight = document.querySelector(`.${this.getUniqueClass()} .ng-option`).clientHeight;
          setTimeout(() => objDiv.scrollTop = toIndex * optionHeight, 0);
        }
      }
    }
    return true;
  }

  get flatSource() {
    return [].concat(...this.field.source.map(item => item.source ? item.source : item));
  }

  get isGrouped() {
    return this.field.source.some(item => item.source);
  }

}
