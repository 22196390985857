<!-- ERROR -->
<div class="box-info error" *ngIf="infoBoxService.showErrorMessage">
  <button class="btn-unstyled btn-clear" (click)="infoBoxService.showErrorMessage = false">
    <i class="icon-clear"></i>
  </button>
  <div class="info-content">
    <p class="flex align-center info-text">{{ 'ADD_CAR.FROM_OTHER_SITE.STATUS_INDICATOR.FAILURE' | translate }}</p>
  </div>
</div>

<!-- SUCCESS -->
<div class="box-info success" *ngIf="infoBoxService.showSuccessMessage">
  <button class="btn-unstyled btn-clear" (click)="infoBoxService.showSuccessMessage = false">
    <i class="icon-clear"></i>
  </button>
  <div class="info-content">
    <p class="flex align-center info-text">{{ 'ADD_CAR.FROM_OTHER_SITE.STATUS_INDICATOR.SUCCESS_SINGLE' | translate }}</p>
  </div>
</div>

<!-- processes example -->
<div class="box-info progress" *ngIf="infoBoxService.allTasks.length">
  <button class="btn-unstyled btn-clear" (click)="infoBoxService.deleteTasks()">
    <i class="icon-clear"></i>
  </button>
  <div class="info-content">
    <p class="fw-600 color-secondary" *ngIf="infoBoxService.inProgressTasks.length">
      {{infoBoxService.inProgressTasks.length}}/{{infoBoxService.allTasks.length}} {{ progressTranslation | translate }}
    </p>
    <app-dynamic-tasks [tasks]="infoBoxService.allTasks"></app-dynamic-tasks>
  </div>
</div>

