import { Input } from '@angular/core';

export class Carousel {

  @Input() protected type = 'last';
  @Input() protected isTop = false;
  @Input() protected query = {
    offset: 0,
    limit: 25,
  };

  slideConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1376,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 606,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  constructor() {}

}
