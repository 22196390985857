import { Injectable } from '@angular/core';
import { DynamicFormCreateService } from '../../../modules/dynamic-fields/providers/dynamic-form-create.service';
import { CarAddEdit } from '@shared/helpers/car/car-add-edit-form';
import { CarLocalizeService } from '@shared/providers/car/car-localize.service';
import { CarAdvancedSearchService } from '@shared/providers/search/car-advanced-search.service';
import { CarFastSearchService } from '@shared/providers/search/car-fast-search.service';
import { CarVehicleTypeService } from '@shared/providers/search/car-vehicle-type.service';

@Injectable({
  providedIn: 'root'
})
export class CarAddEditService extends CarAddEdit {

  constructor(
      protected dynamicForm: DynamicFormCreateService,
      protected advancedSearch: CarAdvancedSearchService,
      protected fastSearch: CarFastSearchService,
      protected vehicleType: CarVehicleTypeService,
      protected carLocalize: CarLocalizeService
  ) {
    super(dynamicForm, advancedSearch, fastSearch, vehicleType, carLocalize);
  }
}
