import { DynamicField } from '../../../modules/dynamic-fields/classes/DynamicField';
import { FormGroup } from '@angular/forms';
import { CarLocalizeService } from '@shared/providers/car/car-localize.service';
import { CarService } from '@shared/providers/car/car.service';
import { Country, Region } from '@shared/interfaces/car/region.interface';
import { SearchForm } from '@shared/helpers/search/search-form';
import { DynamicFormCreateService } from '../../../modules/dynamic-fields/providers/dynamic-form-create.service';
import { minMaxValidator } from '@shared/validators/min-max.validator';
import { FieldGroup } from '../../../modules/dynamic-fields/interfaces/field-group.interface';
import { CarMainForm } from '@shared/helpers/car-main-form';
import { CarFieldsValidation } from '@shared/constants/validation/validation.constant';
import { numberMask } from '@shared/utils/mask';
import { onlyNumbersWithMinMax } from '@shared/validators/number.validator';

export abstract class AdvancedSearch extends CarMainForm {

    protected constructor(
        protected dynamicForm: DynamicFormCreateService,
        protected carService: CarService,
        protected carLocalize: CarLocalizeService
    ) {
        super();
        this.formConfigs();
    }

    public static INDEXES: any = {};
    // fields
    public items: DynamicField[] = [];
    public groupedItems: FieldGroup[] = [];

    initFunctions(form: FormGroup = null) {
        if (form) {
            this.form = form;
        }
        this.initCountries();
    }

    formConfigs(): void {
        // init items
        this.items = [
            new DynamicField({
                type: 'select',
                label: 'ADD_CAR.COUNTRY',
                placeholder: 'CAR.ANY',
                wrapperClass: 'dynamic-input width-3',
                formControlName: 'country',
                loading: true,
                onChange: this.onCountrySelect

            }),
            new DynamicField({
                type: 'select',
                label: 'ADD_CAR.REGION',
                placeholder: 'CAR.ANY',
                wrapperClass: 'dynamic-input width-3',
                formControlName: 'region'
            }),
            new DynamicField({
                type: 'select',
                label: 'CAR.USAGE.LABEL',
                placeholder: 'CAR.ANY',
                wrapperClass: 'dynamic-input width-3',
                formControlName: 'usage',
                source: this.carLocalize.toArray('usage')
            }),
            new DynamicField({
                type: 'select',
                label: 'ADD_CAR.DAMAGE',
                placeholder: 'CAR.ANY',
                wrapperClass: 'dynamic-input width-3',
                formControlName: 'is_crashed',
                source: this.carLocalize.is_crashed
            }),
            new DynamicField({
                type: 'select',
                label: 'ADD_CAR.TRANSMISSION',
                placeholder: 'CAR.ANY',
                wrapperClass: 'dynamic-input width-3',
                formControlName: 'transmission',
                source: this.carLocalize.toArray('transmission')
            }),
            new DynamicField({
                type: 'select',
                label: 'CAR_DETAIL.FUEL_TYPE',
                placeholder: 'CAR.ANY',
                wrapperClass: 'dynamic-input width-3',
                formControlName: 'fuel_type',
                source: this.carLocalize.toArray('fuel_type')
            }),
            ...this.similarGroup([{
                label: 'ADD_CAR.POWER',
                placeholder: 'CAR.FROM',
                formControlName: 'power_min',
            }, {
                placeholder: 'CAR.TO',
                formControlName: 'power_max',
            }], {
                type: 'input',
                updateType: 'blur',
                wrapperClass: 'dynamic-input width-6',
                mask: numberMask(CarFieldsValidation.MAX_POWER),
                validation: [
                    onlyNumbersWithMinMax(
                        CarFieldsValidation.MIN_POWER,
                        CarFieldsValidation.MAX_POWER
                    ),
                    minMaxValidator('power_min', 'power_max')
                ],
            }),
            new DynamicField({
                type: 'select',
                label: 'ADD_CAR.DRIVE_TYPE',
                placeholder: 'CAR.ANY',
                wrapperClass: 'dynamic-input width-3',
                formControlName: 'drive_type',
                source: this.carLocalize.toArray('drive_type')
            }),
            new DynamicField({
                type: 'select',
                label: 'ADD_CAR.CUBIC_CAPACITY',
                placeholder: 'CAR.FROM',
                wrapperClass: 'dynamic-input width-6',
                formControlName: 'cubic_min',
                source: this.carLocalize.cubic_capacity
            }),
            new DynamicField({
                type: 'select',
                placeholder: 'CAR.TO',
                wrapperClass: 'dynamic-input width-6',
                formControlName: 'cubic_max',
                source: this.carLocalize.cubic_capacity
            }),
            new DynamicField({
                type: 'select',
                label: 'ADD_CAR.DRIVE',
                placeholder: 'CAR.ANY',
                wrapperClass: 'dynamic-input width-3',
                formControlName: 'drive_wheel_type',
                source: this.carLocalize.toArray('drive_wheel_type')
            }),
            new DynamicField({
                type: 'select',
                label: 'ADD_CAR.NUMBER_OF_OWNERS',
                placeholder: 'CAR.ANY',
                wrapperClass: 'dynamic-input width-3',
                formControlName: 'owners_count',
                source: this.carLocalize.toArray('owners_count')
            }),
            new DynamicField({
                type: 'select',
                label: 'ADD_CAR.POSTED_TIME',
                placeholder: 'CAR.ANY',
                wrapperClass: 'dynamic-input width-3',
                formControlName: 'posted_time',
                source: this.carLocalize.toArray('posted_time')
            }),
            new DynamicField({
                loading: true,
                type: 'color',
                label: 'ADD_CAR.COLOR',
                wrapperClass: 'dynamic-input width-3',
                formControlName: 'color',
            }),
            new DynamicField({
                type: 'switcher',
                wrapperClass: 'dynamic-input dynamic-input-align-center width-3',
                fieldClass: 'dynamic-input-justify-between',
                formControlName: ['custom_clearance', 'guarantee',  'gas_cylinder_equipment'],
                source: [
                    { name: 'CAR.CUSTOM_CLEARANCE.LABEL' },
                    { name: 'ADD_CAR.GUARANTEE' },
                    { name: 'ADD_CAR.GAS_CYLINDER_EQUIPMENT' },
                ]
            }),
        ];

        // init items INDEXES
        AdvancedSearch.INDEXES = SearchForm.getIndexes(this.items);

        // init groupedItems
        this.groupedItems = [
            {
                title: 'ADD_CAR.INTERIOR',
                fields: [
                    new DynamicField({
                        type: 'select',
                        label: 'ADD_CAR.SALON',
                        placeholder: 'CAR.ANY',
                        wrapperClass: 'dynamic-input width-3',
                        formControlName: 'interior_type',
                        source: this.carLocalize.interior_type
                    }),
                    new DynamicField({
                        type: 'select',
                        label: 'ADD_CAR.SALON_COLOR',
                        placeholder: 'CAR.ANY',
                        wrapperClass: 'dynamic-input width-3',
                        formControlName: 'interior_flavor',
                        source: this.carLocalize.interior_flavor
                    }),
                    new DynamicField({
                        type: 'select',
                        label: 'ADD_CAR.SEAT_NUMBER',
                        placeholder: 'CAR.ANY',
                        wrapperClass: 'dynamic-input width-3',
                        formControlName: 'seat_count',
                        source: this.carLocalize.seat_count
                    }),
                    new DynamicField({
                        type: 'select',
                        label: 'ADD_CAR.DRIVER_SEAUPLOADT_CONFIG',
                        placeholder: 'CAR.ANY',
                        wrapperClass: 'dynamic-input width-3',
                        formControlName: 'driver_seat_regulator',
                        source: this.carLocalize.driver_seat_regulator
                    }),
                    new DynamicField({
                        type: 'select',
                        label: 'ADD_CAR.PASSENGER_SEAT_CONFIG',
                        placeholder: 'CAR.ANY',
                        wrapperClass: 'dynamic-input width-3',
                        formControlName: 'passenger_seat_regulator',
                        source: this.carLocalize.passenger_seat_regulator
                    }),
                    new DynamicField({
                        type: 'select',
                        label: 'ADD_CAR.DRIVE_CONFIG',
                        placeholder: 'CAR.ANY',
                        wrapperClass: 'dynamic-input width-3',
                        formControlName: 'helm_regulator',
                        source: this.carLocalize.helm_regulator
                    }),
                    new DynamicField({
                        type: 'switcher',
                        wrapperClass: 'dynamic-input width-3 pt-2',
                        formControlName: ['heated_seal',  'heated_helm'],
                        source: [
                            { name: 'ADD_CAR.HEATED_SEAL' },
                            { name: 'ADD_CAR.HEATED_HELM' },
                        ]
                    }),
                    new DynamicField({
                        type: 'switcher',
                        wrapperClass: 'dynamic-input width-3 pt-2',
                        formControlName: ['tined_windows',  'sunroof'],
                        source: [
                            { name: 'ADD_CAR.TINED_WINDOW' },
                            { name: 'ADD_CAR.SUNROOF' },
                        ]
                    }),
                ]
            },
            {
                title: 'ADD_CAR.SECURITY',
                fields: [
                    new DynamicField({
                        type: 'select',
                        label: 'ADD_CAR.SAFETY_PADS',
                        placeholder: 'CAR.ANY',
                        wrapperClass: 'dynamic-input width-3',
                        formControlName: 'airbag',
                        source: this.carLocalize.airbag
                    }),
                    new DynamicField({
                        type: 'switcher',
                        wrapperClass: 'dynamic-input dynamic-input-align-center width-3 pt-2',
                        formControlName: ['central_lock',  'security_alarm'],
                        source: [
                            { name: 'ADD_CAR.CENTRAL_LOCK' },
                            { name: 'ADD_CAR.SECURITY_ALARM' },
                        ]
                    }),
                    new DynamicField({
                        type: 'switcher',
                        wrapperClass: 'dynamic-input dynamic-input-align-center width-3 pt-2',
                        formControlName: ['abs',  'esp'],
                        source: [
                            { name: 'ADD_CAR.ABS' },
                            { name: 'ADD_CAR.ESP' },
                        ]
                    }),
                ]
            },
            {
                title: 'ADD_CAR.RELATIONS',
                fields: [
                    new DynamicField({
                        type: 'select',
                        label: 'ADD_CAR.CLIMATE_CONTROL',
                        placeholder: 'CAR.ANY',
                        wrapperClass: 'dynamic-input width-3',
                        formControlName: 'climate_control',
                        source: this.carLocalize.climate_control
                    }),
                    new DynamicField({
                        type: 'switcher',
                        wrapperClass: 'dynamic-input width-3 pt-2',
                        formControlName: ['cruise_control', 'parktronic', 'anti_sleep'],
                        source: [
                            { name: 'ADD_CAR.CRUISE_CONTROL' },
                            { name: 'ADD_CAR.PARKTRONIC' },
                            { name: 'ADD_CAR.ANTI_SLEEP' },
                        ]
                    }),
                    new DynamicField({
                        type: 'switcher',
                        wrapperClass: 'dynamic-input width-3 pt-2',
                        formControlName: ['rain_sensor', 'headlight_washer', 'xenon_headlights'],
                        source: [
                            { name: 'ADD_CAR.RAIN_SENSOR' },
                            { name: 'ADD_CAR.HEADLIGHT_WASHER' },
                            { name: 'ADD_CAR.XENON_HEADLIGHTS' },
                        ]
                    }),
                    new DynamicField({
                        type: 'switcher',
                        wrapperClass: 'dynamic-input width-3 pt-2',
                        formControlName: 'board_computer',
                        source: [
                            { name: 'ADD_CAR.BOARD_COMPUTER' },
                        ]
                    }),
                ]
            },
            {
                title: 'ADD_CAR.MULTIMEDIA',
                fields: [
                    new DynamicField({
                        type: 'switcher',
                        wrapperClass: 'dynamic-input width-3 pt-1',
                        formControlName: 'navigation',
                        source: [
                            { name: 'ADD_CAR.NAVIGATION' },
                        ]
                    }),
                    new DynamicField({
                        type: 'switcher',
                        wrapperClass: 'dynamic-input width-3 pt-1',
                        formControlName: 'cd',
                        source: [
                            { name: 'ADD_CAR.CD' },
                        ]
                    }),
                    new DynamicField({
                        type: 'switcher',
                        wrapperClass: 'dynamic-input width-3 pt-1',
                        formControlName: 'tv',
                        source: [
                            { name: 'ADD_CAR.TV' },
                        ]
                    }),
                ]
            },
            {
                title: 'ADD_CAR.OVERVIEW',
                fields: [
                    new DynamicField({
                        type: 'select',
                        label: 'ADD_CAR.GLASS_CONTROL',
                        placeholder: 'CAR.ANY',
                        wrapperClass: 'dynamic-input width-3',
                        formControlName: 'window_regulator',
                        source: this.carLocalize.window_regulator
                    }),
                    new DynamicField({
                        type: 'switcher',
                        wrapperClass: 'dynamic-input dynamic-input-align-center width-3 pt-2',
                        formControlName: 'electric_mirrors',
                        source: [
                            { name: 'ADD_CAR.ELECTRIC_MIRRORS' },
                        ]
                    }),
                    new DynamicField({
                        type: 'switcher',
                        wrapperClass: 'dynamic-input dynamic-input-align-center width-3 pt-2',
                        formControlName: 'heated_mirrors',
                        source: [
                            { name: 'ADD_CAR.HEATED_MIRRORS' },
                        ]
                    }),
                ]
            },
            {
                title: 'ADD_CAR.FOREIGN_FEATURES',
                fields: [
                    new DynamicField({
                        type: 'switcher',
                        wrapperClass: 'dynamic-input width-3 pt-1',
                        formControlName: 'alloy_wheels',
                        source: [
                            { name: 'ADD_CAR.ALLOY_WHEELS' },
                        ]
                    }),
                ]
            }
        ];
        // init form
        this.form = this.dynamicForm.createForm(
            this.joinArrays(
                this.items,
                this.groupedItems.map(item => item.fields)
            )
        );
    }

    initCountries() {
        this.carService.getCountries<Country>().subscribe(res => {
            this.items[AdvancedSearch.INDEXES.COUNTRY].source = res.results;
            this.items[AdvancedSearch.INDEXES.COUNTRY].isLoading = false;
        });
    }

    initRegions = (id: Country['id']) => {
        this.items[AdvancedSearch.INDEXES.REGION].isLoading = true;
        this.carService.getRegions<Region>(id).subscribe(res => {
            this.items[AdvancedSearch.INDEXES.REGION].source = res.results;
            this.items[AdvancedSearch.INDEXES.REGION].isLoading = false;
            this.items[AdvancedSearch.INDEXES.REGION].disabled = !res.results;
        });
    }

    onCountrySelect = async (value = null) => {
        const country = this.form.get('country');
        const region = this.form.get('region');
        if (region.value) {
            region.setValue(null);
        }
        this.items[AdvancedSearch.INDEXES.REGION].source = [];
        if (country.value) {
            this.initRegions(country.value);
        }
    }

}
