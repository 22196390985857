import { Component, OnInit } from '@angular/core';
import { CarVehicleTypeService } from '@shared/providers/search/car-vehicle-type.service';

@Component({
  selector: 'app-vehicle-type',
  templateUrl: './vehicle-type.component.html'
})
export class VehicleTypeComponent implements OnInit {

  constructor(
      public vehicleType: CarVehicleTypeService
  ) {}

  ngOnInit(): void {
    this.vehicleType.initFunctions();
  }
}
