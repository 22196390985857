import { Injectable } from '@angular/core';
import { AdvancedSearch } from '@shared/helpers/search/advanced-search-form';
import { CarService } from '@shared/providers/car/car.service';
import { CarLocalizeService } from '@shared/providers/car/car-localize.service';
import { DynamicFormCreateService } from '../../../modules/dynamic-fields/providers/dynamic-form-create.service';

@Injectable({
  providedIn: 'root'
})
export class CarAdvancedSearchService extends AdvancedSearch {

  constructor(
      public readonly dynamicForm: DynamicFormCreateService,
      public readonly carService: CarService,
      public readonly carLocalize: CarLocalizeService
  ) {
    super(dynamicForm, carService, carLocalize);
  }
}
