import { Injectable } from '@angular/core';
import { ACCOUNT } from '@shared/constants/api-endpoints';
import { BaseAPIService } from '@shared/providers/base-api.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@shared/providers/auth/auth.service';
import { EMPTY, Observable } from 'rxjs';
import { User } from '@shared/interfaces/user.interface';
import { ChangePassword } from '@shared/interfaces/account/change-password.interface';
import { catchError } from 'rxjs/operators';
import { RESPONSE_CODE } from '@shared/constants/api-response-codes';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseAPIService {

  private accountAvatar = '';

  constructor(
      protected httpClient: HttpClient,
      private authService: AuthService
  ) {
    super(httpClient);
  }

  setAvatar(res: Partial<User>) {
    this.accountAvatar = `${res.first_name ? res.first_name.slice(0, 1) : ''}${res.last_name ? res.last_name.slice(0, 1) : ''}`;
  }

  get avatar() {
    return this.accountAvatar;
  }

  getAccountInfo<T>(id: User['id']): Observable<T | any> {
    return this.getById(`${ACCOUNT.USERS}`, id).pipe(
        catchError(err => this.errorHandler(err))
    );
  }

  getMyCars<T>(query: any): Observable<T> {
    return this.getList(ACCOUNT.MY_CARS, query);
  }

  getFavourites<T>(query: any): Observable<T> {
    return this.getList(ACCOUNT.FAVOURITES, query);
  }

  addToFavourites(body: { advert: any }) {
    return this.post(ACCOUNT.FAVOURITES_ADD, body);
  }

  removeFromFavourites(id: any) {
    return this.delete(`${ACCOUNT.FAVOURITES}${id}`);
  }

  manageAccountInfo(body: Partial<User>) {
    return this.patch(ACCOUNT.MANAGE_ACCOUNT_INFO.replace('{id}', this.authService.userId), body);
  }

  changePassword(body: ChangePassword) {
    return this.post(ACCOUNT.CHANGE_PASSWORD_URL, body);
  }

  protected errorHandler(error) {
    if (this.authService.isLoggedIn) {
      this.authService.logOut(true);
    }
    return EMPTY;
  }

}
