import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Car } from '@shared/interfaces/car/car.interface';
import { getItem, setItem } from '@helpers/local-storage';
import { CarSearchConstant } from '@shared/constants/car/car-search.constant';
import { ResponseModel } from '@shared/interfaces/response-model.interface';
import { CarService } from '@shared/providers/car/car.service';
import { RouterLoaderService } from '@shared/providers/router/router-loader.service';
import { SnackbarService } from '@shared/snackbar/snackbar.service';
import { FULL_ROUTES } from '../../routes';
import { SearchForm } from '@shared/helpers/search/search-form';
import { PagePreloaderComponent } from '../../layout/page-preloader/page-preloader.component';

@Component({
  selector: 'app-search-car',
  templateUrl: './search-car.component.html'
})
export class SearchCarComponent implements OnInit, OnDestroy {

  private readonly fullRoutes = FULL_ROUTES;
  private $subscriptions: Subscription = new Subscription();
  public viewMode = false;
  public showFilters = false;
  public car: ResponseModel<Car>;
  public limit;
  public defPage = CarSearchConstant.PAGE.VALUE;
  public page = CarSearchConstant.PAGE.VALUE;
  public lastValue;

  constructor(
      private readonly activatedRoute: ActivatedRoute,
      private readonly router: Router,
      private readonly carService: CarService,
      private readonly routerLoaderService: RouterLoaderService,
      private readonly snackbarService: SnackbarService,
  ) {
    this.$subscriptions.add(
        this.activatedRoute.queryParams.subscribe(queryParams => {
          this.limit = +queryParams[CarSearchConstant.LIMIT.KEY] || +getItem(CarSearchConstant.LIMIT.KEY, CarSearchConstant.LIMIT.VALUE);
          this.page = Math.ceil((+queryParams[CarSearchConstant.OFFSET.KEY] || CarSearchConstant.OFFSET.VALUE) / this.limit) + 1;
        })
    );
  }

  bodyCoverRemove() {
    this.showFilters = !this.showFilters;
    const body = document.getElementById('body');
    body.classList.remove('body-covered');
  }


  ngOnInit() {
    this.viewMode = getItem(CarSearchConstant.LISTED.KEY, CarSearchConstant.LISTED.VALUE) !== CarSearchConstant.LISTED.VALUE;
    this.$subscriptions.add(
        this.activatedRoute.data.subscribe((data: any) => {
          if (data.cars) {
            this.car = data.cars;
          }
        })
    );
  }

  getCars(params: Params, page = this.page) {
    if (params && JSON.stringify(params) === JSON.stringify(this.lastValue)) {
      return;
    }
    this.routerLoaderService.$loader.next(true);
    const queryParams = SearchForm.getQueryParams({
      ...params || this.activatedRoute.snapshot.queryParams,
      [CarSearchConstant.OFFSET.KEY] : (page - 1) * this.limit
    }, false);

    this.$subscriptions.add(
        this.carService.getCars<ResponseModel<Car>>(queryParams).subscribe(result => {
           this.car = result;
           this.page = page;
           this.router.navigate([`/${this.fullRoutes.SEARCH_CAR}`], {
             queryParams,
             relativeTo: this.activatedRoute
           }).finally(() => {
             PagePreloaderComponent.isGoToTopEnabled = true;
             this.routerLoaderService.$stopLoader.next();
           });
        }, error => this.snackbarService.handleErrorsAndOpen(error.error.detail, error.error.errors))
    );
    this.lastValue = params;
  }

  changeViewMode(event) {
    this.viewMode = !this.viewMode;
    setItem(CarSearchConstant.LISTED.KEY, this.viewMode);
  }

  ngOnDestroy(): void {
    this.$subscriptions.unsubscribe();
  }
}
