import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicComponent } from '../../classes/DynamicComponent';
import { Color } from '@shared/interfaces/car/color.inteface';
import { CarService } from '@shared/providers/car/car.service';
import { Subscription } from 'rxjs';
import { DYNAMIC_FIELDS_DEF_VALUES } from '../../constants/dynamic-fields-default-values';

@Component({
  selector: 'app-dynamic-color',
  templateUrl: './dynamic-color.component.html'
})
export class DynamicColorComponent extends DynamicComponent implements OnInit, OnDestroy {
  private $subscriptions: Subscription = new Subscription();

  constructor(
      private readonly carService: CarService
  ) {
    super();
  }

  ngOnInit() {
    if (!this.field.source) {
      this.$subscriptions.add(
          this.carService.getColors<Color>().subscribe(res => {
            this.field.source = res.results;
            this.field.loading = false;
          })
      );
    }
  }

  chooseColor(id: Color['id']) {
    const value = this.fieldValue === id ? DYNAMIC_FIELDS_DEF_VALUES.color : id;
    this.group.patchValue({
      [this.field.toControlNameString]: value
    });
  }

  isLightColor(color) {
    if (!color) {
      return true;
    }
    let r;
    let g;
    let b;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +('0x' + color.slice(1).replace(
              color.length < 5 && /./g, '$&$&'
          )
      );
      // tslint:disable-next-line:no-bitwise
      r = color >> 16;
      // tslint:disable-next-line:no-bitwise
      g = color >> 8 & 255;
      // tslint:disable-next-line:no-bitwise
      b = color & 255;
    }
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );
    // Using the HSP value, determine whether the color is light or dark
    return hsp > 127.5;
  }

  ngOnDestroy() {
    this.$subscriptions.unsubscribe();
  }

}
