import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { getItem } from '@helpers/local-storage';
import { AppConstants, LanguagesList } from '@shared/constants/app-constants';

@Injectable({
  providedIn: 'root'
})
export class AppLanguageService {


  public static readonly langList = LanguagesList;
  public static API_LANGUAGE = environment.defaultLang;

  constructor(public readonly translator: TranslateService) {
    const lang = this.detectChanges() || getItem(AppConstants.LANGUAGE_KEY, environment.defaultLang);
    this.defineLanguage(lang);
  }

  private detectChanges() {
    const lang = window.location.pathname.slice(1);
    return AppLanguageService.langList[lang] ? lang : null;
  }

  public defineLanguage(lang: string): void {
    if (!lang) return;

    const isExist = AppLanguageService.langList[lang];
    if (!isExist) return;

    this.translator.use(lang);
    this.setLocalLanguage(lang);
  }

  private setLocalLanguage(lang: string): void {
    localStorage.setItem(AppConstants.LANGUAGE_KEY, lang);
    AppLanguageService.API_LANGUAGE = AppLanguageService.langList[lang];
  }
}
