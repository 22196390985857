import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Car } from '@shared/interfaces/car/car.interface';
import { catchError } from 'rxjs/operators';
import { CarService } from '@shared/providers/car/car.service';
import { AuthService } from '@shared/providers/auth/auth.service';
import { SnackbarService } from '@shared/snackbar/snackbar.service';
import { ResponseModel } from '@shared/interfaces/response-model.interface';
import { ResolveService } from '@shared/providers/car/resolve-service';

@Injectable({
  providedIn: 'root'
})
export class SearchResolveService extends ResolveService implements Resolve<any> {

  constructor(
      private readonly carService: CarService,
      public readonly router: Router,
      public readonly authService: AuthService,
      public readonly snackbarService: SnackbarService
  ) {
    super(router , authService , snackbarService);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    return this.carService.getCars<ResponseModel<Car>>(route.queryParams).pipe(
        catchError(err => this.errorHandler(err))
    );
  }

}
