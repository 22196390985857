import { Injectable } from '@angular/core';
import { CarService } from '@shared/providers/car/car.service';
import { DynamicFormCreateService } from '../../../modules/dynamic-fields/providers/dynamic-form-create.service';
import { CarLocalizeService } from '@shared/providers/car/car-localize.service';
import { CarFullSearch } from '@shared/helpers/search/car-full-search-form';
import { CarAdvancedSearchService } from '@shared/providers/search/car-advanced-search.service';
import { CarFastSearchService } from '@shared/providers/search/car-fast-search.service';
import { CarVehicleTypeService } from '@shared/providers/search/car-vehicle-type.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CarFullSearchService extends CarFullSearch {

  constructor(
      public dynamicForm: DynamicFormCreateService,
      public carService: CarService,
      public carLocalize: CarLocalizeService,
      public advancedSearch: CarAdvancedSearchService,
      public fastSearch: CarFastSearchService,
      public vehicleType: CarVehicleTypeService,
      public activatedRoute: ActivatedRoute
  ) {
    super(
        dynamicForm,
        advancedSearch,
        fastSearch,
        vehicleType,
        activatedRoute
    );
  }
}
