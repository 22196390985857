<div class="vehicle-list">
    <ngx-slick-carousel *ngIf="!loading"
                        class="slick-carousel"
                        [config]="slideConfig">
        <ng-container *ngFor="let item of carouselItems" class="flex">
            <app-car-item ngxSlickItem [car]="item"></app-car-item>
        </ng-container>
    </ngx-slick-carousel>
    <span *ngIf="loading">{{ 'SHARED.LOADING_TEXT' | translate }}</span>
    <span *ngIf="!loading && !carouselItems?.length">{{ 'SHARED.NOT_FOUND_MSG' | translate }}</span>
</div>
