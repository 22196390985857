import { Input, OnChanges, SimpleChanges } from '@angular/core';
import { Information } from '@shared/interfaces/car/information.interface';
import { CarLocalizeService } from '@shared/providers/car/car-localize.service';

export abstract class CarAdditionalFields implements OnChanges {
    @Input() information: Information;
    public additionalInformation: Array<{label?: string, fields: any}> = [];
    protected constructor(
        public carLocalize: CarLocalizeService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.information) {
            this.additionalInformation = [{
                label: 'ADD_CAR.INTERIOR',
                fields: [
                    {
                        label: 'ADD_CAR.SALON',
                        value: this.carLocalize.fromArray(this.information.interior_type, 'interior_type')
                    },
                    {
                        label: 'ADD_CAR.SALON_COLOR',
                        value: this.carLocalize.fromArray(this.information.interior_flavor, 'interior_flavor')
                    },
                    {
                        label: 'ADD_CAR.SEAT_NUMBER',
                        value: this.carLocalize.fromArray(this.information.seat_count, 'seat_count')
                    },
                    {
                        label: 'ADD_CAR.DRIVER_SEAUPLOADT_CONFIG',
                        value: this.carLocalize.fromArray(this.information.driver_seat_regulator, 'driver_seat_regulator')
                    },
                    {
                        label: 'ADD_CAR.PASSENGER_SEAT_CONFIG',
                        value: this.carLocalize.fromArray(this.information.passenger_seat_regulator, 'passenger_seat_regulator')
                    },
                    {
                        label: 'ADD_CAR.DRIVE_CONFIG',
                        value: this.carLocalize.fromArray(this.information.helm_regulator, 'helm_regulator')
                    },
                    {
                        value: this.information.heated_seal && 'ADD_CAR.HEATED_SEAL'
                    },
                    {
                        value: this.information.heated_helm && 'ADD_CAR.HEATED_HELM'
                    },
                    {
                        value: this.information.sunroof && 'ADD_CAR.SUNROOF'
                    },
                    {
                        value: this.information.tined_windows && 'ADD_CAR.TINED_WINDOW'
                    }
                ].filter(item => item.value && item.value !== '-')
            }, {
                label: 'ADD_CAR.SECURITY',
                fields: [
                    {
                        label: 'ADD_CAR.SAFETY_PADS',
                        value: this.carLocalize.fromArray(this.information.airbag, 'airbag')
                    },
                    {
                        value: this.information.central_lock && 'ADD_CAR.CENTRAL_LOCK'
                    },
                    {
                        value: this.information.security_alarm && 'ADD_CAR.SECURITY_ALARM'
                    },
                    {
                        value: this.information.abs && 'ADD_CAR.ABS'
                    },
                    {
                        value: this.information.esp && 'ADD_CAR.ESP'
                    }
                ].filter(item => item.value && item.value !== '-')
            }, {
                label: 'ADD_CAR.RELATIONS',
                fields: [
                    {   label: 'ADD_CAR.CLIMATE_CONTROL',
                        value: this.carLocalize.fromArray(this.information.climate_control, 'climate_control')
                    },
                    {
                        value: this.information.board_computer && 'ADD_CAR.BOARD_COMPUTER'
                    },
                    {
                        value: this.information.cruise_control && 'ADD_CAR.CRUISE_CONTROL'
                    },
                    {
                        value: this.information.parktronic && 'ADD_CAR.PARKTRONIC'
                    },
                    {
                        value: this.information.anti_sleep && 'ADD_CAR.ANTI_SLEEP'
                    },
                    {
                        value: this.information.rain_sensor && 'ADD_CAR.RAIN_SENSOR'
                    },
                    {
                        value: this.information.headlight_washer && 'ADD_CAR.HEADLIGHT_WASHER'
                    },
                    {
                        value: this.information.xenon_headlights && 'ADD_CAR.XENON_HEADLIGHTS'
                    }
                ].filter(item => item.value && item.value !== '-')
            }, {
                label: 'ADD_CAR.MULTIMEDIA',
                fields: [
                    {
                        value: this.information.navigation && 'ADD_CAR.NAVIGATION'
                    },
                    {
                        value: this.information.cd && 'ADD_CAR.CD'
                    },
                    {
                        value: this.information.tv && 'ADD_CAR.TV'
                    }
                ].filter(item => item.value && item.value !== '-')
            }, {
                label: 'ADD_CAR.OVERVIEW',
                fields: [
                    {
                        label: 'ADD_CAR.GLASS_CONTROL',
                        value: this.carLocalize.fromArray(this.information.window_regulator, 'window_regulator')
                    },
                    {
                        value: this.information.electric_mirrors && 'ADD_CAR.ELECTRIC_MIRRORS'
                    },
                    {
                        value: this.information.heated_mirrors && 'ADD_CAR.HEATED_MIRRORS'
                    }
                ].filter(item => item.value && item.value !== '-')
            }, {
                label: 'ADD_CAR.FOREIGN_FEATURES',
                fields: [
                    {
                        value: this.information.alloy_wheels && 'ADD_CAR.ALLOY_WHEELS'
                    }
                ].filter(item => item.value && item.value !== '-')
            }].filter(item => item.fields.length);
        }
    }
}
