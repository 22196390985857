import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { CarItemComponent } from './car-item.component';

@NgModule({
    declarations: [
        CarItemComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule.forChild(),
        NgxSmartModalModule,
    ],
    exports: [
        CarItemComponent
    ]
})
export class CarItemModule { }
