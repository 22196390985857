<div [ngClass]="[field.fieldClass]" [formGroup]="group" class="text-field">
    <p class="form-label"
       [ngClass]="[field.theme]"
       [class.invalid]="isTouchedAndInvalid(getFormControlName(0))">
        {{field.label | translate }}
    </p>
    <div class="flex align-center text-field-select">
        <div class="text-field mb-0"
             [ngClass]="[field.theme]"
             [class.disabled]="!!field.disabled"
             [class.invalid]="isTouchedAndInvalid(getFormControlName(0))">
            <input [type]="field.inputType"
                   [pattern]="field.pattern"
                   [mask]="field.mask"
                   [specialCharacters]="field.specialCharacters"
                   [formControlName]="getFormControlName(0)"
                   [name]="getFormControlName(0)"
                   [placeholder]="(field.placeholder | translate) || ''">
        </div>
        <div class="select-box mb-0"
             [ngClass]="[field.theme, field.fieldClass, this.getUniqueClass(1)]"
             [class.disabled]="!!field.disabled"
             [class.invalid]="isTouchedAndInvalid(getFormControlName(1))"
            >
            <ng-select
                    [formControlName]="getFormControlName(1)"
                    [clearable]="!field.notClearable"
                    [searchable]="false"
                    [keyDownFn]="keyPress"
                    [items]="field.source"
                    bindLabel="name"
                    bindValue="id"
            >
                <ng-template ng-option-tmp let-item="item">
                    {{ item.name | translate }}
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    {{ item.name | translate }}
                </ng-template>
            </ng-select>
        </div>
    </div>

    <app-print-field-errors *ngIf="field.printErrors && isTouchedAndInvalid(getFormControlName(0))"
                            [field]="field"
                            [errors]="group.get(getFormControlName(0)).errors">
    </app-print-field-errors>
</div>