import { Validators } from '@angular/forms';
import { Form } from '@shared/helpers/forms/form';
import { DynamicFormCreateService } from '../../../modules/dynamic-fields/providers/dynamic-form-create.service';
import { DynamicField } from '../../../modules/dynamic-fields/classes/DynamicField';
import { CarFieldsValidation } from '@shared/constants/validation/validation.constant';
import { CarLocalizeService } from '@shared/providers/car/car-localize.service';
import { supportedSitesToAddCarFromValidator } from '@shared/validators/supported-websites-validator';
import { numberMask } from '@shared/utils/mask';
import { CarSearchConstant } from '@shared/constants/car/car-search.constant';

export abstract class CarFromAnotherWebsite extends Form {

    protected constructor(
        protected dynamicForm: DynamicFormCreateService,
        protected carLocalize: CarLocalizeService
    ) {
        super();
        this.formConfigs();
    }

    formConfigs(): void {
        // init items
        this.items = [
            new DynamicField({
                type: 'input',
                inputType: 'text',
                label: 'ADD_CAR.FROM_OTHER_SITE.URL',
                wrapperClass: 'dynamic-input width-1',
                formControlName: 'url',
                printErrors: true,
                validation: [
                    Validators.required,
                    supportedSitesToAddCarFromValidator
                ]
            }),
            new DynamicField({
                type: 'inputWithSelect',
                label: 'ADD_CAR.PRICE',
                placeholder: 'ADD_CAR.PRICE',
                source: this.carLocalize.toArray('currency'),
                wrapperClass: 'dynamic-input width-1',
                formControlName: ['price', 'currency'],
                printErrors: true,
                notClearable: true,
                mask: numberMask(CarFieldsValidation.MAX_PRICE),
                validation: [
                    Validators.min(CarFieldsValidation.MIN_PRICE),
                    Validators.max(CarFieldsValidation.MAX_PRICE),
                    Validators.required,
                ]
            })
        ];
        // init form
        this.form = this.dynamicForm.createForm(this.items);
    }

    resetForm() {
        this.form.reset({
            currency: CarSearchConstant.CURRENCY.VALUE
        });
    }
}
