import { Component, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

// Helpers
import { AppLanguageService } from '@shared/providers/language/app-language.service';
import { SeoService } from '@shared/providers/seo/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnDestroy {

  constructor(
      private readonly translate: TranslateService,
      private readonly appLanguageService: AppLanguageService,
      private readonly seoService: SeoService,
      @Inject(PLATFORM_ID) private readonly platformId: object,
  ) {
    this.setPrototypes();
  }

  private subscription: Subscription;


  private setPrototypes(): void {
    // @ts-ignore
    Array.prototype.diff = function(a) {
      return this.filter( (i) => {
        return a.indexOf(i) === -1;
      });
    };
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
