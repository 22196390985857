import { Car } from '@shared/interfaces/car/car.interface';
import { Params } from '@angular/router';
import { CarSearchConstant } from '@shared/constants/car/car-search.constant';
import { Official } from '@shared/interfaces/officials/official.interface';

function urlFragment(url) {
	return url.replace(/"/g, '').replace(/'/g, '').replace(/[^a-zA-Z0-9-]/g, '-');
}

export function carSlug(car: Car) {
	return  urlFragment(
		(car.car_model && car.car_model.brand ? car.car_model.brand.name + '-' : '')
		+ (car.car_model ? car.car_model.name + '-' : '')
		+ (car.generation && car.generation.name ? car.generation.name + '-' : '')
		+ (car.production_date)
	);
}

export function officialSlug(official: Official) {
	return urlFragment(
		(official.dealership ? official.dealership.name + '-' : '')
		+ (official.brand ? official.brand.name : '')
	);
}

export function getPageFromQueryParams(queryParams: Params) {
	let page;
	if (!queryParams.hasOwnProperty('page') || (typeof +queryParams.page) === 'number') {
		page = +queryParams.page;
	}
	return page || CarSearchConstant.PAGE.VALUE;
}

export function getHostnameFromUrl(url: string): string {
	let hostname;
	// remove protocol
	if (url.indexOf('//') > -1) {
		hostname = url.split('/')[2];
	} else {
		hostname = url.split('/')[0];
	}
	if (url.indexOf('www.') > -0) {
		hostname = url.split('www.')[1];
	}
	// remove port
	hostname = hostname.split(':')[0];
	// remove query
	hostname = hostname.split('?')[0];
	hostname = hostname.split('/')[0];
	return hostname;
}

export function getDateDifferenceInMinutes(start: Date, end: Date): number {
	const difference = end.getTime() - start.getTime();
	return Math.round(difference / 60000);
}