import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from '@shared/providers/auth/auth.service';
import { getItem } from '@helpers/local-storage';
import { environment } from '@environments/environment';
import { AppConstants } from '@shared/constants/app-constants';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { RESPONSE_CODE } from '@shared/constants/api-response-codes';
import { AUTH, OFFICIALS } from '@shared/constants/api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class TokenAndLangInterceptorService implements HttpInterceptor {

  exceptRoutes = [
      AUTH.REFRESH_TOKEN,
      AUTH.REGISTER,
      AUTH.LOGIN,
      OFFICIALS.REGISTER
  ];

  constructor(
      private readonly authService: AuthService
  ) {}

    private refreshTokenInProgress = false;
    // Refresh Token Subject tracks the current token, or is null if no token is currently
    // available (e.g. refresh pending).
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      request = this.requestWithHeader(request);
      return next.handle(request).pipe(catchError(error => {
          // We don't want to refresh token for some requests like login or refresh token itself
          // So we verify url and we throw an error if it's the case
          if (this.ifInExceptRoutes(request.url)) {
              // We do another check to see if refresh token failed
              // In this case we want to logout user and to redirect it to login page
              this.refreshTokenInProgress = false;
              if (this.ifRefreshUrl(request.url)) {
                  this.authService.logOut(true);
              }
              return throwError(error);
          }

          // If error status is different than 401 we want to skip refresh token
          // So we check that and throw the error if it's the case
          if (error.status !== RESPONSE_CODE.UNAUTHORIZED) {
              return throwError(error);
          }

          if (this.refreshTokenInProgress) {
              // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
              // – which means the new token is ready and we can retry the request again
              return this.refreshTokenSubject.pipe(
                  filter(result => result !== null),
                  take(1),
                  switchMap(() => next.handle(this.requestWithHeader(request)))
              );
          } else {
              this.refreshTokenInProgress = true;

              // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
              this.refreshTokenSubject.next(null);

              // Call auth.refreshAccessToken(this is an Observable that will be returned)
              return this.authService
                  .refreshExpiredToken().pipe(
                      switchMap((token: any) => {
                          // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                          // for the next time the token needs to be refreshed
                          this.refreshTokenInProgress = false;
                          this.refreshTokenSubject.next(token);

                          return next.handle(this.requestWithHeader(request));
                      }),
                      catchError(err => {
                          this.refreshTokenInProgress = false;
                          if (err.status === RESPONSE_CODE.UNAUTHORIZED) {
                              this.authService.logOut(true);
                          }
                          return throwError(err);
                      })
                  );
          }
      }));
  }

  private requestWithHeader(request: HttpRequest<any>) {
    const token = this.authService.token;
    const lang  = getItem(AppConstants.LANGUAGE_KEY, environment.defaultLang);
    return request.clone({
      setHeaders: {
        'Accept-Language': lang,
        ...!this.ifInExceptRoutes(request.url) && token && { Authorization: `Bearer ${token}` }
      }
    });
  }

  ifInExceptRoutes(url) {
        return this.exceptRoutes.findIndex(
            item => url.includes(item)
        ) > -1;
  }

  ifRefreshUrl(url) {
      return url.includes(AUTH.REFRESH_TOKEN);
  }

}
