export abstract class SliderConfigs {

    slideImagesBigConfig = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '.carousel-small',
        prevArrow: `
          <button type="button" class="slick-arrow big-arrow big-prev-arrow">
            <i class="icon-left"></i>
          </button>`,
        nextArrow: `
          <button type="button" class="slick-arrow big-arrow big-next-arrow">
            <i class="icon-right"></i>
          </button>`
    };

    slideImagesSmallConfig = {
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        speed: 600,
        asNavFor: '.carousel-big',
        focusOnSelect: true,
        draggable: false,
        centerPadding: '0',
        // centerMode: true,
        prevArrow: `
          <button type="button" class="slick-arrow prev-arrow">
            <i class="icon-left"></i>
          </button>`,
        nextArrow: `
          <button type="button" class="slick-arrow next-arrow">
            <i class="icon-right"></i>
          </button>`
    };

    slideImagesBigModalConfig = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        asNavFor: '.carousel-small-modal',
        prevArrow: `
          <button type="button" class="slick-arrow prev-arrow">
            <i class="icon-left"></i>
          </button>`,
        nextArrow: `
          <button type="button" class="slick-arrow next-arrow">
            <i class="icon-right"></i>
          </button>`

    };

    slideImagesSmallModalConfig = {
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        speed: 600,
        asNavFor: '.carousel-big-modal',
        focusOnSelect: true,
        draggable: false,
        centerPadding: '0',
        // centerMode: true,
        prevArrow: `
          <button type="button" class="slick-arrow prev-arrow">
            <i class="icon-left"></i>
          </button>`,
        nextArrow: `
          <button type="button" class="slick-arrow next-arrow">
            <i class="icon-right"></i>
          </button>`,
        responsive: [
            {
                breakpoint: 1376,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 6,
                }
            }
        ]
    };
}
