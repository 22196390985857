<div class="filters-header">
    <button class="btn link color-primary flex align-center mx-auto" (click)="resetForm()">
        <i class="icon-clear mr-2 fs-xs"></i>
        {{ 'FILTER.RESET' | translate }}
    </button>
    <button class="btn filled primary btn-results" (click)="emitShowFilterChange()">
        {{ 'FILTER.FILTER_VEHICLES' | translate }}
    </button>
</div>
<ul class="filter-list">
    <li *ngFor="let group of carFullSearch.groupedItems; let i = index">
        <div class="filter-group" [ngClass]="{'active': !expandedItem[i]}">
            <button class="btn-unstyled btn-expand fs-md color-black w-100 fw-600"
                    (click)="expandItem(i)">
                {{ group.title | translate }}
                <i class="icon-arrow-down"></i>
            </button>
        </div>
        <div class="group-options" [ngClass]="{'expanded': expandedItem[i]}">
            <app-dynamic-fields
                    class="w-100"
                    [group]="carFullSearch.form"
                    [fields]="group.fields">
            </app-dynamic-fields>
        </div>
    </li>
</ul>