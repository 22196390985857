<section class="main-content search-car">
  <div class="container flex pt-3 pb-10">
    <div class="content-left" [ngClass]="{'show': showFilters}">
      <div class="content card mr-3">
        <app-car-filter
                [showFilters]="showFilters"
                (onShowFilters)="bodyCoverRemove()"
                (onFilterValueChange)="getCars($event, defPage)">
        </app-car-filter>
        <div class="filters-footer align-center justify-between">
          <h2 class="color-secondary h4"> {{ 'FILTER.FILTER' | translate }}</h2>
          <button class="btn-unstyled fs-md" (click)="bodyCoverRemove()"><i class="icon-clear"></i></button>
        </div>
      </div>
    </div>
    <div class="content-right w-100">
      <!-- Filter header content start -->
      <app-search-car-header
              [viewMode]="viewMode"
              [showFilters]="showFilters"
              (onViewModeChange)="changeViewMode($event)"
              (onFilterValueChange)="getCars($event, defPage)"
              (onShowFiltersChange)="showFilters = !showFilters">
      </app-search-car-header>
      <!-- Filter header content end -->

      <ng-container *ngIf="car.count; else noResult">
        <!-- Vehicle list start -->
        <div class="vehicle-list flex flex-wrap" [ngClass]="{'list-view': viewMode}">
          <app-car-item class="item-container" *ngFor="let car of car.results | paginate: { itemsPerPage: limit, currentPage: page, totalItems: car.count }" [car]="car"></app-car-item>
        </div>
        <!-- Vehicle list end -->

        <!-- Paination start -->
        <app-car-pagination (pageChange)="getCars(null, $event)"></app-car-pagination>
        <!-- Paination end -->
      </ng-container>

      <ng-template #noResult>
        <!--no items-->
        <p>{{'USER.NO_ADDED_CARS' | translate}}</p>
        <!--no items-->
      </ng-template>

    </div>
  </div>
</section>
