export class CarSearchConstant {

    static readonly LIMIT = {
        KEY: 'limit',
        VALUE: 12
    };

    static readonly PAGE = {
        KEY: 'page',
        VALUE: 1
    };

    static readonly OFFSET = {
        KEY: 'offset',
        VALUE: 0
    };

    static readonly SORT_BY = {
        KEY: 'posted_time_desc',
        VALUE: 1
    };

    static readonly LISTED = {
        KEY: 'listed',
        VALUE: 'false'
    };

    static readonly CURRENCY = {
        KEY: 'currency',
        VALUE: 'usd'
    };

    static readonly DEFAULT_SORT_PARAM = {
        [CarSearchConstant.SORT_BY.KEY]: CarSearchConstant.SORT_BY.VALUE
    };
}
