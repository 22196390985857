import { DynamicFormCreateService } from '../../../modules/dynamic-fields/providers/dynamic-form-create.service';
import { CarAdvancedSearchService } from '@shared/providers/search/car-advanced-search.service';
import { CarFastSearchService } from '@shared/providers/search/car-fast-search.service';
import { CarVehicleTypeService } from '@shared/providers/search/car-vehicle-type.service';
import { FormGroup } from '@angular/forms';
import { isValidNumber } from '@shared/validators/number.validator';
import { ActivatedRoute } from '@angular/router';
import { bindRequiredField } from '@shared/validators/bind-requried-values';
import { FieldGroup } from '../../../modules/dynamic-fields/interfaces/field-group.interface';
import { CarMainForm } from '@shared/helpers/car-main-form';

export abstract class CarFullSearch extends CarMainForm {

    // items
    groupedItems: FieldGroup[] = [];

    protected constructor(
        protected dynamicForm: DynamicFormCreateService,
        protected advancedSearch: CarAdvancedSearchService,
        protected fastSearch: CarFastSearchService,
        protected vehicleType: CarVehicleTypeService,
        protected activatedRoute: ActivatedRoute
    ) {
        super();
        this.formConfigs();
    }

    formConfigs() {
        const selects = {
            brand: this.fastSearch.initCarModels,
            model: this.fastSearch.initCarGenerations,
            country: this.advancedSearch.initRegions,
        };
        for (const name in selects) {
            if (this.activatedRoute.snapshot.queryParams.hasOwnProperty(name)) {
                selects[name](this.activatedRoute.snapshot.queryParams[name]);
            }
        }
        this.groupedItems = [
            {
                title: 'CAR_DETAIL.DESCRIPTION',
                fields: [
                    ...this.vehicleType.items,
                    ...this.fastSearch.items,
                    ...this.advancedSearch.items
                ]
            },
            ...this.advancedSearch.groupedItems
        ];
        this.form = this.dynamicForm.createForm(this.joinArrays(
            this.groupedItems.map(item => item.fields),
        ), {
            validators: [
                bindRequiredField('model', 'brand'),
                bindRequiredField('generation', 'brand', 'model'),
                bindRequiredField('region', 'country'),
            ]
        });
        this.advancedSearch.initFunctions(this.form);
        this.fastSearch.initFunctions(this.form);
        this.vehicleType.initFunctions(this.form);
        this.initValues();
    }

    initValues() {
        const values = {};
        // init values
        for (const group of this.groupedItems) {
            for (const item of group.fields) {
                const names = item.toControlNameArray;
                for (const name of names) {
                    if (this.activatedRoute.snapshot.queryParams.hasOwnProperty(name)) {
                        let val = this.activatedRoute.snapshot.queryParams[name];
                        if (isValidNumber(val)) {
                            val = parseInt(val, 10);
                        }
                        if (name === 'is_crashed') {
                            val = !!val;
                        }
                        values[name] = val;
                    }
                }
            }
        }
        this.form.patchValue(values);
    }
}
