import { Input, OnChanges, SimpleChanges } from '@angular/core';
import { CarLocalizeService } from '@shared/providers/car/car-localize.service';
import { Car } from '@shared/interfaces/car/car.interface';

export abstract class CarDescriptionFields implements OnChanges {

    @Input() car: Car;
    public description: any;
    protected constructor(
        public readonly carLocalize: CarLocalizeService
    ) {}


    ngOnChanges(changes: SimpleChanges): void {
       if (changes.car) {
           this.description = {
               ...this.car.kilometer && {
                   'ADD_CAR.RUNS': {
                       value: this.car.kilometer,
                       additional: 'CAR.KM'
                   }
               },
               ...this.car.car_type && {
                   'ADD_CAR.BODY_TYPE': {
                       value: this.car.car_type.name
                   }
               },
               ...this.car.cubic_capacity && {
                   'ADD_CAR.CUBIC_CAPACITY' : {
                       value: this.car.cubic_capacity,
                       additional: 'CAR.LITER'
                   }
               },
               ...this.car.power && {
                   'ADD_CAR.POWER': {
                       value: this.car.power,
                       additional: 'CAR.POWER'
                   }
               },
               ...this.car.is_crashed !== null && {
                   'ADD_CAR.DAMAGE': {
                       value: this.carLocalize.fromArray(this.car.is_crashed, 'is_crashed')
                   }
               },
               ...this.car.fuel_type && {
                   'ADD_CAR.FUEL_TYPE': {
                       value: this.carLocalize.get(this.car.fuel_type, 'fuel_type')
                   }
               },
               ...this.car.drive_type && {
                   'ADD_CAR.DRIVE_TYPE': {
                       value: this.carLocalize.get(this.car.drive_type, 'drive_type')
                   }
               },
               ...this.car.drive_wheel_type && {
                   'ADD_CAR.DRIVE': {
                       value: this.carLocalize.get(this.car.drive_wheel_type, 'drive_wheel_type')
                   }
               },
               ...this.car.usage && {
                   'CAR.USAGE.LABEL': {
                       value: this.carLocalize.get(this.car.usage, 'usage')
                   }
               },
               ...this.car.transmission && {
                   'ADD_CAR.TRANSMISSION': {
                       value: this.carLocalize.get(this.car.transmission, 'transmission')
                   }
               },
               ...this.car.color && {
                   'ADD_CAR.COLOR': {
                       value: this.car.color.name
                   }
               },
               ...this.car.owners_count && {
                   'ADD_CAR.NUMBER_OF_OWNERS': {
                       value:  this.carLocalize.get(this.car.owners_count, 'owners_count')
                   },
               },
               ...this.car.custom_clearance != null && {
                   'ADD_CAR.CUSTOMS_CLEARANCE': {
                       value:  this.carLocalize.fromArray(this.car.custom_clearance, 'custom_clearance')
                   },
               },
               ...this.car.guarantee != null && {
                   'ADD_CAR.GUARANTEE': {
                       value:  this.carLocalize.fromArray(this.car.guarantee, 'guarantee')
                   },
               },
               ...this.car.information && this.car.information.gas_cylinder_equipment != null && {
                   'ADD_CAR.GAS_CYLINDER_EQUIPMENT': {
                       value:  this.carLocalize.fromArray(this.car.information.gas_cylinder_equipment, 'guarantee')
                   },
               },
           };
       }
    }
}
