import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { DynamicComponent } from '../../classes/DynamicComponent';
// @ts-ignore
import { } from '@types/googlemaps';


@Component({
  selector: 'app-dynamic-address-autocomplete',
  templateUrl: './dynamic-address-autocomplete.component.html'
})
export class DynamicAddressAutocompleteComponent extends DynamicComponent implements AfterViewInit {

  @ViewChild('addressText') addressText: any;

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
        {
          componentRestrictions: { country: this.country },
          types: [this.addressType]
        });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.group.patchValue({
        [`${this.field.formControlName}`]: this.addressText.nativeElement.value
      });
    });
  }

  get addressType() {
    return this.field.additionalParams.addressType || 'address';
  }

  get country() {
    return this.field.additionalParams.country || 'AM';
  }

}
