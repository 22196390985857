import { Component, OnDestroy, OnInit } from '@angular/core';
import { FULL_ROUTES } from '../routes';
import { ContactUs } from '@shared/helpers/forms/contact-us-form';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CarService } from '@shared/providers/car/car.service';
import { SnackbarService } from '@shared/snackbar/snackbar.service';
import { SNACKBAR_TYPES } from '@shared/snackbar/snackbar.constants';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html'
})
export class ContactUsComponent extends ContactUs implements OnInit, OnDestroy {

  private $subscriptions: Subscription = new Subscription();
  public readonly fullRoutes = FULL_ROUTES;
  public accountInfo = {
    name: '',
    email: ''
  };
  loading = false;

  constructor(
      private readonly activatedRoute: ActivatedRoute,
      private readonly carService: CarService,
      private readonly snackbarService: SnackbarService
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data: any) => {
      if (data.accountInfo) {
        this.accountInfo.name  = `${data.accountInfo.first_name ? data.accountInfo.first_name + ' ' : ''}${data.accountInfo.last_name || ''}`;
        this.accountInfo.email = data.accountInfo.email || '';
        this.setFormValues();
      }
    }).unsubscribe();
  }

  submit() {
    if (this.form.valid && !this.loading) {
      this.loading = true;
      this.$subscriptions.add(
          this.carService.contactUs(this.form.value).subscribe(res => {
            this.snackbarService.open('CONTACT_US.CONTACTS_SUCCESS');
            this.form.reset();
            this.setFormValues();
          }, err => this.snackbarService.handleErrorsAndOpen('TOASTS.NOT_VALID_SEARCH', err.error && err.error.errors) ).add(
              () => this.loading = false
          )
      );
    }
  }

  setFormValues() {
    for (const key in this.accountInfo) {
      if (this.form.controls.hasOwnProperty(key)) {
        this.form.controls[key].setValue(this.accountInfo[key]);
      }
    }
  }

  ngOnDestroy() {
    this.$subscriptions.unsubscribe();
  }

}
