// ng
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// Modules
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import {AlertModule} from 'ngx-alerts';
import { NgxMaskModule } from 'ngx-mask';
import { NgProgressModule } from 'ngx-progressbar';
import { AppRoutingModule } from './app-routing.module';
import { DynamicFieldsModule } from './modules/dynamic-fields/dynamic-fields.module';
import { CarItemModule } from './components/car-item/car-item.module';

import { DynamicTaskModule } from './modules/dynamic-tasks/dynamic-task.module';
// translate
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SearchCarComponent } from './components/search-car/search-car.component';
import { getItem } from '@helpers/local-storage';
import { environment } from '@environments/environment';
// constants
import {SNACKBAR_CONFIG} from '@shared/snackbar/snackbar.constants';
import { VehicleTypeComponent } from './home/search-section/components/vehicle-type/vehicle-type.component';
import { CarLocalizeService } from '@shared/providers/car/car-localize.service';
import { SeoService } from '@shared/providers/seo/seo.service';
// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { SignInComponent } from './home/sign-in/sign-in.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CarSingleComponent } from './car-single/car-single.component';
import { SearchSectionComponent } from './home/search-section/search-section.component';
import { AdvancedSearchComponent } from './home/search-section/components/advanced-search/advanced-search.component';
import { TopVehiclesComponent } from './components/top-vehicles/top-vehicles.component';
import { PopularBrandsComponent } from './home/search-section/components/popular-brands/popular-brands.component';
import { PagePreloaderComponent } from './layout/page-preloader/page-preloader.component';
import { CarImageSliderComponent } from './car-single/car-image-slider/car-image-slider.component';
import { CarDescriptionComponent } from './car-single/car-description/car-description.component';
import { CarAdditionalInformationComponent } from './car-single/car-additional-information/car-additional-information.component';
import { DetailRightSectionComponent } from './car-single/detail-right-section/detail-right-section.component';
import { SearchCarHeaderComponent } from './components/search-car/search-car-header/search-car-header.component';
import { CarFilterComponent } from './components/search-car/car-filter/car-filter.component';
import { CarPaginationModule } from './components/car-pagination/car-pagination.module';
import { TokenAndLangInterceptorService } from '@shared/interceptors/token-and-lang-interceptor.service';
import { AddCarModalComponent } from './layout/header/add-car-modal/add-car-modal.component';
import {AddCarProgressComponent} from './layout/add-car-progress/add-car-progress.component';
import { PrivacyPolicyComponent } from './terms-and-privacy/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-privacy/terms-and-conditions/terms-and-conditions.component';
import { AppConstants } from '@shared/constants/app-constants';
import { NavLinkComponent } from './layout/header/nav-link/nav-link.component';
import { RulesListComponent } from './terms-and-privacy/parts/rules-list/rules-list.component';


export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () => new Promise<any>((resolve: any) => {
        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        locationInitialized.then(() => {
            const lang = getItem(AppConstants.LANGUAGE_KEY, environment.defaultLang);
            translate.setDefaultLang(lang);
            translate.use(lang).subscribe(
                () => {
                },
                err => {
                },
                () => resolve(null));
        });
    });
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        NavLinkComponent,
        FooterComponent,
        PagePreloaderComponent,
        HomeComponent,
        SignInComponent,
        SearchCarComponent,
        SearchSectionComponent,
        VehicleTypeComponent,
        AdvancedSearchComponent,
        TopVehiclesComponent,
        PopularBrandsComponent,
        AboutUsComponent,
        ContactUsComponent,
        CarSingleComponent,
        CarImageSliderComponent,
        CarDescriptionComponent,
        CarAdditionalInformationComponent,
        DetailRightSectionComponent,
        SearchCarHeaderComponent,
        CarFilterComponent,
        AddCarModalComponent,
        AddCarProgressComponent,
        PrivacyPolicyComponent,
        TermsAndConditionsComponent,
        RulesListComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        HttpClientModule,
        AppRoutingModule,
        NgxMaskModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ReactiveFormsModule,
        FormsModule,
        NgSelectModule,
        SlickCarouselModule,
        NgxSmartModalModule.forRoot(),
        AlertModule.forRoot(SNACKBAR_CONFIG),
        DynamicFieldsModule,
        NgProgressModule,
        CarItemModule,
        CarPaginationModule,
        DynamicTaskModule
    ],
    providers: [
        CarLocalizeService,
        SeoService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenAndLangInterceptorService,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
