import { ValidationErrors, FormGroup, AbstractControl } from '@angular/forms';


export function repeatPasswordValidator(group: FormGroup): ValidationErrors | null {
    const password: AbstractControl = group.get('password');
    const repeatPassword: AbstractControl = group.get('repeat_password');
    if (password.value === repeatPassword.value) return null;

    return { invalidRepeatPassword: 'VALIDATION.PASSWORDS_NOT_MATCH' };
}
