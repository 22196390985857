<div class="item" *ngIf="car">
    <div class="item-wrapper" [class.is-top]="isTop">
        <div class="item-img-wrapper">
            <a [routerLink]="['/', fullRoutes.CAR_SINGLE, car.id, slug]">
                <div class="item-img"
                     [style.background-image]="'url('+carLocalize.getThumbnail(car.images)+')'">
                </div>
            </a>
            <div *ngIf="isTop" class="is-top">{{ 'TOP.TOP' | translate }}</div>
<!--            TODO remove comment -->
            <!--<button *ngIf="editable && !isTop"
                    (click)="openAddTopModal()"
                    class="btn-unstyled make-top-btn">
                {{ 'TOP.MAKE_CAR_TOP' | translate }}
            </button>-->
        </div>
        <div class="item-details flex justify-between column">
            <a [routerLink]="['/', fullRoutes.CAR_SINGLE, car.id, slug]">
                <div class="flex align-center justify-between pt-2 pb-2 pl-3 pr-3 primary-details">
                    <h3 class="color-primary fs-xl mw-100 ellipsis mr-2 price">{{car.advert?.price}} {{carLocalize.get(car.advert?.currency, 'currency')}}</h3>
                    <span class="fs-md color-black">{{car.production_date}}</span>
                </div>
                <div class="additional-details">
                    <div class="title flex align-center">
                        <h2 class="fs-md color-black">{{car.car_model?.brand?.name}} {{car.car_model?.name}} {{car.generation?.name}}</h2>
                    </div>
                    <ul class="flex align-center flex-wrap mt-3 details-list">
                        <li *ngIf="car.kilometer">
                            {{car.kilometer}} {{ 'CAR.KM' | translate }}
                        </li>
                        <li *ngIf="car.transmission">
                            {{carLocalize.get(car.transmission, 'transmission')}}
                        </li>
                        <li *ngIf="car.cubic_capacity">
                            {{car.cubic_capacity}} {{ 'CAR.LITER' | translate }}
                        </li>
                        <li *ngIf="car.power">
                            {{car.power}} {{ 'CAR.POWER' | translate }}
                        </li>
                        <li *ngIf="car.drive_type">
                            {{carLocalize.get(car.drive_type, 'drive_type')}}
                        </li>
                    </ul>
                </div>
            </a>
            <div class="item-footer">
                <div class="item-actions">
                    <label *ngIf="car.advert?.user != authService.userId && !authService.isOfficial"
                           [title]="'CAR_DETAIL.LIKE' | translate"
                           class="favorite-checkbox"
                           (click)="toggleFavourite()">
                        <input type="checkbox" [checked]="car.favorite?.favorite"/>
                        <span class="favorite-icon"></span>
                    </label>
                    <button type="button" class="btn-unstyled" (click)="fbShareBtnClick()">
                        <i class="icon-share"></i>
                    </button>
                    <ng-container *ngIf="editable">
                        <a [routerLink]="'/' + fullRoutes.ADD_CAR + '/' + car.id" class="btn-unstyled">
                            <i class="icon-edit"></i>
                        </a>
                        <button type="button" class="btn-unstyled" (click)="openDeleteCarModal()">
                            <i class="icon-delete"></i>
                        </button>
                    </ng-container>
                </div>
                <p class="clearance" [ngClass]="{
                    'color-success': car.custom_clearance,
                    'color-warning': !car.custom_clearance
                    }">
                    {{(car.custom_clearance ? 'CAR.CUSTOM_CLEARANCE.YES' : 'CAR.CUSTOM_CLEARANCE.NO') | translate }}
                </p>
            </div>
        </div>
    </div>
</div>
