import { FormBuilder, FormGroup } from '@angular/forms';
import { VehicleType } from '@shared/interfaces/car/vehicle-type.interface';
import { CarService } from '@shared/providers/car/car.service';
import { DynamicField } from '../../../modules/dynamic-fields/classes/DynamicField';
import { SearchForm } from '@shared/helpers/search/search-form';
import { DynamicFormCreateService } from '../../../modules/dynamic-fields/providers/dynamic-form-create.service';


export abstract class VehicleTypeSearch {

    public static INDEXES: any = {};

    // items
    public types: VehicleType[] = [];
    public items: DynamicField[] = [];

    // form
    form: FormGroup;

    protected constructor(
        protected dynamicForm: DynamicFormCreateService,
        protected carService: CarService
    ) {
        this.formConfigs();
    }

    formConfigs(): void {

        // init items
        this.items = [
            new DynamicField({
                type: 'select',
                label: 'ADD_CAR.BODY_TYPE',
                placeholder: 'CAR.ANY',
                wrapperClass: 'dynamic-input width-3',
                formControlName: 'car_type',
                loading: true,
            }),
        ];

        // init items INDEXES
        VehicleTypeSearch.INDEXES = SearchForm.getIndexes(this.items);

        // init form
        this.form = this.dynamicForm.createForm(this.items);
    }

    initFunctions(form: FormGroup = null): void {
        if (form) {
            this.form = form;
        }
        this.getCarSeries();
    }

    getCarSeries() {
        this.carService.getCarSeries<VehicleType>().subscribe(res => {
            this.types = res.results;
            this.items[VehicleTypeSearch.INDEXES.CAR_TYPE].source = res.results;
            this.items[VehicleTypeSearch.INDEXES.CAR_TYPE].isLoading = false;
        });
    }

}
