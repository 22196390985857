<header [ngClass]="routerData.headerClass" id="navBar">
  <div class="container relative">

    <div class="flex align-center justify-between header-content" [ngClass]="{'show': showMenu}">

      <div class="logo">
        <a [routerLink]="'/' + fullRoutes.HOME" class="inline-block hover-opacity">
          <img src="/assets/images/logo.svg" alt="Logo">
        </a>
      </div>

      <div class="flex align-center justify-between w-100 nav-items">

        <ul class="nav-left flex">
          <ng-container *ngFor="let val of linksConfig">
            <li *ngIf="!val.children">
              <app-nav-link [item]="val"></app-nav-link>
            </li>
            <li *ngIf="val.children" class="dropdown"
                (mouseover)="val.dropdownOpened = true"
                (mouseleave)="val.dropdownOpened = false">
              <app-nav-link [item]="val"></app-nav-link>
              <div class="dropdown-div" *ngIf="val.dropdownOpened">
                <ul class="dropdown-menu">
                  <li *ngFor="let child of val.children" class="dropdown-item">
                    <a [routerLink]="'/' + child.path" class="dropdown-link">{{ child.text | translate }}</a>
                  </li>
                </ul>
              </div>
            </li>
            <li *ngFor="let child of val.children" [class.not-in-tablet]="val.children">
              <app-nav-link [item]="child"></app-nav-link>
            </li>
          </ng-container>
          <ng-container  *ngIf="!authService.isOfficial">
            <li *ngFor="let val of optionalLinksConfig">
              <app-nav-link [item]="val"></app-nav-link>
            </li>
          </ng-container>
        </ul>

        <div class="content-right">
          <div class="lang-and-action">
            <ul *ngIf="!authService.isLoggedIn; else loggedIn" class="nav-right flex align-center">
              <li>
                <button [routerLink]="'/' + fullRoutes.SIGN_IN" routerLinkActive="active" class="btn link">
                  {{'HEADER.SIGN_IN' | translate}}
                </button>
              </li>
              <li>
                <a [routerLink]="'/' + fullRoutes.SIGN_UP" routerLinkActive="active" class="btn filled primary shadow">
                  {{'HEADER.SIGN_UP' | translate}}
                </a>
              </li>
            </ul>
            <ng-template #loggedIn>
              <ul class="nav-right nav-user flex align-center">
                <li *ngIf="!authService.isOfficial">
                  <a class="btn outline white" (click)="ngxSmartModalService.getModal('addCarModal').open()">
                    {{"ADD_CAR.FROM_OTHER_SITE.BUTTON_TEXT" | translate}}
                  </a>
                </li>

                <li>
                  <a [routerLink]="'/' + fullRoutes.ADD_CAR" class="btn filled primary shadow">
                    {{"HEADER.SELL" | translate}}
                  </a>
                </li>

                <li class="dropdown">
                  <button class="btn-unstyled user-account" (click)="toggleUserDropdown()">{{accountService.avatar}}<i class="icon-person" *ngIf="!accountService.avatar"></i></button>

                  <div class="dropdown-div" *ngIf="userDropdownOpened">
                    <ul class="dropdown-menu">
                      <li>
                        <a [routerLink]="[authService.isOfficial ? fullRoutes.OFFICIALS_MY_ACCOUNT : fullRoutes.MY_ACCOUNT]">
                          {{"USER.ACCOUNT_INFORMATION" | translate}}
                        </a>
                      </li>
                      <ng-container *ngIf="!authService.isOfficial">
                        <li>
                          <a [routerLink]="'/' + fullRoutes.FAVORITES" [queryParams]="defaultPageParams">{{"CAR_DETAIL.FAVORITES" | translate}}</a>
                        </li>
                        <li>
                          <a [routerLink]="'/' + fullRoutes.MY_CARS" [queryParams]="defaultPageParams">{{"CAR_DETAIL.MY_ADDS" | translate}}</a>
                        </li>
                      </ng-container>
                      <li class="divider">
                        <a (click)="logout()">{{"HEADER.LOGOUT" | translate}}</a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </ng-template>

            <div class="lang-section">
              <div class="dropdown">
                <button class="btn-unstyled color-white lang-btn" (click)="toggleLangDropdown()">
                  <span>{{currentLang}}</span>
                  <i class="icon-arrow-down"></i>
                </button>

                <div class="dropdown-div" *ngIf="langDropdownOpened">
                  <ul class="dropdown-menu small-dropdown">
                    <li *ngFor="let lang of languages | keyvalue">
                      <a (click)="onLangChange(lang)" [class.active]="lang.value === currentLang">{{lang.value}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <button class="btn-unstyled btn-menu" (click)="showMenu = !showMenu">
        <i class="icon-menu"></i>
      </button>

    </div>

  </div>
</header>

<ngx-smart-modal
  #addCarModal
  identifier="addCarModal"
  customClass="modal nsm-centered n-lg"
  (onOpen)="addCarModalOpened = true; resetActions()"
  (onAnyCloseEventFinished)="addCarModalOpened = false; resetActions()"
>
  <app-add-car-modal [modalOpened]="addCarModalOpened"></app-add-car-modal>
</ngx-smart-modal>
